import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as path from "path";
import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { ISchoolSop } from "common/typings";
import firebase from "firebase/compat/app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-sops",
  templateUrl: "./sops.component.html",
})
export class SchoolSopsComponent implements OnInit, OnDestroy {
  files = [];
  pdfs = [];
  selectedId: string = "";
  sops: ISchoolSop[] = [];
  school: Observable<DocumentData>;
  schoolObj: DocumentData;
  loading = false;
  editing: ISchoolSop = {
    type: "",
    location: "",
    title: "",
  };
  editingIndex: number;
  sopTypeOptions = [];
  subs = new SubSink();
  newSopType = "";
  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private storage: AngularFireStorage,
    private toastr: ToastrService,
    private _modalService: NgbModal
  ) {
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      const schoolRef = db.doc("schools/" + this.selectedId);
      this.school = schoolRef.valueChanges();
      this.subs.sink = this.school.subscribe((scl) => {
        this.schoolObj = scl;
        this.sops =
          scl["sop"] === undefined
            ? []
            : Object.keys(scl["sop"]).map((title) => {
                return { type: title, ...scl["sop"][title] };
              });
        this.sopTypeOptions = Object.keys(scl["sop"]);
      });
      this.loadFiles();
    });
  }

  ngOnInit() {}

  loadFiles() {
    this.storage.storage
      .ref("Schools/" + this.selectedId + "/Documents")
      .listAll()
      .then((files) => {
        this.files = files.items;
        this.files.forEach((file) => {
          const ext = path.extname(file.fullPath);
          if ([".pdf"].indexOf(ext.toLowerCase()) > -1) {
            this.pdfs.push(file);
          }
        });
      });
  }

  async deleteSop(item: any) {
    if (confirm("Are you sure to delete this sop?")) {
      try {
        await this.db
          .doc(`schools/${this.selectedId}`)
          .update({ [`sop.${item.type}`]: firebase.firestore.FieldValue.delete() });
      } catch (error) {
        console.log(error);
      }
      this.toastr.success("Great, sop has been deleted successfully!");
    }
  }

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }

  download(i: number) {
    const downloadRef = this.storage.ref(this.sops[i]["location"]);
    downloadRef.getDownloadURL().subscribe((url) => {
      window.open(url, "_blank");
    });
  }

  isEditing() {
    return this.editingIndex === undefined ? false : true;
  }

  resetSop() {
    this.editingIndex = undefined;
    this.editing = {
      type: "",
      location: "",
      title: "",
    };
  }

  edit(i: number) {
    this.editingIndex = i;
    const selectedDocument = this.sops[i];
    this.editing = {
      ...selectedDocument,
      type: selectedDocument["type"],
      location: selectedDocument["location"],
      title: selectedDocument["title"],
    };
    window.scroll(0, 0);
  }

  openAddNewSopTypeModal(value: any, form: NgForm, addNewSopTypeTemplate: TemplateRef<any>) {
    if (value === "fa fa-plus-circle") {
      this.newSopType = "";
      this.editing.type = "";
      form.control.patchValue({
        type: "",
      });
      form.control.updateValueAndValidity();
      this._modalService.open(addNewSopTypeTemplate);
    }
  }

  addNewType() {
    this.sopTypeOptions = [...this.sopTypeOptions, this.newSopType];
    this.editing.type = this.newSopType;
    this.closeModal();
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  async onSubmit(form: NgForm) {
    try {
      this.loading = true;
      if (this.editingIndex !== undefined) {
        await this.db
          .doc(`schools/${this.selectedId}`)
          .update({ [`sop.${this.editing.type}`]: { location: this.editing.location, title: this.editing.title } });
        this.toastr.success("Great, sop has been updated successfully!");
      } else {
        const currentSops = this.schoolObj["sop"] ? this.schoolObj["sop"] : {};
        let toUpdateSop = {
          ...currentSops,
          [`${this.editing.type}`]: { location: this.editing.location, title: this.editing.title },
        };
        await this.db
          .doc(`schools/${this.selectedId}`)
          .update({ [`sop.${this.editing.type}`]: { location: this.editing.location, title: this.editing.title } });
        this.toastr.success("Great, new sop has been added successfully!");
      }
      form.reset();
      form.control.patchValue({
        type: "",
        location: "",
        title: "",
      });
      this.loading = false;
    } catch (error) {
      console.error("error", error);
      this.toastr.error(`Oops, something went wrong while adding documents. Please try again!`);
      this.loading = false;
    }
  }

  ngOnDestroy(): void {}
}

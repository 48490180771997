export enum CollectionNames {
  vendors = "vendors",
  schools = "schools",
  services = "services",
  users = "users",
  messages = "messages",
  regions = "regions",
  districts = "districts",
  ratings = "ratings",
  ongoingIncidents = "ongoing_incidents",
  notifications = "notifications",
  dailyReports = "daily-reports",
  visitors = "visitors",
  settings = "settings",
  rollcalls = "rollcalls",
  hotline = "hotline",
  photosOfTheWeek = "photos_of_the_week",
  states = "states",
  events = "events",
}

export enum AllUserRoles {
  admin = "admin",
  security = "security",
  employee = "employee",
  business = "business",
  endUser = "end_user",
  staff = "staff",
  student = "student",
  family = "family",
  district = "district",
  pdFireEms = "pd_fire_ems",
  boater = "boater",
  vendor = "vendor",
  vendor_employee = "vendor_employee",
  maintenance = "maintenance",
  unit_owner = "unit_owner",
  unit_owner_admin = "unit_owner_admin",
  guest = "guest",
  tenant = "tenant",
  tenant_family = "tenant_family",
}

export enum RoutePaths {
  login = "auth/login",
  home = "app/home",
  regions = "app/regions",
  districts = "app/districts",
  schools = "app/schools",
  vendors = "app/vendors",
  services = "app/services",
  districtAdmins = "app/district-admins",
  globalSettings = "app/global-settings",
  photoOfTheWeek = "app/global-settings/photos-of-the-week",
  boatersInformation = "app/global-settings/boaters-information",
  yachtSpreadSheet = "app/global-settings/yacht-spreadsheet",
}

export const SchoolVillageIncidents = {
  negative: {
    vandalism: "Vandalism",
    outOfClass: "Out of Class",
    cheatingOnTestClasswork: "Cheating on test/classwork",
    drugsAlcohol: "Drugs/Alcohol",
    unauthorizedVehicleOnCampus: "Unauthorized Vehicle on Campus",
    inappropriateClothing: "Inappropriate Clothing",
    intruder: "Intruder",
    bullying: "Bullying",
    disabilityRelated: "Disability Related",
    gangActivity: "Gang Activity",
    useOfCellPhone: "Use of Cell Phone",
    fight: "Fight",
    accidentInjury: "Accident/Injury",
    threats: "Threats",
    abductionMissing: "Abduction/Missing",
    fire: "Fire",
    sexualHarassmentHome: "Sexual Harassment: Home",
    depressionSuicidal: "Depression/Suicidal",
    sexualHarassmentCampus: "Sexual Harassment: Campus",
    weapons: "Weapons",
    maintenance: "Maintenance",
  },
  positive: {
    exceptionalClassWork: "Exceptional Class Work",
    commendation: "Commendation",
    remarkableImprovement: "Remarkable Improvement",
    notableAchievement: "Notable Achievement",
    actOfKindness: "Act of Kindness",
  },
};

export const MarinaVillageIncidents = {
  negative: {
    assault: "Assault",
    autoAccidentInjury: "Auto Accident - Injury",
    autoAccidentNonInjury: "Auto Accident - Non Injury",
    boatAccidentInjury: "Boat Accident - Injury",
    boatAccidentNonInjury: "Boat Accident - Non Injury",
    excessiveNoise: "Excessive Noise",
    fire: "Fire",
    intruderTrespass: "Intruder/Trespass",
    maintenance: "Maintenance",
    mentalHealth: "Mental Health",
    oilHazMatSpill: "Oil/Haz Mat Spill",
    outstandingService: "Outstanding Service",
    theft: "Theft",
    threats: "Threats",
    vandalism: "Vandalism",
  },
  positive: {},
};

export const HoaVillageIncidents = {
  negative: {
    assault: "Assault",
    autoAccidentInjury: "Auto Accident - Injury",
    autoAccidentNonInjury: "Auto Accident - Non Injury",
    excessiveNoise: "Excessive Noise",
    fire: "Fire",
    intruderTrespass: "Intruder/Trespass",
    maintenance: "Maintenance",
    mentalHealth: "Mental Health",
    outstandingService: "Outstanding Service",
    theft: "Theft",
    threats: "Threats",
    vandalism: "Vandalism",
    medical: "Medical",
    parkingIssue: "Parking Issue",
    trashDebrisHazMat: "Trash/Debris/Haz Mat",
  },
  positive: {},
};

export enum StorageFolderLocation {
  photosOfTheWeek = "photos-of-the-week",
  boatersInformation = "boaters-information",
}

export enum UserAccountStatus {
  reviewed = "reviewed",
  deleted = "deleted",
  suspended = "suspended",
  pending_review = "pending_review",
  incomplete = "incomplete",
}

export enum UserStage {
  personal_boater = "personal_boater",
  personal_vendor = "personal_vendor",
  boater_boat_details = "boater_boat_details",
  vendor_info = "vendor_info",
  vendor_billing = "vendor_billing",
  completed = "completed",
}

export enum HoaUnitValues {
  single_family_detached = "single_family_detached",
  condominium_residential = "condominium_residential",
  condominium_commercial = "condominium_commercial",
  townhouse = "townhouse",
}

export const HoaUnitTypes = [
  {
    label: "Single-Family Detached",
    value: HoaUnitValues.single_family_detached,
  },
  {
    label: "Condominium-Residential",
    value: HoaUnitValues.condominium_residential,
  },
  {
    label: "Condominium Commercial",
    value: HoaUnitValues.condominium_commercial,
  },
  {
    label: "Townhouse",
    value: HoaUnitValues.townhouse,
  },
];

import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit } from "@angular/core";
import { isSameDay, isSameMonth } from "date-fns";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CalendarEvent, CalendarView } from "angular-calendar";
import { EventColor } from "calendar-utils";
import { AngularFirestore, DocumentData, DocumentReference } from "@angular/fire/compat/firestore";
import { CollectionNames } from "app/shared/generic_variables";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute } from "@angular/router";
import { ICalendarEventData } from "common/typings";
import { ToastrService } from "ngx-toastr";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
import { Location } from "@angular/common";
import { unionBy } from "lodash";
import { getFirstDateOfMonth, getLastDateOfMonth } from "app/utils/date.util";
import * as moment from "moment";

const colors: Record<string, EventColor> = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-events-calendar",
  templateUrl: "./events-calendar.component.html",
  styleUrls: ["./events-calendar.component.css"],
})
export class EventsCalendarComponent implements OnInit {
  @ViewChild("eventAddEditTemplate", { static: true }) eventAddEditTemplate: TemplateRef<any>;
  @ViewChild("eventViewTemplate", { static: true }) eventViewTemplate: TemplateRef<any>;
  schoolData: DocumentData = {};
  currentUserData: DocumentData = {};
  selectedEventData: DocumentData = null;
  selectedSchoolId: string = "";
  loading = false;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  todayDate = new Date();
  viewDate: Date = this.todayDate;
  allEvents: DocumentData[] = [];
  calendarEvents: CalendarEvent[] = [];
  selectedDateCalendarEvents: any[] = [];
  activeDayIsOpen: boolean = false;
  addEditMode: "edit" | "create" = "create";

  columns = [
    { prop: "title", name: "Title" },
    { prop: "start", name: "Starts At" },
    { prop: "end", name: "Ends At" },
    { prop: "actions", name: "Actions" },
  ];

  constructor(
    private _modalService: NgbModal,
    private db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private _location: Location
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.selectedSchoolId = params.get("id");
      const startTimestamp = getFirstDateOfMonth(this.todayDate.getFullYear(), this.todayDate.getMonth());
      const endTimestamp = getLastDateOfMonth(this.todayDate.getFullYear(), this.todayDate.getMonth());
      this.fetEvents({ startTimestamp, endTimestamp });
      this.db
        .doc("schools/" + this.selectedSchoolId)
        .valueChanges()
        .subscribe((scl) => {
          this.schoolData = scl;
        });
    });

    this.afAuth.authState.subscribe((authUser) => {
      const uid = authUser.uid;
      const userRef = this.db.doc("users/" + uid);
      const userDoc = userRef.valueChanges();
      userDoc.subscribe(
        (userValue) => {
          this.currentUserData = userValue;
        },
        (error) => {}
      );
    });
  }

  eventModel: ICalendarEventData = {
    title: "",
    description: "",
    startTimestamp: "",
    endTimestamp: "",
    location: "",
    attendees: [],
    isRecurring: false,
    recurrenceRule: "",
    createdBy: "",
    createdById: "",
    updatedBy: "",
    updatedByById: "",
  };

  ngOnInit(): void {}

  onActivate(event: any) {}

  fetEvents(params: { startTimestamp: Date; endTimestamp: Date }) {
    this.db
      .collection(CollectionNames.schools)
      .doc(this.selectedSchoolId)
      .collection(CollectionNames.events, (ref) => this.getEventQueryRef(ref, params))
      .valueChanges()
      .subscribe((_events) => {
        this.allEvents = [];
        this.calendarEvents = [];
        this.selectedDateCalendarEvents = [];
        console.log("subscription working", _events.length);
        this.allEvents = _events;
        this.allEvents.forEach((eachEvent) => {
          let eventTitle = eachEvent?.title;
          try {
            eventTitle = `${eachEvent?.title} (${moment(eachEvent?.startTimestamp?.toDate()).format(
              "YYYY-MM-DD h:mm A"
            )} - ${moment(eachEvent?.endTimestamp?.toDate()).format("YYYY-MM-DD h:mm A")})`;
          } catch {}
          const event: CalendarEvent = {
            start: eachEvent?.startTimestamp?.toDate(),
            end: eachEvent?.endTimestamp?.toDate(),
            title: eventTitle,
            // color: { ...colors.red },
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
            id: eachEvent?.id,
            meta: {
              onlyTitle: eachEvent?.title,
            },
          };
          this.calendarEvents = unionBy([...this.calendarEvents, event], "id");
          this.selectedDateCalendarEvents = unionBy(
            [
              ...this.selectedDateCalendarEvents,
              {
                ...event,
                title: eachEvent?.title,
                start: moment(eachEvent?.startTimestamp?.toDate()).format("YYYY-MM-DD h:mm A"),
                end: moment(eachEvent?.endTimestamp?.toDate()).format("YYYY-MM-DD h:mm A"),
              },
            ],
            "id"
          );
          this.refresh.next();
        });
      });
  }

  getEventQueryRef(ref: any, params: { startTimestamp: Date; endTimestamp: Date }) {
    const { startTimestamp, endTimestamp } = params;
    try {
      if (startTimestamp) {
        ref = ref.where("dateFilter.startDateMonth", "==", startTimestamp?.getUTCMonth());
        ref = ref.where("dateFilter.startDateYear", "==", startTimestamp?.getUTCFullYear());
      }
    } catch (error) {
      console.log(error);
    }
    try {
      if (startTimestamp) {
        ref = ref.where("dateFilter.endDateMonth", "==", endTimestamp?.getUTCMonth());
        ref = ref.where("dateFilter.endDateYear", "==", endTimestamp?.getUTCFullYear());
      }
    } catch (error) {
      console.log(error);
    }
    return ref.where("deleted", "==", false);
  }

  refresh = new Subject<void>();

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.viewDate = date;
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
        this.selectedDateCalendarEvents = this.allEvents.map((item) => {
          return {
            ...item,
            title: item?.title,
            start: moment(item?.startTimestamp?.toDate()).format("YYYY-MM-DD h:mm A"),
            end: moment(item?.endTimestamp?.toDate()).format("YYYY-MM-DD h:mm A"),
          };
        });
      } else {
        this.activeDayIsOpen = true;
        this.selectedDateCalendarEvents = events.map((item) => {
          return {
            ...item,
            title: item?.meta?.onlyTitle,
            start: moment(item?.start).format("YYYY-MM-DD h:mm A"),
            end: moment(item?.start).format("YYYY-MM-DD h:mm A"),
          };
        });
      }
    }
  }

  openAddEventDialog(): void {
    this.addEditMode = "create";
    this._modalService.open(this.eventAddEditTemplate);
  }

  openEditEventDialog(selectedRow: any): void {
    this.addEditMode = "edit";
    this.selectedEventData = this.allEvents.find((item) => item.id === selectedRow.id);
    const { title, description, startTimestamp, endTimestamp, location, attendees, isRecurring, recurrenceRule } =
      this.selectedEventData;
    this.eventModel = {
      title: title ? title : "",
      startTimestamp: startTimestamp ? moment(startTimestamp?.toDate()).format("YYYY-MM-DDTHH:mm") : null,
      endTimestamp: endTimestamp ? moment(endTimestamp?.toDate()).format("YYYY-MM-DDTHH:mm") : null,
      location: location ? location : "",
      attendees: attendees ? attendees : [],
      isRecurring,
      recurrenceRule: recurrenceRule ? recurrenceRule : "",
      description: description ? description : "",
    };
    this._modalService.open(this.eventAddEditTemplate);
  }

  openEventViewModal(selectedRow: any): void {
    this.selectedEventData = this.allEvents.find((item) => item.id === selectedRow.id);
    console.log("selectedEventData", this.selectedEventData);
    this._modalService.open(this.eventViewTemplate);
  }

  async onSubmit() {
    try {
      const eventData = this.formulateEventData();
      if (eventData?.startTimestamp >= eventData?.endTimestamp) {
        this.toastrService.warning("Event start date can not be greater than end date.");
        return;
      }
      if (this.selectedEventData?.id) {
        this.loading = true;
        try {
          await this.db
            .collection(CollectionNames.schools)
            .doc(this.selectedSchoolId)
            .collection(CollectionNames.events)
            .doc(this.selectedEventData.id)
            .update(eventData);
          this.closeModal();
          this.resetForm();
          this.toastrService.success("Great, Event is updated successfully!");
        } catch (error) {
          console.log(error);
          this.toastrService.error("Oops, Something went wrong while updating data. Please try again!");
        }
        this.loading = false;
      } else {
        try {
          await this.db
            .collection(CollectionNames.schools)
            .doc(this.selectedSchoolId)
            .collection(CollectionNames.events)
            .add(eventData);
          this.toastrService.success("Great, Event is added successfully!");
          this.closeModal();
          this.resetForm();
        } catch (error) {
          console.log(error);
          this.toastrService.error("Oops, Something went wrong while saving data. Please try again!");
        }
        this.loading = false;
      }
    } catch {
      this.toastrService.error("Oops, Something went wrong while saving data. Please try again!");
    }
  }

  formulateEventData() {
    const {
      title,
      description,
      startTimestamp,
      endTimestamp,
      location,
      attendees: [],
      isRecurring,
      recurrenceRule,
      createdBy,
      createdById,
    } = this.eventModel;
    return {
      title: title,
      description: description ? description : "",
      startTimestamp: startTimestamp ? new Date(startTimestamp) : "",
      endTimestamp: endTimestamp ? new Date(endTimestamp) : "",
      location,
      attendees: [], // TODO attendees are to be added
      isRecurring: isRecurring == undefined ? false : isRecurring,
      recurrenceRule: recurrenceRule ? recurrenceRule : "",
      createdBy: createdBy ? createdBy : this.currentUserData?.fullName ?? "",
      createdById: createdById ? createdById : this.currentUserData?.id ?? "",
      updatedBy: this.currentUserData?.fullName ?? "",
      updatedById: this.currentUserData?.id ?? "",
    };
  }

  resetForm() {
    this.eventModel = {
      title: "",
      description: "",
      startTimestamp: "",
      endTimestamp: "",
      location: "",
      attendees: [],
      isRecurring: false,
      recurrenceRule: "",
      createdBy: "",
      createdById: "",
      updatedBy: "",
      updatedByById: "",
    };
  }

  confirmEventDeletion(eventData: DocumentData) {
    const modalRef = this._modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = `Delete Event`;
    modalRef.componentInstance.text = `Are you sure you want to delete  `;
    modalRef.componentInstance.highlightedText = `${eventData?.title}`;
    modalRef.result.then(async (confirmed: boolean) => {
      if (confirmed) {
        try {
          await this.db
            .collection(CollectionNames.schools)
            .doc(this.selectedSchoolId)
            .collection(CollectionNames.events)
            .doc(eventData?.id)
            .update({ deleted: true });
          this.toastrService.success("Great, Event is deleted successfully!");
        } catch {
          this.toastrService.error("Oops, Something went wrong while deleting data. Please try again!");
        }
      }
    });
  }

  getBackLink() {
    this._location.back();
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  calendarViewDateChanged() {
    this.activeDayIsOpen = false;
    const startTimestamp = getFirstDateOfMonth(this.viewDate.getFullYear(), this.viewDate.getMonth());
    const endTimestamp = getLastDateOfMonth(this.viewDate.getFullYear(), this.viewDate.getMonth());
    this.fetEvents({ startTimestamp, endTimestamp });
  }

  closeModal() {
    this._modalService.dismissAll();
    this.selectedEventData = null;
  }
}

<section class="content">
  <header class="content__title">
    <h1><a [routerLink]="getBackLink()">Back</a></h1>
    <br />
    <h1>{{ (school | async)?.name }}</h1>
    <small>{{ (school | async)?.city }}</small>
  </header>

  <div class="row">
    <div class="col-md-6">
      <button class="btn btn-primary mr-2" (click)="addUser()">Add User</button>
      <button class="btn btn-primary" (click)="addUsersBulk()">Bulk Upload User</button><br /><br />
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        id="search"
        [(ngModel)]="searchText"
        name="search"
        placeholder="search"
        (keyup)="onUserSearch($event)"
      />
    </div>
    <div class="col-md-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Filter By Group:</span>
        </div>
        <select
          class="form-control"
          id="role"
          (ngModelChange)="onRoleFilterChange($event)"
          required
          [(ngModel)]="selectedRole"
          name="role"
        >
          <option value="all">All</option>
          <option *ngFor="let role of allRoles" [value]="role">
            {{ role }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12" *ngFor="let item of users; let i = index">
      <div class="card" style="cursor: pointer" (click)="navigateToUserDetailPage(item)">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <h4 class="card-title">
                <i class="zmdi zmdi-account"></i>&nbsp;&nbsp; {{ item.lastName }}, {{ item.firstName }}
                <span *ngIf="item.associatedSchools[selectedSchoolId]?.isYachtClub" class="badge badge-info"
                  >YachtClub Member</span
                >
              </h4>
              <h6 class="card-subtitle"><i class="zmdi zmdi-email"></i>&nbsp;&nbsp;&nbsp;{{ item.email }}</h6>
              <h6 class="card-subtitle">
                {{ item?.createdTimestamp?.toDate() | date : "medium" }}
              </h6>
            </div>
            <div class="col-md-5">
              <h6 class="card-subtitle">Role: {{ getUserRole(item) }}</h6>
              <h6 class="card-subtitle">
                Phone: {{ item.phone }}
                <span *ngIf="item.phone_extension"><span class="pl-1 pr-1">X</span>{{ item.phone_extension }}</span>
              </h6>
              <h6 class="card-subtitle">Groups: {{ getGroups(item) }}</h6>
            </div>
            <div class="col-md-2">
              <span *ngIf="item.associatedSchools[selectedSchoolId]?.verified" class="ml-3 badge badge-success"
                >Verified</span
              >
              <button
                *ngIf="
                  item.associatedSchools[selectedSchoolId]?.isYachtClub &&
                  !item.associatedSchools[selectedSchoolId]?.verified
                "
                class="btn btn-primary"
                (click)="
                  verifyYachtMemberShip(
                    $event,
                    item,
                    selectedSchoolId,
                    item.associatedSchools[selectedSchoolId]?.schoolName
                  );
                  $event.stopPropagation()
                "
              >
                Verify</button
              ><br /><br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

import { DocumentData } from "@angular/fire/compat/firestore";

export interface IBoatLocationOptions {
  dockNumber: string;
  slipNumber: string;
  mooring: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  description: string;
}

export type TBoatLocationType = "dockSlip" | "mooring" | "address" | "others";

export function getBoatLocation(boatLocationType: string, boatLocationOptions: IBoatLocationOptions) {
  const { dockNumber, slipNumber, mooring, street, city, state, zip, description } = boatLocationOptions;
  let boatLocation = {};
  switch (boatLocationType) {
    case "dockSlip":
      boatLocation = { dockNumber: dockNumber ? dockNumber : "", slipNumber: slipNumber ? slipNumber : "" };
      break;
    case "mooring":
      boatLocation = { mooring: mooring };
      break;
    case "address":
      boatLocation = {
        street: street ? street : "",
        city: city ? city : "",
        state: state ? state : "",
        zip: zip ? zip : "",
      };
      break;
    case "others":
      boatLocation = { description: description ? description : "" };
      break;
  }
  return boatLocation;
}

export function hasAccessToAllTheSchools(userData: DocumentData, isOwner: boolean) {
  return (
    userData &&
    (isOwner === true || (userData["isAssociatedToAllSchools"] === true && userData["isDistrictAdmin"] === true))
  );
}

export const USTimezones = [
  "America/Los_Angeles",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
];

export const SchoolBulkUploadRequiredOrderOfSheetTitles = [
  "S_name",
  "l_slipsCount",
  "l_slipsSizeRange.from",
  "l_slipsSizeRange.to",
  "S_slipsSizeUnit",
  "S_business_phone",
  "S_contact_name",
  "S_contact_email",
  "S_contact_phone",
  "S_harbor_name",
  "S_region_name",
  "S_address",
  "S_city",
  "S_state",
  "S_zip",
  "S_county",
  "S_tz",
  "S_website",
  "S_location",
  "S_map_marina",
  "S_map_harbor",
  "S_yacht_clubs",
  "B_has_moorings",
  "S_vhf",
  "Notes",
];

<section class="content">
  <app-school-detail-page-header [schoolData]="schoolData"></app-school-detail-page-header>
  <div class="row mb-5">
    <div class="col-md-12">
      <div>
        <h1>New User</h1>
        <form (ngSubmit)="onSubmit()" #userForm="ngForm">
          <div class="form-group">
            <label for="role">Role<span class="required-field"></span></label>
            <select
              class="form-control"
              id="role"
              required
              [(ngModel)]="model.role"
              name="role"
              (change)="onRoleChange($event.target.value)"
              #role="ngModel"
            >
              <option *ngFor="let role of roles" [value]="role">
                {{ formatRole(role) | titlecase }}
              </option>
            </select>
            <div [hidden]="role.valid || role.pristine" class="alert alert-danger">Role is required</div>
          </div>
          <div class="form-group">
            <label for="name">First Name<span class="required-field"></span></label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              required
              [(ngModel)]="model.firstName"
              name="firstName"
              #firstName="ngModel"
            />
            <div [hidden]="firstName.valid || firstName.pristine" class="alert alert-danger">
              First Name is required
            </div>
          </div>

          <div class="form-group">
            <label for="name">Last Name<span class="required-field"></span></label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              required
              [(ngModel)]="model.lastName"
              name="lastName"
              #lastName="ngModel"
            />
            <div [hidden]="lastName.valid || lastName.pristine" class="alert alert-danger">Last Name is required</div>
          </div>

          <div class="form-group">
            <label for="email">Email<span class="required-field"></span></label>
            <input
              type="email"
              class="form-control"
              id="email"
              required
              email
              [(ngModel)]="model.email"
              name="email"
              #email="ngModel"
            />
            <div [hidden]="email.valid || email.pristine" class="alert alert-danger">Valid Email is required</div>
          </div>
          <div class="form-row">
            <div class="col">
              <label for="phone">Mobile Phone<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="phone"
                required
                [(ngModel)]="model.phone"
                name="phone"
                mask="(000) 000-0000"
                #phone="ngModel"
              />
              <div [hidden]="phone.valid || phone.pristine" class="alert alert-danger">Invalid phone number</div>
            </div>
            <div *ngIf="showOfficePhoneNumberField" class="col">
              <label for="officePhone">Office Phone</label>
              <input
                type="text"
                class="form-control"
                id="officePhone"
                [(ngModel)]="model.officePhone"
                name="officePhone"
                mask="(000) 000-0000"
                #officePhone="ngModel"
              />
              <div [hidden]="officePhone.valid || officePhone.pristine" class="alert alert-danger">
                Invalid Office phone number
              </div>
            </div>
            <div *ngIf="showOfficePhoneExtensionField" class="col">
              <label for="phone_extension">Office Phone Extension</label>
              <input
                type="text"
                class="form-control"
                id="phone_extension"
                [(ngModel)]="model.phone_extension"
                name="phone_extension"
                #phone_extension="ngModel"
              />
              <div [hidden]="phone_extension.valid || phone_extension.pristine" class="alert alert-danger">
                Invalid phone extension (should be between 2 to 5 characters)
              </div>
            </div>
          </div>
          <div *ngIf="showUnitType" class="form-group">
            <label for="unitType">Unit Type<span class="required-field"></span></label>
            <select
              class="form-select form-control"
              id="unitType"
              [(ngModel)]="model.unitType"
              name="unitType"
              placeholder="unitType"
              required
              #unitType="ngModel"
            >
              <option *ngFor="let option of HoaUnitTypes" [value]="option.value">{{ option.label }}</option>
            </select>
            <div [hidden]="unitType.valid || unitType.pristine" class="alert alert-danger">
              Please select Unit Type!
            </div>
          </div>

          <div *ngIf="showAgeField" class="form-group">
            <label for="age">Age<span class="required-field"></span></label>
            <select
              class="form-select form-control"
              id="age"
              [(ngModel)]="model.age"
              name="age"
              placeholder="Age"
              required
              #age="ngModel"
            >
              <option value="Above 18 years">18 or Above 18 years</option>
              <option value="13-17 years">Between 13-17 years</option>
              <option value="Under 13 years">Under 13 years</option>
            </select>
            <div [hidden]="age.valid || age.pristine" class="alert alert-danger">Please select age!</div>
          </div>

          <div *ngIf="showCompanyField" class="form-group">
            <label for="company"> Company/Organization</label>
            <input
              type="text"
              class="form-control"
              id="company"
              [(ngModel)]="model.company"
              name="company"
              #company="ngModel"
            />
          </div>

          <div *ngIf="isAdmin" class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="allowAdminUIPermission"
                #allowAdminUIPermission="ngModel"
                [(ngModel)]="model.allowAdminUIPermission"
                name="allowAdminUIPermission"
              />
              <label class="form-check-label"> Add Admin UI Modification Permission </label>
            </div>
          </div>

          <div *ngIf="isAdmin" class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="allowBillingResponsibility"
                #allowBillingResponsibility="ngModel"
                [(ngModel)]="model.allowBillingResponsibility"
                name="allowBillingResponsibility"
              />
              <label class="form-check-label"> Add Billing Responsibility</label>
            </div>
          </div>

          <div *ngIf="showBoaterFields">
            <div class="row">
              <div class="col-md-12">
                <div *ngIf="userForm.valid" class="float-right mb-4">
                  <button class="btn btn-primary cursor-pointer" type="button" (click)="addAnotherBoat()">
                    ADD Another Boat <i class="fa fa-plus-circle" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-12" *ngIf="additionalBoats.length > 0">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Additional Boats</h4>
                    <div class="row mt-3">
                      <div class="col-12 card" *ngFor="let boat of additionalBoats; let inx = index">
                        <h6 class="font-weight-bold">
                          {{ inx + 1 }}. <i class="zmdi zmdi-account"></i>&nbsp;&nbsp; {{ boat.boatName }}
                          <a class="cursor-pointer" style="margin-left: 24px" (click)="removeAdditionalBoat(index)">
                            <i class="zmdi zmdi-delete text-danger" style="font-size: 16px"></i>
                          </a>
                        </h6>
                        <p style="margin-bottom: 2px">
                          <span *ngIf="boat?.boatLocation?.dockNumber" class="mr-1"
                            >DockNumber: {{ boat?.boatLocation?.dockNumber }},
                          </span>
                          <span *ngIf="boat?.boatLocation?.slipNumber" class="mr-1"
                            >slipNumber: {{ boat?.boatLocation?.slipNumber }},
                          </span>
                          <span *ngIf="boat?.boatLocation?.mooring" class="mr-1"
                            >mooring: {{ boat?.boatLocation?.mooring }},
                          </span>
                          <span *ngIf="boat?.boatLocation?.street" class="mr-1"
                            >street: {{ boat?.boatLocation?.street }},
                          </span>
                          <span *ngIf="boat?.boatLocation?.city" class="mr-1"
                            >city: {{ boat?.boatLocation?.city }},
                          </span>
                          <span *ngIf="boat?.boatLocation?.state" class="mr-1"
                            >state: {{ boat?.boatLocation?.state }},
                          </span>
                          <span *ngIf="boat?.boatLocation?.zip" class="mr-1">zip: {{ boat?.boatLocation?.zip }}, </span>
                        </p>
                        <p *ngIf="boat?.boatLocation?.description" class="mb-1">
                          Description: {{ boat?.boatLocation?.description }},
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="boatName">Boat Name<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="boatName"
                [(ngModel)]="model.boatName"
                name="boatName"
                required
                #boatName="ngModel"
              />
              <div [hidden]="boatName.valid || boatName.pristine" class="alert alert-danger">Boat Name is required</div>
            </div>

            <div class="form-group">
              <label for="boatLocationType"
                >Location (Select one format and enter location of your boat) <span class="required-field"></span
              ></label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  [(ngModel)]="model.boatLocationType"
                  value="dockSlip"
                  required
                  name="boatLocationType"
                  type="radio"
                  name="boatLocationType"
                  id="dockSlip"
                />
                <label class="form-check-label" for="dockSlip"> Dock Slip </label>
              </div>
              <div *ngIf="model.boatLocationType === 'dockSlip'" class="mt-4">
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="dockNumber">Dock<span class="required-field"></span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="dockNumber"
                      [(ngModel)]="boatLocationOptions.dockNumber"
                      name="dockNumber"
                      placeholder="Sample: Dock C"
                      required
                      #dockNumber="ngModel"
                    />
                    <div [hidden]="dockNumber.valid || dockNumber.pristine" class="alert alert-danger">
                      Dock is required
                    </div>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="slipNumber">Slip<span class="required-field"></span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="slipNumber"
                      [(ngModel)]="boatLocationOptions.slipNumber"
                      name="slipNumber"
                      placeholder="Sample: Slip 27"
                      required
                      #slipNumber="ngModel"
                    />
                    <div [hidden]="slipNumber.valid || slipNumber.pristine" class="alert alert-danger">
                      Slip is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  [(ngModel)]="model.boatLocationType"
                  value="mooring"
                  name="boatLocationType"
                  type="radio"
                  name="boatLocationType"
                  id="mooring"
                  checked
                />
                <label class="form-check-label" for="mooring"> Mooring </label>
              </div>
              <div *ngIf="model.boatLocationType === 'mooring'" class="mt-4">
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="mooringInput">Mooring<span class="required-field"></span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="mooringInput"
                      [(ngModel)]="boatLocationOptions.mooring"
                      placeholder="Sample: Mooring #G23"
                      name="mooring"
                      required
                      #mooring="ngModel"
                    />
                    <div [hidden]="mooring.valid || mooring.pristine" class="alert alert-danger">
                      Mooring is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  value="address"
                  [(ngModel)]="model.boatLocationType"
                  name="boatLocationType"
                  type="radio"
                  name="boatLocationType"
                  id="address"
                  checked
                />
                <label class="form-check-label" for="address"> Address </label>
              </div>
              <div *ngIf="model.boatLocationType === 'address'" class="mt-4">
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="street">Street<span class="required-field"></span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="street"
                      [(ngModel)]="boatLocationOptions.street"
                      name="street"
                      placeholder="eg. 22222 Street Ave. "
                      required
                      #street="ngModel"
                    />
                    <div [hidden]="street.valid || street.pristine" class="alert alert-danger">Street is required</div>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="city">City<span class="required-field"></span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      [(ngModel)]="boatLocationOptions.city"
                      name="city"
                      placeholder="City "
                      required
                      #city="ngModel"
                    />
                    <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="state">State<span class="required-field"></span></label>
                    <select
                      class="form-select form-control"
                      id="state"
                      [(ngModel)]="boatLocationOptions.state"
                      name="state"
                      placeholder="State "
                      required
                      #state="ngModel"
                    >
                      <option value="{{ state?.state }}" *ngFor="let state of usStateList">
                        {{ state?.state }}
                      </option>
                    </select>
                    <div [hidden]="state.valid || state.pristine" class="alert alert-danger">State is required</div>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="zip">Zip<span class="required-field"></span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="zip"
                      [(ngModel)]="boatLocationOptions.zip"
                      name="zip"
                      placeholder="Zip"
                      required
                      #zip="ngModel"
                    />
                    <div [hidden]="zip.valid || zip.pristine" class="alert alert-danger">Zip is required</div>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  value="others"
                  [(ngModel)]="model.boatLocationType"
                  name="boatLocationType"
                  type="radio"
                  id="others"
                  checked
                />
                <label class="form-check-label" for="others"> Others -- Description </label>
              </div>
              <div *ngIf="model.boatLocationType === 'others'" class="mt-4">
                <div class="form-check">
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      id="description"
                      [(ngModel)]="boatLocationOptions.description"
                      name="description"
                      placeholder="Provide description if none of the above formats apply"
                      required
                      #description="ngModel"
                      rows="5"
                    ></textarea>
                    <div [hidden]="description.valid || description.pristine" class="alert alert-danger">
                      Description is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showUserLocationFields" class="mt-3">
            <div class="form-check form-check-inline">
              <div class="form-group">
                <label for="address">Address<span class="required-field"></span></label>
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  [(ngModel)]="userLocationOptions.address"
                  name="address"
                  placeholder="22222 Street Ave. "
                  required
                  #address="ngModel"
                />
                <div [hidden]="address.valid || address.pristine" class="alert alert-danger">Address is required</div>
              </div>
            </div>
            <div class="form-check form-check-inline">
              <div class="form-group">
                <label for="unit">Unit</label>
                <input
                  type="text"
                  class="form-control"
                  id="unit"
                  [(ngModel)]="userLocationOptions.unit"
                  name="unit"
                  placeholder="Unit"
                  #unit="ngModel"
                />
              </div>
            </div>
            <div class="form-check form-check-inline">
              <div class="form-group">
                <label for="city">City<span class="required-field"></span></label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  [(ngModel)]="userLocationOptions.city"
                  name="city"
                  placeholder="City "
                  required
                  #city="ngModel"
                />
                <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
              </div>
            </div>
            <div class="form-check form-check-inline">
              <div class="form-group">
                <label for="state">State<span class="required-field"></span></label>
                <select
                  class="form-select form-control"
                  id="state"
                  [(ngModel)]="userLocationOptions.state"
                  name="state"
                  placeholder="State "
                  required
                  #state="ngModel"
                  (ngModelChange)="onStateSelection()"
                >
                  <option value="{{ state?.state }}" *ngFor="let state of usStateList">
                    {{ state?.state }}
                  </option>
                </select>
                <div [hidden]="state.valid || state.pristine" class="alert alert-danger">State is required</div>
              </div>
            </div>

            <div class="form-check form-check-inline">
              <div class="form-group">
                <label for="county">County<span class="required-field"></span></label>
                <select
                  type="text"
                  class="form-control"
                  id="county"
                  [(ngModel)]="userLocationOptions.county"
                  name="county"
                  placeholder="County"
                  required
                  #county="ngModel"
                >
                  <option value="{{ cty }}" *ngFor="let cty of selectedStateCounties">{{ cty }}</option>
                </select>
                <div [hidden]="county.valid || county.pristine" class="alert alert-danger">County is required</div>
              </div>
            </div>

            <div class="form-check form-check-inline">
              <div class="form-group">
                <label for="zip">Zip<span class="required-field"></span></label>
                <input
                  type="text"
                  class="form-control"
                  id="zip"
                  [(ngModel)]="userLocationOptions.zip"
                  name="zip"
                  placeholder="Zip"
                  required
                  #zip="ngModel"
                />
                <div [hidden]="zip.valid || zip.pristine" class="alert alert-danger">Zip is required</div>
              </div>
            </div>
          </div>

          <div *ngIf="isSchool && isSelectedRoleFamilyType" class="row">
            <div class="col-md-12">
              <label for="students">Add Student(s)</label>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">
                    Add Students (whom this user
                    <span *ngIf="model.firstName || model.lastName" class="font-weight-bold"
                      >({{ model.firstName }} {{ model.lastName }})</span
                    >
                    belong to as parent/guardian)
                  </h4>
                  <div class="row">
                    <div class="col-12 card" *ngFor="let student of selectedStudentsToAdd; let inx = index">
                      <div *ngIf="student">
                        <h6 class="font-weight-bold">
                          {{ inx + 1 }}. <i class="zmdi zmdi-account"></i>&nbsp;&nbsp;
                          {{ student.firstName | titlecase }}
                          {{ student.lastName | titlecase }}
                          <a class="cursor-pointer" style="margin-left: 24px" (click)="openRemoveStudentModal(student)">
                            <i class="zmdi zmdi-delete text-danger" style="font-size: 16px"></i>
                          </a>
                        </h6>
                        <p style="margin-bottom: 2px">
                          <i class="zmdi zmdi-email"></i>&nbsp;&nbsp;&nbsp;{{ student.email }}
                        </p>
                        <p *ngIf="student.phone">
                          <i class="zmdi zmdi-phone"></i>&nbsp;&nbsp;&nbsp;{{ student.phone }}
                          <span *ngIf="student?.phone_extension">
                            <span class="pl-1 pr-1">X</span>{{ student?.phone_extension }}</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="ng-autocomplete">
                    <ng-autocomplete
                      id="students"
                      [(ngModel)]="currentStudentToAdd"
                      [ngModelOptions]="{ standalone: true }"
                      [data]="allSchoolStudentUsers"
                      searchKeyword="name"
                      placeholder="Search student by name..."
                      (inputChanged)="onChangeSearch($event)"
                      (inputFocused)="onFocused($event)"
                      [itemTemplate]="itemTemplate"
                      [notFoundTemplate]="notFoundTemplate"
                    >
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                  <br />
                  <div *ngIf="showNoStudentsError && this.selectedStudentsToAdd.length < 1" class="alert alert-danger">
                    At least one student must be selected for guardian/parent user.
                  </div>
                  <button class="btn btn-primary" type="button" (click)="addStudent()">
                    {{ selectedStudentsToAdd.length > 0 ? "Add Another" : "Add" }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="defaultAlertSelectionOption === 'selectable'" class="row row-eq-height">
            <div class="col-md-6" *ngIf="userAlerts != null">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Edit Alerts Received</h4>
                  <ul *ngIf="!defaultAlertSelectionOption">
                    <li>Alerts are are based on Role. Please select a role first to decide alerts!</li>
                  </ul>
                  <ul *ngIf="defaultAlertSelectionOption === 'all'">
                    <li *ngFor="let alert of AllAlerts">
                      <input
                        type="checkbox"
                        name="{{ alert.key }}"
                        value="{{ userAlerts[alert.key] }}"
                        readonly
                        disabled
                        [(ngModel)]="userAlerts[alert.key]"
                      />
                      {{ alert.value }}
                    </li>
                  </ul>
                  <ul *ngIf="defaultAlertSelectionOption === 'selectable'">
                    <li *ngFor="let alert of AllAlerts">
                      <input
                        type="checkbox"
                        name="{{ alert.key }}"
                        value="{{ userAlerts[alert.key] }}"
                        [(ngModel)]="userAlerts[alert.key]"
                      />
                      {{ alert.value }}
                    </li>
                  </ul>
                  <ul *ngIf="defaultAlertSelectionOption === 'none'">
                    <li>User with {{ model.role }} role will receive no alerts!</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="userGroups != null">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Edit Groups (controls what notifications the selected user receives)</h4>
                  <ul>
                    <li *ngIf="!model.role; else groupsList">
                      Groups are are based on Role. Please select a role first to decide groups!
                    </li>
                    <ng-template #groupsList>
                      <li *ngFor="let group of allGroups">
                        <input
                          type="checkbox"
                          name="{{ group }}"
                          value="{{ userGroups[group] }}"
                          [(ngModel)]="userGroups[group]"
                          disabled
                          readonly
                        />
                        {{ group }}
                      </li>
                    </ng-template>
                  </ul>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <button type="submit" [disabled]="!userForm.form.valid || loading" class="btn btn-success">
              {{ loading ? "Creating...." : "Create" }}
            </button>
            <button type="button" class="ml-2 btn btn-danger" (click)="userForm.reset()" [hidden]="loading">
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="widget-lists card-columns"></div>
</section>

import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import {
  AngularFirestore,
  DocumentChangeAction,
  DocumentData,
  DocumentReference,
} from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router, ActivatedRoute } from "@angular/router";
import firebase from "firebase/compat/app";
import { AllUserRoles, CollectionNames, HoaUnitTypes } from "app/shared/generic_variables";
import { AllAlerts, Roles, strings } from "environments/environment";
import { VillageType } from "common/typings";
import { map } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
import { orderBy, uniqBy } from "lodash";
import { BoatComponent } from "../boat/boat.component";

@Component({
  selector: "app-school-user",
  templateUrl: "./user.component.html",
})
export class SchoolUserDetailComponent implements OnInit, OnDestroy {
  items = [];
  selectedSchoolId = "";
  selectedUserId = "";
  selectedStudentToRemove: DocumentData;
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  selectedUserData: DocumentData;
  allGroups = [];
  userGroups = {};
  selectedRole: AllUserRoles;
  strings = strings;
  roles = Roles;
  AllAlerts = AllAlerts;
  devices = [];
  userBasicInfo = {
    firstName: "",
    lastName: "",
    phone: "",
    officePhone: "",
    phone_extension: "",
    allowAdminUIPermission: false,
    allowBillingResponsibility: false,
    company: "",
  };
  infoLoading = false;
  isDeletingDevice = false;
  userAlerts: Object;
  allSchoolStudentUsers: DocumentData[] = [];
  selectedStudentToAdd: DocumentData;
  defaultAlertSelectionOption: "all" | "none" | "selectable";
  usStateList = [];
  isOwner = false;

  familyMemberUsers: DocumentData[] = [];
  tenantUsers: DocumentData[] = [];
  tenantFamilyUsers: DocumentData[] = [];

  userLocationOptions = {
    unitType: "",
    address: "",
    city: "",
    state: "",
    unit: "",
    zip: "",
    county: "",
  };

  private subs = new SubSink();
  allBoatsOfUser = [];

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private _modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.selectedSchoolId = params.get("id");
      this.selectedUserId = params.get("userId");
      const schoolRef = db.doc("schools/" + this.selectedSchoolId);
      this.school = schoolRef.valueChanges();
      this.afAuth.authState.subscribe((currentUser) => {
        const uid = currentUser.uid;
        this.afAuth.idTokenResult.subscribe((idTokenResult) => {
          this.isOwner = idTokenResult["claims"]["owner"];
        });
      });
      this.subs.sink = db
        .collection(CollectionNames.users)
        .doc(this.selectedUserId)
        .valueChanges()
        .subscribe(async (_user: DocumentData) => {
          if (_user.students && _user.students.length > 0) {
            let students = [];
            for await (let student of _user.students) {
              const studentRef: DocumentReference = student;
              const studentData = (await studentRef.get()).data();
              if (studentData) students = [...students, { ...studentData, id: studentRef.id }];
            }
            _user = { ..._user, students: students };
          }
          this.selectedUserData = _user;
          try {
            this.allBoatsOfUser = [];
            Object.keys(_user.associatedSchools).forEach((schoolId) => {
              const sData = _user.associatedSchools[schoolId];
              if (sData.boats) {
                Object.keys(sData.boats).forEach((boatId) => {
                  let bData = {
                    ...sData.boats[boatId],
                    schoolId,
                    schoolName: _user.associatedSchools[schoolId]["schoolName"],
                    uniqueId: `${schoolId}_${boatId}`,
                  };
                  if (!bData.id) {
                    bData = { ...bData, id: boatId };
                  }
                  this.allBoatsOfUser = uniqBy([...this.allBoatsOfUser, bData], "uniqueId");
                });
              }
            });
          } catch (er) {
            console.log("error", er);
          }
          if (this.isSchoolVillage) this.getAllStudents();
          this.userBasicInfo.firstName = _user.firstName;
          this.userBasicInfo.lastName = _user.lastName;
          this.userBasicInfo.phone = _user.phone;
          this.userBasicInfo.officePhone = _user?.officePhone ? _user?.officePhone : "";
          this.userBasicInfo.phone_extension = _user?.phone_extension ? _user?.phone_extension : "";
          this.userBasicInfo.allowAdminUIPermission = _user?.allowAdminUIPermission
            ? _user?.allowAdminUIPermission
            : false;
          this.userBasicInfo.allowBillingResponsibility = _user?.allowBillingResponsibility
            ? _user?.allowBillingResponsibility
            : false;
          this.userBasicInfo.company = _user?.company ? _user?.company : "";
          this.devices = Object.keys(_user).indexOf("devices") < 0 ? [] : Object.keys(this.selectedUserData.devices);
          this.selectedRole = _user.associatedSchools?.[this.selectedSchoolId]?.role;
          try {
            if (_user.associatedSchools?.[this.selectedSchoolId]?.role) {
              this.selectedRole = _user.associatedSchools?.[this.selectedSchoolId]?.role;
            }
            if (_user?.isAssociatedToAllSchools && _user?.isDistrictAdmin) {
              this.selectedRole = AllUserRoles.district;
            }
          } catch {}
          this.userAlerts = _user.associatedSchools[this.selectedSchoolId].alerts;
          if (this.userAlerts === undefined) {
            this.userAlerts = {};
          }
          if (this.selectedRole) {
            this.setCurrentAlertSelectionOption(this.selectedRole);
            this.userLocationOptions = {
              ...this.userLocationOptions,
              ...(this.selectedUserData.location ? this.selectedUserData.location : {}),
            };
            if (this.selectedRole === AllUserRoles.unit_owner || this.selectedRole === AllUserRoles.unit_owner_admin) {
              this.userLocationOptions = {
                ...this.userLocationOptions,
                unitType: this.selectedUserData?.unitType ? this.selectedUserData?.unitType : "",
              };
            }
          }
          this.userGroups = _user.associatedSchools[this.selectedSchoolId].groups;
          if (this.userGroups === undefined) {
            this.userGroups = {};
          }
        });
      this.subs.sink = this.school.subscribe((_school) => {
        this.allGroups = Object.keys(_school.groups);
        this.schoolData = _school;
      });

      if (this.isHoaVillage) {
        this.getHoaMembers();
      }
    });
  }

  ngOnInit() {
    this.getAllStates();
  }

  getHoaMembers() {
    this.db
      .collection(CollectionNames.users, (ref) =>
        ref.where("homeOwnerUserId", "==", this.selectedUserId).where("deleted", "==", false)
      )
      .valueChanges()
      .subscribe((_users) => {
        _users.forEach((user: DocumentData) => {
          if (user && user["associatedSchools"] && user["associatedSchools"][this.selectedSchoolId]) {
            const role = user["associatedSchools"][this.selectedSchoolId]["role"];
            if (role === AllUserRoles.family) {
              this.familyMemberUsers = orderBy(uniqBy([...this.familyMemberUsers, user], "id"), "createdAt", "desc");
            } else if (role === AllUserRoles.tenant) {
              this.tenantUsers = orderBy(uniqBy([...this.tenantUsers, user], "id"), "createdAt", "desc");
            } else if (role === AllUserRoles.tenant_family) {
              this.tenantFamilyUsers = orderBy(uniqBy([...this.tenantFamilyUsers, user], "id"), "createdAt", "desc");
            }
          }
        });
      });
  }

  getAllStates() {
    this.db
      .collection(CollectionNames.states)
      .valueChanges()
      .subscribe((states) => {
        if (states) this.usStateList = states;
      });
  }

  getAllStudents() {
    const queryKey = "associatedSchools." + this.selectedSchoolId + ".role";
    this.db
      .collection(CollectionNames.users, (ref) => ref.where(queryKey, "==", AllUserRoles.student))
      .snapshotChanges()
      .pipe(
        map((documents: DocumentChangeAction<DocumentData>[]) =>
          documents.map((userSnap) => {
            const userData = userSnap.payload.doc.data();
            const name =
              (userData.firstName ? userData.firstName : "") + " " + (userData.lastName ? userData.lastName : "");
            return { id: userSnap.payload.doc["id"], ...userData, name: name };
          })
        )
      )
      .subscribe((users) => {
        this.allSchoolStudentUsers = users;
      });
  }

  setCurrentAlertSelectionOption(role: AllUserRoles) {
    if (this.isMarinaVillage) {
      this.defaultAlertSelectionOption = "all";
    } else if (this.isSchoolVillage) {
      switch (role) {
        case AllUserRoles.admin:
        case AllUserRoles.security:
        case AllUserRoles.district:
        case AllUserRoles.maintenance:
        case AllUserRoles.pdFireEms:
          this.defaultAlertSelectionOption = "all";
          break;
        case AllUserRoles.staff:
        case AllUserRoles.student:
        case AllUserRoles.family:
          this.defaultAlertSelectionOption = "none";
          break;
        default:
          this.defaultAlertSelectionOption = "none";
          break;
      }
    }
  }

  onRoleChange(role: AllUserRoles) {
    if (this.isMarinaVillage) {
      this.selectAllGroups();
      this.selectAllAlerts();
      this.defaultAlertSelectionOption = "all";
    } else if (this.isSchoolVillage) {
      switch (role) {
        case AllUserRoles.admin:
        case AllUserRoles.security:
        case AllUserRoles.district:
        case AllUserRoles.maintenance:
        case AllUserRoles.pdFireEms:
          this.selectAllGroups();
          this.selectAllAlerts();
          this.defaultAlertSelectionOption = "all";
          break;
        case AllUserRoles.staff:
        case AllUserRoles.student:
        case AllUserRoles.family:
          this.selectNoAlerts();
          this.selectOnlyGroup(role);
          this.defaultAlertSelectionOption = "none";
          break;
        default:
          this.defaultAlertSelectionOption = "none";
          this.selectNoAlerts();
          this.selectOnlyGroup(role);
          break;
      }
    } else if (this.isHoaVillage) {
      this.selectAllGroups();
      this.selectAllAlerts();
      this.defaultAlertSelectionOption = "all";
    }
  }

  selectAllGroups() {
    this.userGroups = {};
    this.allGroups.forEach((group) => {
      this.userGroups[group] = true;
    });
  }

  selectAllAlerts() {
    this.userAlerts = {};
    this.AllAlerts.forEach((alert) => {
      this.userAlerts[alert.key] = true;
    });
  }

  selectNoAlerts() {
    this.userAlerts = {};
  }

  selectOnlyGroup(group: AllUserRoles) {
    this.userGroups = {};
    this.userGroups[group] = true;
  }

  async resetPassword() {
    try {
      await this.afAuth.sendPasswordResetEmail(this.selectedUserData.email);
      this.toastr.success(
        `Password reset email has been sent to your email ${this.selectedUserData.email}.
            Please check your inbox. Remember to check your spam if not found on inbox.`,
        "Success"
      );
    } catch (error) {
      console.log("error", error);
      this.toastr.error(
        `Sorry, we had trouble sending password reset email to your email ${this.selectedUserData.email}.
            Please try again.`,
        "Error"
      );
    }
  }

  async removeFromSchool() {
    const modalRef = this._modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = `Remove ${this.selectedUserData?.email} from ${this.schoolData?.name}`;
    modalRef.componentInstance.text = `Are you sure you want to remove`;
    modalRef.componentInstance.highlightedText = `${this.selectedUserData?.email}
    (${this.selectedUserData?.firstName} ${this.selectedUserData?.lastName}) from ${this.schoolData?.name} ${strings.villageType}`;
    modalRef.result.then((confirmed) => {
      if (confirmed) {
        const changes = {
          associatedSchools: {},
        };
        changes.associatedSchools[this.selectedSchoolId] = firebase.firestore.FieldValue.delete();
        try {
          this.db.doc(`users/${this.selectedUserId}`).set(changes, { merge: true });
          this.router.navigateByUrl(`app/schools/${this.selectedSchoolId}/users`);
          this.toastr.success(
            `User has been removed successfully from ${this.schoolData.name} ${strings.villageType}.`,
            "Success"
          );
        } catch (error) {
          console.error("error", error);
          this.toastr.error(
            `Sorry, we had trouble removing user from ${this.schoolData.name} ${strings.villageType}.
              Please try again.`,
            "Error"
          );
        }
      }
    });
  }

  async removeFromAllSchools() {
    const modalRef = this._modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = `Remove ${this.selectedUserData?.email} from all ${strings.villageType}s`;
    modalRef.componentInstance.text = `Are you sure you want to remove`;
    modalRef.componentInstance.highlightedText = `${this.selectedUserData?.email}
    (${this.selectedUserData?.firstName} ${this.selectedUserData?.lastName}) from all ${strings.villageType}s`;
    modalRef.result.then((confirmed) => {
      if (confirmed) {
        let changes: any = {
          associatedSchools: {},
          deleted: true,
        };
        if (this.selectedUserData?.isAssociatedToAllSchools) {
          changes = { ...changes, isAssociatedToAllSchools: false };
        }
        if (changes.associatedSchools && changes.associatedSchools[this.selectedSchoolId]) {
          changes.associatedSchools[this.selectedSchoolId] = firebase.firestore.FieldValue.delete();
        }
        try {
          this.db.doc(`users/${this.selectedUserId}`).set(changes, { merge: true });
          this.router.navigateByUrl(`app/schools/${this.selectedSchoolId}/users`);
          this.toastr.success(`User has been removed successfully from all ${strings.villageType}s.`, "Success");
        } catch (error) {
          console.error("error", error);
          this.toastr.error(
            `Sorry, we had trouble removing user from  all ${strings.villageType}s.
              Please try again.`,
            "Error"
          );
        }
      }
    });
  }

  async saveUser() {
    this.infoLoading = true;
    const toUpdateUserData = {
      firstName: this.userBasicInfo.firstName ? this.userBasicInfo.firstName : "",
      lastName: this.userBasicInfo.lastName ? this.userBasicInfo.lastName : "",
      officePhone: this.userBasicInfo.officePhone ? this.userBasicInfo.officePhone.toString() : "",
      phone: this.userBasicInfo.phone ? this.userBasicInfo.phone.toString() : "",
      phone_extension: this.userBasicInfo.phone_extension ? this.userBasicInfo.phone_extension : "",
      allowAdminUIPermission: this.userBasicInfo.allowAdminUIPermission
        ? this.userBasicInfo.allowAdminUIPermission
        : false,
      allowBillingResponsibility: this.userBasicInfo.allowBillingResponsibility
        ? this.userBasicInfo.allowBillingResponsibility
        : false,
      company: this.userBasicInfo.company ? this.userBasicInfo.company : "",
    };
    try {
      await this.db.collection(CollectionNames.users).doc(this.selectedUserId).set(toUpdateUserData, { merge: true });
      this.toastr.success("User is updated successfully!.", "Success");
      this.infoLoading = false;
    } catch (error) {
      console.log("error", error);
      this.toastr.error("Sorry, User data could not updated. Please try again!", "Error");
      this.infoLoading = false;
    }
  }

  saveGroups() {
    const changes = {
      associatedSchools: {},
    };
    changes.associatedSchools[this.selectedSchoolId] = {
      groups: this.getTrueGroups(),
    };
    this.db
      .doc(`users/${this.selectedUserId}`)
      .set(changes, { merge: true })
      .then(() => {
        this.toastr.success("Groups are saved successfully!.", "Success");
      });
  }

  saveAlerts() {
    const changes = {
      associatedSchools: {},
    };
    changes.associatedSchools[this.selectedSchoolId] = {
      alerts: this.getTrueAlerts(),
    };
    this.db
      .doc(`users/${this.selectedUserId}`)
      .set(changes, { merge: true })
      .then(() => {
        this.toastr.success("Alerts are saved successfully!.", "Success");
      });
  }

  saveBoaterInformation(boatData: DocumentData) {
    let data: DocumentData = {
      ...boatData,
      boatLocation: this.getBoatLocation(boatData),
    };
    this.db
      .doc(`users/${this.selectedUserId}`)
      .update({ [`associatedSchools.${this.selectedSchoolId}.boats.${boatData.id}`]: data })
      .then(() => {
        this.toastr.success("Boater information are saved successfully!.", "Success");
      })
      .catch((_) => {
        this.toastr.error("Oops, something went wrong while saving data. Please try again!");
      });
  }

  saveResidentLocation() {
    let changes: DocumentData = {
      associatedSchools: {},
      location: this.getUserLocation,
    };
    changes.associatedSchools[this.selectedSchoolId] = {
      role: this.selectedRole,
      alerts: this.getTrueAlerts(),
      groups: this.getTrueGroups(),
    };
    if (this.userLocationOptions?.unitType) {
      changes = { ...changes, unitType: this.userLocationOptions.unitType };
    }
    this.db
      .doc(`users/${this.selectedUserId}`)
      .set(changes, { merge: true })
      .then(() => {
        this.toastr.success("Resident location is saved successfully.", "Success");
      })
      .catch((_) => {
        this.toastr.error("Oops, something went wrong while saving data. Please try again!");
      });
  }

  saveRole() {
    if (!this.selectedRole) {
      return;
    }
    let changes: DocumentData = {
      associatedSchools: {},
    };
    if (this.selectedRole !== AllUserRoles.family) {
      changes = { ...changes, students: [] };
    }
    changes.associatedSchools[this.selectedSchoolId] = {
      role: this.selectedRole,
      alerts: this.getTrueAlerts(),
    };
    const batch = this.db.firestore.batch();
    const userRef = this.db.doc(`users/${this.selectedUserId}`).ref;
    batch.set(userRef, changes, { merge: true });
    batch.update(userRef, {
      [`associatedSchools.${this.selectedSchoolId}.groups`]: this.getTrueGroups(),
    });
    batch.commit().then(() => {
      this.toastr.success("Role saved successfully!.", "Success");
    });
  }

  hasGroupsChanged() {
    const groupsChanged = Object.keys(this.getTrueGroups());
    if (this.selectedUserData == null) {
      return false;
    }
    let groupsOld = this.selectedUserData.associatedSchools[this.selectedSchoolId].groups;
    if (groupsOld == null) {
      groupsOld = {};
    }
    const groupsChangesKeys = Object.keys(groupsOld);
    return !(
      groupsChanged.length === groupsChangesKeys.length && groupsChanged.every((v, i) => v === groupsChangesKeys[i])
    );
  }

  getTrueGroups() {
    this.onRoleChange(this.selectedRole);
    if (this.userGroups == null) {
      return {};
    }
    const cleanGroups = this.userGroups;
    const keys = Object.keys(cleanGroups);
    for (const key of keys) {
      if (cleanGroups[key] === false) {
        cleanGroups[key] = firebase.firestore.FieldValue.delete();
      }
    }
    return cleanGroups;
  }

  getTrueAlerts() {
    this.onRoleChange(this.selectedRole);
    if (this.userAlerts == null) {
      return {};
    }
    const cleanAlerts = this.userAlerts;
    const keys = Object.keys(cleanAlerts);
    for (const key of keys) {
      if (cleanAlerts[key] === false) {
        cleanAlerts[key] = firebase.firestore.FieldValue.delete();
      }
    }
    return cleanAlerts;
  }

  getBackLink() {
    return `/app/schools/${this.selectedSchoolId}/users`;
  }

  deleteDevice(device) {
    this.isDeletingDevice = true;
    const deletedDevices = {
      devices: {},
    };
    deletedDevices.devices[device] = firebase.firestore.FieldValue.delete();
    this.db
      .doc(`users/${this.selectedUserId}`)
      .set(deletedDevices, { merge: true })
      .then(() => {
        this.isDeletingDevice = false;
      });
  }

  openRemoveStudentModal(student: DocumentData, templateRef: TemplateRef<any>) {
    this.selectedStudentToRemove = student;
    const modalRef = this._modalService.open(templateRef);
  }

  async removeStudent() {
    try {
      const studentRef = this.db.doc(`${CollectionNames.users}/${this.selectedStudentToRemove.id}`).ref;
      await this.db
        .doc(`${CollectionNames.users}/${this.selectedUserId}`)
        .set({ students: firebase.firestore.FieldValue.arrayRemove(studentRef) }, { merge: true });
      this._modalService.dismissAll();
    } catch (error) {
      console.log("error", error);
    }
  }

  async addStudent(addStudentButton: HTMLButtonElement) {
    if (!this.selectedStudentToAdd) {
      return;
    }
    if (this.selectedRole !== AllUserRoles.family) {
      return;
    }
    const studentRef = this.db.doc(`${CollectionNames.users}/${this.selectedStudentToAdd.id}`).ref;
    const changes: DocumentData = {
      associatedSchools: {},
      students: firebase.firestore.FieldValue.arrayUnion(studentRef),
    };
    changes.associatedSchools[this.selectedSchoolId] = {
      role: this.selectedRole,
      alerts: this.getTrueAlerts(),
    };
    addStudentButton.disabled = true;
    addStudentButton.innerHTML = "Adding...";
    try {
      await this.db.doc(`${CollectionNames.users}/${this.selectedUserId}`).set(changes, { merge: true });
      this._modalService.dismissAll();
      resetButtonAttrs();
    } catch (error) {
      console.log("error", error);
      resetButtonAttrs();
    }

    function resetButtonAttrs() {
      addStudentButton.disabled = false;
      addStudentButton.innerHTML = "Add";
    }
  }

  studentSelectEvent(selectedStudentToAdd: DocumentData) {
    this.selectedStudentToAdd = selectedStudentToAdd;
  }

  onChangeSearch(val: string) {}

  onFocused(e) {}

  closeModal() {
    this._modalService.dismissAll();
    this.selectedStudentToRemove = null;
  }

  addAnotherBoat() {
    const modalRef = this._modalService.open(BoatComponent, { size: "lg" });
    modalRef.componentInstance.showSchoolOptions = true;
    modalRef.result.then((res) => {
      if (res) {
        let { added, boatData } = res;
        if (added) {
          boatData = {
            ...boatData,
            id: boatData.boaterId,
          };
          const boatSchoolId = boatData["schools"][0]["id"];
          const boatSchoolName = boatData["schools"][0]["name"];
          delete boatData["boaterId"];
          delete boatData["schools"];
          let userData = { ...this.selectedUserData };
          try {
            if (userData["associatedSchools"][boatSchoolId]) {
              if (userData["associatedSchools"][boatSchoolId]["boats"]) {
                userData["associatedSchools"][boatSchoolId]["boats"][boatData.id] = boatData;
              } else {
                userData["associatedSchools"][boatSchoolId]["boats"] = {
                  [boatData.id]: boatData,
                };
              }
            } else {
              userData["associatedSchools"][boatSchoolId] = {
                groups: this.getTrueGroups(),
                alerts: this.getTrueAlerts(),
                allowed: true,
                role: AllUserRoles.boater,
                boats: { [boatSchoolId]: boatData },
                schoolName: boatSchoolName ? boatSchoolName : "",
              };
            }
          } catch (err) {
            console.log("err", err);
            this.toastr.error("Oops, something went wrong.", "Error");
          }
          this.db
            .doc(`users/${this.selectedUserId}`)
            .update(userData)
            .then(() => {
              this.toastr.success("Boater information are saved successfully.", "Success");
            })
            .catch((_) => {
              this.toastr.error("Oops, something went wrong while saving data. Please try again!");
            });
        }
      }
    });
  }

  removeBoat(boatData: DocumentData) {
    const modalRef = this._modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = `Delete the Boat`;
    modalRef.componentInstance.text = `Are you sure you want to delete the boat`;
    modalRef.componentInstance.highlightedText = `${boatData.boatName}?`;
    modalRef.result
      .then((confirmed) => {
        if (confirmed) {
          try {
            this.db.doc(`users/${this.selectedUserData.id}`).update({
              [`associatedSchools.${boatData.schoolId}.boats.${boatData.id}`]: firebase.firestore.FieldValue.delete(),
            });
            this.toastr.success(`Boat  has been successfully deleted.`, "Success");
          } catch (error) {
            console.error("error", error);
            this.toastr.error(`Sorry, we had trouble deleting the boat .Please try again.`, "Error");
          }
        }
      })
      .catch();
  }

  get isSchoolVillage() {
    return this.strings.villageType === VillageType.SCHOOL;
  }

  get isMarinaVillage() {
    return this.strings.villageType === VillageType.MARINA;
  }

  get isHoaVillage() {
    return this.strings.villageType === VillageType.HOA;
  }

  get isSelectedUserFamilyType() {
    return this.selectedRole === AllUserRoles.family;
  }

  get AllUserRoles() {
    return AllUserRoles;
  }

  get showUnitType() {
    return (
      strings.villageType === VillageType.HOA &&
      this.selectedUserData &&
      (this.selectedUserData[this.selectedSchoolId]["role"] === AllUserRoles.unit_owner ||
        this.selectedUserData[this.selectedSchoolId]["role"] === AllUserRoles.unit_owner_admin)
    );
  }

  private getBoatLocation(boatData: DocumentData) {
    const { dockNumber, slipNumber, mooring, street, city, state, zip, description } = boatData?.boatLocation;
    let boatLocation = {};
    switch (boatData.boatLocationType) {
      case "dockSlip":
        boatLocation = { dockNumber: dockNumber ? dockNumber : "", slipNumber: slipNumber ? slipNumber : "" };
        break;
      case "mooring":
        boatLocation = { mooring: mooring };
        break;
      case "address":
        boatLocation = {
          street: street ? street : "",
          city: city ? city : "",
          state: state ? state : "",
          zip: zip ? zip : "",
        };
        break;
      case "others":
        boatLocation = { description: description ? description : "" };
        break;
    }
    return boatLocation;
  }

  private get getUserLocation() {
    const { address, city, state, zip, unit, county } = this.userLocationOptions;
    return {
      address: address ? address : "",
      city: city ? city : "",
      county: county ? county : "",
      state: state ? state : "",
      zip: zip ? zip : "",
      unit: unit ? unit : "",
    };
  }

  get isAdmin() {
    return this.selectedRole === AllUserRoles.admin || this.selectedRole === AllUserRoles.unit_owner_admin;
  }

  get showCompanyField() {
    return (
      this.selectedRole === AllUserRoles.admin ||
      this.selectedRole === AllUserRoles.maintenance ||
      this.selectedRole === AllUserRoles.district ||
      this.selectedRole === AllUserRoles.security ||
      this.selectedRole === AllUserRoles.pdFireEms
    );
  }

  get HoaUnitTypes() {
    return HoaUnitTypes;
  }

  ngOnDestroy() {
    if (this.subs) this.subs.unsubscribe();
  }
}

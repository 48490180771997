import { Component, ViewChild, TemplateRef, OnInit } from "@angular/core";
import { endOfDay, isSameDay, isSameMonth } from "date-fns";
import { CalendarEvent, CalendarView } from "angular-calendar";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Observable } from "rxjs";
import { CollectionNames } from "app/shared/generic_variables";
import * as moment from "moment";
import { groupBy } from "lodash";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SingleDateVisitorsComponentModal } from "./single-date-visitors/single-date-visitors.component";

const colors = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-visitors",
  templateUrl: "./visitors.component.html",
  styleUrls: ["./visitors.component.css"],
})
export class VisitorsComponent implements OnInit {
  @ViewChild("modalContent") modalContent: TemplateRef<any>;
  selectedId = "";
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  loading = false;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  activeDayIsOpen = false;

  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      const schoolRef = db.doc("schools/" + this.selectedId);
      this.school = schoolRef.valueChanges();
      this.school.subscribe((scl) => {
        this.schoolData = scl;
      });
      const visitorsColRef = db
        .collection(CollectionNames.schools)
        .doc(this.selectedId)
        .collection(CollectionNames.visitors, (ref) => ref.orderBy("createdAt", "desc"));
      visitorsColRef.valueChanges().subscribe((allVisitors: DocumentData[]) => {
        this.events = [];
        allVisitors.forEach((visitor) => {
          visitor["date"] = moment(visitor.createdAt.toDate()).format("YYYY-MM-DD");
        });
        const groupedVisitorsByDate = groupBy(allVisitors, "date");
        Object.entries(groupedVisitorsByDate).forEach(([date, visitorsOfDate]) => {
          const totalVisitors = visitorsOfDate.length;
          this.events.push(<CalendarEvent>{
            start: endOfDay(new Date(date)),
            end: endOfDay(new Date(date)),
            title: `${totalVisitors} visitors were tracked on ${date}. Click to view detail.`,
            color: colors.blue,
            draggable: false,
            meta: {
              selectedVisitors: visitorsOfDate,
              incrementsBadgeTotal: false,
            },
          });
        });
      });
    });
  }

  ngOnInit() {}

  handleEvent(event: CalendarEvent): void {
    const modalRef = this.modalService.open(SingleDateVisitorsComponentModal, { size: "xl" });
    modalRef.componentInstance.selectedDate = event.start;
    modalRef.componentInstance.selectedVisitors = event.meta.selectedVisitors;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}

<section class="content">
  <header class="content__title" *ngIf="schoolData">
    <h1><a [routerLink]="getBackLink()">Back</a></h1>
    <br />
    <h1>{{ schoolData.name }}</h1>
    <small>{{ schoolData.city }}</small>
  </header>

  <!-- <div class="row">
    <div class="col-md-12" *ngFor="let group of getObject().keys((school | async)?.groups)">
      <div class="card" style="cursor: pointer;">
        <div class="card-body">
          <h4 class="card-title">{{group}}</h4>
          <h6 class="card-subtitle" (click)="deleteGroup(group)"><i class="zmdi zmdi-delete"></i></h6>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col-md-12">
      <div class="panel-body">
        <div *ngFor="let item of groups; let i = index" style="list-style: none">
          <div class="card" style="cursor: pointer">
            <div class="card-body">
              <h4 class="card-title">{{ item.key }}</h4>
              <h6 class="card-subtitle" (click)="deleteGroup(i)"><i class="zmdi zmdi-delete"></i></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <form (ngSubmit)="createNewGroup()" #heroForm="ngForm">
        <div class="form-group">
          <label for="newGroup">New Group</label>
          <input
            type="text"
            class="form-control"
            id="newGroup"
            pattern="[A-Za-z][A-Za-z_\s]+"
            required
            [(ngModel)]="newGroup"
            name="newGroup"
          />
          <!-- <div [hidden]="newGroup.valid || newGroup.pristine" class="alert alert-danger">
            Name is required
          </div> -->
        </div>

        <button type="submit" class="btn btn-success" [disabled]="!heroForm.form.valid || loading">
          {{ loading ? "Creating.." : "Create" }}
        </button>
      </form>
    </div>
  </div>

  <div class="widget-lists card-columns"></div>
</section>

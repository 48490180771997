import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public afAuth: AngularFireAuth) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handleIntercept(request, next));
  }

  private async handleIntercept(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await (await this.afAuth.currentUser).getIdToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).toPromise();
  }
}

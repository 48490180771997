<section class="content">
  <header class="content__title" *ngIf="schoolData">
    <h1><a [routerLink]="getBackLink()">Back</a></h1>
    <br />
    <h1>{{ schoolData.name }}</h1>
    <small>{{ schoolData.city }}</small>
  </header>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h6 *ngIf="!isEditing()">Create New Document</h6>
          <h6 *ngIf="isEditing()">Editing Document</h6>
          <form (ngSubmit)="onSubmit(heroForm)" #heroForm="ngForm">
            <div class="form-group">
              <label for="title">Title (in app)</label>
              <input
                type="text"
                class="form-control"
                id="title"
                required
                [(ngModel)]="editing.title"
                name="title"
                #title="ngModel"
              />
              <div [hidden]="title.valid || title.pristine" class="alert alert-danger">Title (in app)</div>
            </div>

            <div class="form-group">
              <label for="type">Link Type</label>
              <select class="form-control" id="type" required [(ngModel)]="editing.type" name="type" #type="ngModel">
                <option [value]="'url'">URL</option>
                <option [value]="'pdf'">PDF</option>
                <option [value]="'linked-pdf'">Linked-PDF</option>
                <option [value]="'internal-link'">Internal Link</option>
                <option [value]="'media'">Media</option>
              </select>
              <div [hidden]="type.valid || type.pristine" class="alert alert-danger">Type is required</div>
            </div>

            <div class="form-group">
              <label for="icon">Icon</label>
              <select class="form-control" id="icon" required [(ngModel)]="editing.icon" name="icon" #icon="ngModel">
                <option *ngFor="let f of imageFiles" [value]="f.fullPath">{{ f.fullPath }}</option>
              </select>
              <div [hidden]="icon.valid || icon.pristine" class="alert alert-danger">Icon is required</div>
            </div>

            <div class="form-group" *ngIf="isEditingPdf()">
              <label for="location">File</label>
              <select
                class="form-control"
                id="location"
                required
                [(ngModel)]="editing.location"
                name="location"
                #location="ngModel"
              >
                <option *ngFor="let f of pdfs" [value]="f.fullPath">{{ f.fullPath }}</option>
              </select>
              <div [hidden]="location.valid || location.pristine" class="alert alert-danger">Location is required</div>
            </div>

            <div class="form-group" *ngIf="isEditingLinkedPdf()">
              <label for="location">File</label>
              <select
                class="form-control"
                id="location"
                required
                [(ngModel)]="editing.location"
                name="location"
                #location="ngModel"
              >
                <option *ngFor="let item of linkedPdfs" [value]="getLinkedPdfFullPath(item.root)">
                  {{ getLinkedPdfFullPath(item.root) }}
                </option>
              </select>
              <div [hidden]="location.valid || location.pristine" class="alert alert-danger">Location is required</div>
            </div>

            <div class="form-group" *ngIf="isEditingUrl()">
              <label for="location">Location</label>
              <input
                type="text"
                class="form-control"
                id="location"
                required
                placeholder="URL"
                [(ngModel)]="editing.location"
                name="location"
                #location="ngModel"
              />
              <div [hidden]="location.valid || location.pristine" class="alert alert-danger">Location is required</div>
            </div>

            <div class="form-group">
              <label for="category">Category</label>
              <select
                class="form-control"
                id="category"
                [(ngModel)]="editing.category"
                name="category"
                #category="ngModel"
              >
                <option *ngFor="let c of categories | keyvalue" [value]="c.value">{{ c.value }}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="display">Display on Mobile App</label><br />
              <select
                class="form-control"
                id="display"
                required
                [(ngModel)]="editing.display"
                name="display"
                #display="ngModel"
              >
                <option [ngValue]="true">Yes</option>
                <option [ngValue]="false">No</option>
              </select>
              <div [hidden]="display.valid || display.pristine" class="alert alert-danger">
                Should the document be displayed on mobile app?
              </div>
            </div>

            <div class="form-group">
              <label for="downloadOnLogin">Download on Login?</label><br />
              <select
                class="form-control"
                id="downloadOnLogin"
                required
                [(ngModel)]="editing.downloadOnLogin"
                name="downloadOnLogin"
                #downloadOnLogin="ngModel"
              >
                <option [ngValue]="true">Yes</option>
                <option [ngValue]="false">No</option>
              </select>
              <div [hidden]="downloadOnLogin.valid || downloadOnLogin.pristine" class="alert alert-danger">
                Should the document be downloaded on login?
              </div>
            </div>

            <div class="form-group">
              <label for="item_location">Document Location (How it appears in Mobile App)</label><br />
              <input
                [disabled]="true"
                type="text"
                class="form-control"
                id="item_location"
                required
                [(ngModel)]="editing.item_location"
                name="item_location"
                #item_location="ngModel"
              />
              <div [hidden]="item_location.valid || item_location.pristine" class="alert alert-danger">
                Item Location
              </div>
            </div>

            <div class="form-group">
              <label for="item_position">Document Location (Which ranking it appears in Mobile App)</label><br />
              <input
                [disabled]="true"
                type="text"
                class="form-control"
                id="item_position"
                required
                [(ngModel)]="editing.item_position"
                name="item_position"
                #item_position="ngModel"
              />
              <div [hidden]="item_position.valid || item_position.pristine" class="alert alert-danger">
                Item Location
              </div>
            </div>

            <div class="form-group">
              <label for="accessRoles">Roles</label><br />
              <select
                class="form-control"
                id="accessRoles"
                required
                [(ngModel)]="editing.accessRoles"
                name="accessRoles"
                #accessRoles="ngModel"
                multiple
              >
                <option *ngFor="let r of userRoles" [value]="r">{{ r }}</option>
              </select>
              <div [hidden]="accessRoles.valid || accessRoles.pristine" class="alert alert-danger">
                Select valid roles
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-success"
              [disabled]="!heroForm.form.valid || loading"
              *ngIf="!isEditing()"
            >
              {{ loading ? "Creating.." : "Create" }}
            </button>
            <button
              type="submit"
              class="btn btn-success"
              [disabled]="!heroForm.form.valid || loading"
              *ngIf="isEditing()"
            >
              {{ loading ? "Saving.." : "Save" }}
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-danger" (click)="resetDocument()" [hidden]="loading">Reset</button>
            <br /><br /><br /><br />
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="panel-body">
        <div *ngFor="let item of documents; let i = index" style="list-style: none">
          <div class="card" style="cursor: pointer">
            <div class="card-body">
              <img src="{{ getImgUrl(i) }}" style="width: 72px; height: 72px" /><br /><br />
              <h4 class="card-title">{{ item.title }}</h4>
              <h6 *ngIf="item.location" class="card-subtitle" (click)="download(i)">
                {{ item.location }} &nbsp;&nbsp; <i class="zmdi zmdi-download"></i>
              </h6>
              <h6 class="card-subtitle">Type: {{ item.type }}</h6>
              <h6 *ngIf="item?.media_type" class="card-subtitle">Media Type: {{ item.media_type }}</h6>
              <h6 class="card-subtitle" *ngIf="item.category">Category: {{ item.category }}</h6>
              <h6 class="card-subtitle">Download on login: {{ item.downloadOnLogin }}</h6>
              <h6 class="card-subtitle">Access Roles: {{ item.accessRoles }}</h6>
              <h6 class="card-subtitle">Item Position: {{ item.item_position }}</h6>
              <h6 class="card-subtitle">Item Location: {{ item.item_location }}</h6>
              <h6 class="card-subtitle">
                Display:
                <span class="" [ngStyle]="{ color: item?.display ? 'green' : 'red' }">{{
                  item?.display ? "True" : "False"
                }}</span>
              </h6>
              <a (click)="edit(i)" class="card-link"><i class="zmdi zmdi-edit"></i> &nbsp;&nbsp;EDIT</a>
              <a (click)="deleteDocument(i)" class="card-link" style="color: red"
                ><i class="zmdi zmdi-delete"></i> &nbsp;&nbsp;DELETE</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="widget-lists card-columns"></div>
</section>

<ng-template
  bsModal
  #lgModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
>
  <div class="modal-dialog modal-lg" style="width: 600px">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">Edit Document</h4>
        <button type="button" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
          <div class="form-group">
            <label for="title">School Name</label>
            <input
              type="text"
              class="form-control"
              id="title"
              required
              [(ngModel)]="editing.title"
              name="title"
              #title="ngModel"
            />
            <div [hidden]="title.valid || title.pristine" class="alert alert-danger">Title (in app)</div>
          </div>

          <div class="form-group">
            <label for="type">Link Type</label>
            <select class="form-control" id="type" required [(ngModel)]="editing.type" name="type" #type="ngModel">
              <option [value]="'url'">URL</option>
              <option [value]="'pdf'">PDF</option>
              <option [value]="'linked-pdf'">Linked-PDF</option>
              <option [value]="'internal-link'">Internal Link</option>
              <option [value]="'media'">Media</option>
            </select>
            <div [hidden]="type.valid || type.pristine" class="alert alert-danger">Type is required</div>
          </div>

          <div class="form-group">
            <label for="icon">Icon</label>
            <select class="form-control" id="icon" required [(ngModel)]="editing.icon" name="icon" #icon="ngModel">
              <option *ngFor="let f of imageFiles" [value]="f.fullPath">{{ f.fullPath }}</option>
            </select>
            <div [hidden]="icon.valid || icon.pristine" class="alert alert-danger">Icon is required</div>
          </div>

          <div class="form-group" *ngIf="isEditingPdf()">
            <label for="location">File</label>
            <select
              class="form-control"
              id="location"
              required
              [(ngModel)]="editing.location"
              name="location"
              #location="ngModel"
            >
              <option *ngFor="let f of pdfs" [value]="f.fullPath">{{ f.fullPath }}</option>
            </select>
            <div [hidden]="location.valid || location.pristine" class="alert alert-danger">Location is required</div>
          </div>

          <div class="form-group" *ngIf="!isEditingPdf()">
            <label for="location">Location</label>
            <input
              type="text"
              class="form-control"
              id="location"
              required
              placeholder="URL"
              [(ngModel)]="editing.location"
              name="location"
              #location="ngModel"
            />
            <div [hidden]="location.valid || location.pristine" class="alert alert-danger">Location is required</div>
          </div>

          <button type="submit" class="btn btn-success" [disabled]="!heroForm.form.valid || loading">
            {{ loading ? "Creating.." : "Create" }}
          </button>
          <button type="button" class="btn btn-danger" (click)="heroForm.reset()" [hidden]="loading">Reset</button>
          <br /><br /><br /><br />
        </form>
      </div>
    </div>
  </div>
</ng-template>

<section class="content">
  <header class="content__title">
    <h1><a class="link" (click)="getBackLink()">Back</a></h1>
    <br />
    <div class="row no-gutters">
      <div class="col">
        <div class="card-block px-2">
          <h1 style="font-size: 18px; margin-top: 12px">
            {{ schoolData?.name }}
          </h1>
          <small style="font-size: 11px">{{ schoolData?.city }}</small>
        </div>
      </div>
      <div class="col">
        <div class="float-right">
          <button class="btn btn-success float-end" (click)="openAddEventDialog()">Add new Event</button>
        </div>
      </div>
    </div>
  </header>
  <div class="row text-center">
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="calendarViewDateChanged()"
        >
          Previous
        </div>
        <div
          class="btn btn-outline-secondary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="calendarViewDateChanged()"
        >
          Today
        </div>
        <div
          class="btn btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="calendarViewDateChanged()"
        >
          Next
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate : view + "ViewTitle" : "en" }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
          Month
        </div>
        <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
          Week
        </div>
        <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
          Day
        </div>
      </div>
    </div>
  </div>
  <br />
  <div [ngSwitch]="view" class="mb-5">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="calendarEvents"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="openEventViewModal($event.event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="calendarEvents"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="openEventViewModal($event.event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="calendarEvents"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="openEventViewModal($event.event)"
    >
    </mwl-calendar-day-view>
  </div>

  <div>
    <h5 *ngIf="activeDayIsOpen">Events on only {{ viewDate | date }}</h5>
    <h5 *ngIf="!activeDayIsOpen">Events of the month ({{ viewDate | date : "MMMM , y" }})</h5>
    <ngx-datatable
      class="material"
      [rowHeight]="'auto'"
      [headerHeight]="50"
      [footerHeight]="50"
      [columnMode]="'force'"
      [rows]="selectedDateCalendarEvents"
      (activate)="onActivate($event)"
      [columns]="columns"
      [scrollbarH]="true"
    >
      <ngx-datatable-column *ngFor="let column of columns" [name]="column.name" [prop]="column.prop">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-rowIndex="rowIndex">
          <ng-container *ngIf="column.prop === 'actions'">
            <button (click)="openEventViewModal(row)" class="btn btn-info mr-4">View</button>
            <button (click)="openEditEventDialog(row)" class="btn btn-success mr-4">Edit</button>
            <button (click)="confirmEventDeletion(row)" class="btn btn-danger">Delete</button>
          </ng-container>
          <div *ngIf="column.prop !== 'actions'">{{ value }}</div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <ng-template #eventViewTemplate>
    <div class="card text-center" style="min-width: 500px">
      <div class="card-header" style="height: 40px">Event Detail</div>
      <div class="card-body">
        <h5 class="card-title">{{ selectedEventData?.title }}</h5>
        <h6 class="card-subtitle">
          <span>
            {{ selectedEventData?.startTimestamp?.toDate() | date : "medium" }} -
            {{ selectedEventData?.endTimestamp?.toDate() | date : "medium" }}
          </span>
          <span *ngIf="selectedEventData?.isRecurring" class="ml-2"
            ><span [ngStyle]="{ color: 'green' }">{{ "Recurring" }}</span>
            <span *ngIf="selectedEventData?.recurrenceRule">
              | <span [ngStyle]="{ color: 'green' }">{{ selectedEventData?.recurrenceRule }}</span></span
            >
          </span>
        </h6>
        <p class="card-text">{{ selectedEventData?.description }}</p>
      </div>
      <div class="card-footer">
        <button type="button" class="ml-2 btn btn-secondary" (click)="closeModal()" [hidden]="loading">Close</button>
      </div>
    </div>
  </ng-template>

  <ng-template #eventAddEditTemplate>
    <form (ngSubmit)="onSubmit()" #eventForm="ngForm">
      <div class="modal-body" style="min-width: 500px">
        <div class="form-group">
          <label for="title">Event Title<span class="required-field"></span></label>
          <input
            type="text"
            class="form-control"
            id="title"
            required
            [(ngModel)]="eventModel.title"
            name="title"
            #title="ngModel"
          />
          <div [hidden]="title.valid || title.pristine" class="alert alert-danger">Event Title is required</div>
        </div>

        <div class="form-group">
          <label for="name">Event Location<span class="required-field"></span></label>
          <input
            type="text"
            class="form-control"
            id="location"
            required
            [(ngModel)]="eventModel.location"
            name="location"
            #location="ngModel"
          />
          <div [hidden]="location.valid || location.pristine" class="alert alert-danger">
            Event Location is required
          </div>
        </div>

        <div class="form-group">
          <label for="email">Start DateTime<span class="required-field"></span></label>
          <input
            type="datetime-local"
            id="startTimestamp"
            name="startTimestamp"
            [min]="todayDate"
            class="form-control"
            required
            [(ngModel)]="eventModel.startTimestamp"
            name="startTimestamp"
            #startTimestamp="ngModel"
          />
          <div [hidden]="startTimestamp.valid || startTimestamp.pristine" class="alert alert-danger">
            Valid Start DateTime is required
          </div>
        </div>

        <div class="form-group">
          <label for="endTimestamp">End DateTime<span class="required-field"></span></label>
          <input
            type="datetime-local"
            id="endTimestamp"
            name="endTimestamp"
            [min]="eventModel.startTimestamp"
            class="form-control"
            required
            [(ngModel)]="eventModel.endTimestamp"
            name="endTimestamp"
            #endTimestamp="ngModel"
          />
          <div [hidden]="endTimestamp.valid || endTimestamp.pristine" class="alert alert-danger">
            Valid End DateTime is required
          </div>
        </div>

        <!-- <div class="form-group">
          <input
            class="form-check-input"
            type="checkbox"
            id="isRecurring"
            [(ngModel)]="eventModel.isRecurring"
            name="isRecurring"
            [checked]="eventModel.isRecurring === true"
          />
          <label class="form-check-label"> Is Recurring Event</label>
        </div>
        <div *ngIf="eventModel.isRecurring === true" class="form-group">
          <label for="recurrenceRule">Recurrence Rule</label>
          <select
            class="form-control"
            id="recurrenceRule"
            required
            [(ngModel)]="eventModel.recurrenceRule"
            name="recurrenceRule"
          >
            <option value="" [disabled]="true">Select Recurrence Rule</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div> -->
        <div class="form-group">
          <label>Event Description</label>
          <textarea
            class="form-control"
            id="description"
            [(ngModel)]="eventModel.description"
            name="description"
            #description="ngModel"
            rows="5"
          ></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" [disabled]="!eventForm.form.valid || loading" class="btn btn-success">
          <span *ngIf="addEditMode === 'edit'">{{ loading ? "Updating...." : "Update" }}</span>
          <span *ngIf="addEditMode === 'create'">{{ loading ? "Creating...." : "Create" }}</span>
        </button>
        <button
          type="button"
          class="ml-2 btn btn-secondary"
          (click)="eventForm.reset(); closeModal()"
          [hidden]="loading"
        >
          Cancel
        </button>
      </div>
    </form>
  </ng-template>
</section>

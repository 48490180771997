<section class="content">
  <header class="content__title" *ngIf="schoolData">
    <h1><a [routerLink]="getBackLink()">Back</a></h1>
    <br />
    <h1>{{ schoolData.name }}</h1>
    <small>{{ schoolData.city }}</small>
  </header>

  <div class="row">
    <div class="col-md-6">
      <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
        <div class="form-group">
          <label for="name"> Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            required
            [(ngModel)]="model.name"
            name="name"
            #name="ngModel"
          />
          <div [hidden]="name.valid || name.pristine" class="alert alert-danger">Name is required</div>
        </div>

        <button type="submit" class="btn btn-success" [disabled]="!heroForm.form.valid || loading">
          {{ loading ? "Saving.." : "Save" }}
        </button>
        <br /><br />
        <input
          id="cin"
          name="cin"
          type="file"
          (change)="fileChangeEvent($event)"
          placeholder="Upload a file..."
          multiple
        />
        <br /><br />
        <h4 *ngIf="model.root">Root Document: {{ model.root }}</h4>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6" *ngFor="let item of filesToUpload">
      <div class="card" style="cursor: pointer">
        <div class="card-body">
          <h4 class="card-title">{{ item.name }}</h4>
          <h6 class="card-subtitle">{{ item.fullPath }}</h6>
          <div class="btn btn-secondary" (click)="setRoot(item.name)">Set As Root Document</div>
        </div>
      </div>
    </div>
  </div>
  <div class="widget-lists card-columns"></div>
</section>

import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs/Observable";

@Component({
  selector: "app-school-linked-pdfs",
  templateUrl: "./school-linked-pdfs.component.html",
})
export class SchoolLinkedPDFsComponent implements OnInit {
  items = [];
  selectedId = "";
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  loading = false;
  uploadPercent: Observable<number>;

  token = "";
  error = "";

  model = {
    name: "",
    root: "",
  };
  filesToUpload: Array<File> = [];

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private storage: AngularFireStorage
  ) {
    this.afAuth.authState.subscribe((_) => {
      this.route.paramMap.subscribe((params) => {
        this.selectedId = params.get("id");
        const schoolRef = db.doc("schools/" + this.selectedId);
        this.school = schoolRef.valueChanges();
        this.school.subscribe((scl) => {
          this.schoolData = scl;
        });
        // this.loadFiles();
      });
    });
  }

  loadFiles() {
    this.storage.storage
      .ref("Schools/" + this.selectedId + "/Documents")
      .listAll()
      .then((files) => {
        this.items = files.items;
        this.items.forEach((element) => {});
      });
  }

  ngOnInit() {}

  uploadFile(event: any) {
    const file = event.target.files[0];
    const filePath = "Schools/" + this.selectedId + "/Documents/" + file.name;
    this.loading = true;
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.then((_) => {
      this.loadFiles();
      this.loading = false;
    });
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = [...this.filesToUpload, ...(<Array<File>>fileInput.target.files)];
    if (!this.model.root && this.filesToUpload.length > 0) {
      this.model.root = this.filesToUpload[0].name;
    }
  }

  async onSubmit() {
    this.loading = true;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      const filePath =
        "Schools/" + this.selectedId + "/Documents/" + this.model.name + "/" + this.filesToUpload[i].name;
      this.loading = true;
      const task = await this.storage.upload(filePath, this.filesToUpload[i]);
    }
    try {
      await this.db.collection(`schools/${this.selectedId}/linked-pdfs`).add(this.model);
      this.router.navigateByUrl(`app/schools/${this.selectedId}/assets`);
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  getBackLink() {
    return `/app/schools/${this.selectedId}/assets`;
  }

  setRoot(filename: string) {
    this.model.root = filename;
  }
}

<section class="content">
  <header class="content__title">
    <h1><a [routerLink]="getBackLink()">Back</a></h1>
    <small>{{ (school | async)?.name }}</small>
    <div class="row">
      <div class="offset-6 col-6">
        <button class="btn btn-info mr-2" (click)="resetPassword()">Reset Password</button>
        <button
          *ngIf="!selectedUserData?.isAssociatedToAllSchools"
          class="btn btn-warning mr-2"
          (click)="removeFromSchool()"
        >
          Remove from {{ strings.villageType }}
        </button>
        <button class="btn btn-danger" (click)="removeFromAllSchools()">
          Remove from All {{ strings.villageType }}s
        </button>
      </div>
    </div>
  </header>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title"><i class="zmdi zmdi-account"></i>&nbsp;&nbsp;Edit User</h4>
          <h6 class="card-subtitle"><i class="zmdi zmdi-email"></i>&nbsp;&nbsp;&nbsp;{{ selectedUserData?.email }}</h6>
          <div class="row">
            <div class="col-md-12">
              <form (ngSubmit)="saveUser()" #userForm="ngForm">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="firstName"
                        required
                        [(ngModel)]="userBasicInfo.firstName"
                        name="firstName"
                        #firstName="ngModel"
                      />
                      <div [hidden]="firstName.valid || firstName.pristine" class="alert alert-danger">
                        First Name is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="lastName"
                        required
                        [(ngModel)]="userBasicInfo.lastName"
                        name="lastName"
                        #lastName="ngModel"
                      />
                      <div [hidden]="lastName.valid || lastName.pristine" class="alert alert-danger">
                        Last Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="phone">Mobile Phone</label>
                      <input
                        type="text"
                        class="form-control"
                        id="phone"
                        mask="(000) 000-0000"
                        [(ngModel)]="userBasicInfo.phone"
                        name="phone"
                        #phone="ngModel"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for="officePhone">Office Phone</label>
                      <input
                        type="text"
                        class="form-control"
                        id="officePhone"
                        mask="(000) 000-0000"
                        [(ngModel)]="userBasicInfo.officePhone"
                        name="officePhone"
                        #officePhone="ngModel"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for="phone_extension">Office Phone Extension</label>
                      <input
                        type="text"
                        class="form-control"
                        id="phone_extension"
                        [(ngModel)]="userBasicInfo.phone_extension"
                        name="phone_extension"
                        #phone_extension="ngModel"
                      />
                      <div [hidden]="phone_extension.valid || phone_extension.pristine" class="alert alert-danger">
                        Invalid phone extension (should be between 2 to 5 characters)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div *ngIf="showCompanyField" class="form-group">
                      <label for="company"> Company/Organization</label>
                      <input
                        type="text"
                        class="form-control"
                        id="company"
                        [(ngModel)]="userBasicInfo.company"
                        name="company"
                        #company="ngModel"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="isAdmin" class="form-group">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="allowAdminUIPermission"
                          #allowAdminUIPermission="ngModel"
                          [(ngModel)]="userBasicInfo.allowAdminUIPermission"
                          name="allowAdminUIPermission"
                        />
                        <label class="form-check-label"> Add Admin UI Modification Permission </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div *ngIf="isAdmin" class="form-group">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="allowBillingResponsibility"
                          #allowBillingResponsibility="ngModel"
                          [(ngModel)]="userBasicInfo.allowBillingResponsibility"
                          name="allowBillingResponsibility"
                        />
                        <label class="form-check-label"> Add Billing Responsibility</label>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary" [disabled]="!userForm.form.valid || infoLoading">
                  {{ infoLoading ? "Saving.." : "Save" }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Edit Role (controls what the selected user can do)</h4>
          <div class="form-group">
            <label for="role">Role</label>
            <select
              class="form-control"
              id="role"
              #role="ngModel"
              required
              (change)="onRoleChange($event.target.value)"
              [(ngModel)]="selectedRole"
              name="role"
            >
              <option *ngFor="let role of roles" [value]="role">{{ role }}</option>
            </select>
            <div [hidden]="role.valid || role.pristine" class="alert alert-danger">Role is required</div>
          </div>
          <br />
          <button class="btn btn-primary" (click)="saveRole()">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedRole === 'boater'" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="float-right mr-1">
            <button class="btn btn-primary cursor-pointer" type="button" (click)="addAnotherBoat()">
              Add Another Boat <i class="fa fa-plus-circle" aria-hidden="true"></i>
            </button>
          </div>
          <h4 class="card-title">Edit Boater Information</h4>
          <div
            *ngFor="let boatItem of allBoatsOfUser"
            style="margin-bottom: '20px'; border: 'solid grey 1px'; border-radius: '10px'; padding: '
			
			20px'"
          >
            <div class="form-group">
              <button class="btn btn-danger float-right cursor-pointer" type="button" (click)="removeBoat(boatItem)">
                Remove Boat
              </button>
            </div>
            <h5>{{ boatItem?.schoolName }}</h5>
            <div class="form-group">
              <div class="form-check">
                <label for="boatName">Boat Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="boatName"
                  [(ngModel)]="boatItem.boatName"
                  name="boatName"
                  required
                  #boatName="ngModel"
                />
                <div [hidden]="boatName.valid || boatName.pristine" class="alert alert-danger">
                  Boat Name is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="boatLocationType"
                >Location (Select one format and enter location of your boat){{ boatItem.boatLocationType }}
                {{ boatItem.boatLocationType === "dockSlip" }}
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  [(ngModel)]="boatItem.boatLocationType"
                  value="dockSlip"
                  required
                  name="boatLocationType"
                  type="radio"
                  name="boatLocationType"
                  id="dockSlip"
                  [checked]="boatItem.boatLocationType === 'dockSlip'"
                />
                <label class="form-check-label" for="dockSlip"> Dock Slip </label>
              </div>
              <div *ngIf="boatItem.boatLocationType === 'dockSlip'" class="mt-4">
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="dockNumber">Dock</label>
                    <input
                      type="text"
                      class="form-control"
                      id="dockNumber"
                      [(ngModel)]="boatItem?.boatLocation.dockNumber"
                      name="dockNumber"
                      placeholder="Sample: Dock C"
                      required
                      #dockNumber="ngModel"
                    />
                    <div [hidden]="dockNumber.valid || dockNumber.pristine" class="alert alert-danger">
                      Dock is required
                    </div>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="slipNumber">Slip</label>
                    <input
                      type="text"
                      class="form-control"
                      id="slipNumber"
                      [(ngModel)]="boatItem?.boatLocation.slipNumber"
                      name="slipNumber"
                      placeholder="Sample: Slip 27"
                      required
                      #slipNumber="ngModel"
                    />
                    <div [hidden]="slipNumber.valid || slipNumber.pristine" class="alert alert-danger">
                      Slip is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  [(ngModel)]="boatItem.boatLocationType"
                  value="mooring"
                  name="boatLocationType"
                  type="radio"
                  name="boatLocationType"
                  id="mooring"
                  [checked]="boatItem.boatLocationType === 'mooring'"
                />
                <label class="form-check-label" for="mooring"> Mooring </label>
              </div>
              <div *ngIf="boatItem.boatLocationType === 'mooring'" class="mt-4">
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="mooringInput"
                      [(ngModel)]="boatItem?.boatLocation.mooring"
                      placeholder="Sample: Mooring #G23"
                      name="mooring"
                      required
                      #mooring="ngModel"
                    />
                    <div [hidden]="mooring.valid || mooring.pristine" class="alert alert-danger">
                      Mooring is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  value="address"
                  [(ngModel)]="boatItem.boatLocationType"
                  name="boatLocationType"
                  type="radio"
                  name="boatLocationType"
                  id="address"
                  [checked]="boatItem.boatLocationType === 'address'"
                />
                <label class="form-check-label" for="address"> Address </label>
              </div>
              <div *ngIf="boatItem.boatLocationType === 'address'" class="mt-4">
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="street">Street</label>
                    <input
                      type="text"
                      class="form-control"
                      id="street"
                      [(ngModel)]="boatItem?.boatLocation.street"
                      name="street"
                      placeholder="eg. 22222 Street Ave. "
                      required
                      #street="ngModel"
                    />
                    <div [hidden]="street.valid || street.pristine" class="alert alert-danger">Street is required</div>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="city">City</label>
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      [(ngModel)]="boatItem?.boatLocation.city"
                      name="city"
                      placeholder="City "
                      required
                      #city="ngModel"
                    />
                    <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="state">State</label>
                    <select
                      class="form-select form-control"
                      id="state"
                      [(ngModel)]="boatItem?.boatLocation.state"
                      name="state"
                      placeholder="State "
                      required
                      #state="ngModel"
                    >
                      <option value="{{ state?.state }}" *ngFor="let state of usStateList">{{ state?.state }}</option>
                    </select>
                    <div [hidden]="state.valid || state.pristine" class="alert alert-danger">State is required</div>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <div class="form-group">
                    <label for="zip">Zip</label>
                    <input
                      type="text"
                      class="form-control"
                      id="zip"
                      [(ngModel)]="boatItem?.boatLocation.zip"
                      name="zip"
                      placeholder="Zip "
                      required
                      #zip="ngModel"
                    />
                    <div [hidden]="zip.valid || zip.pristine" class="alert alert-danger">Zip is required</div>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  value="others"
                  [(ngModel)]="boatItem.boatLocationType"
                  name="boatLocationType"
                  type="radio"
                  id="others"
                  [checked]="boatItem.boatLocationType === 'others'"
                />
                <label class="form-check-label" for="others"> Others -- Description </label>
              </div>
              <div *ngIf="boatItem.boatLocationType === 'others'" class="mt-4">
                <div class="form-check">
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      id="description"
                      [(ngModel)]="boatItem?.boatLocation.description"
                      name="description"
                      placeholder="Provide description if none of the above formats apply"
                      required
                      #description="ngModel"
                      rows="5"
                    ></textarea>
                    <div [hidden]="description.valid || description.pristine" class="alert alert-danger">
                      Description is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary" (click)="saveBoaterInformation(boatItem)">Save</button>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="selectedRole === 'unit_owner'">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Edit Resident Location</h4>
          <div class="form-group">
            <label for="unitType">Unit Type<span class="required-field"></span></label>
            <select
              class="form-select form-control"
              id="unitType"
              [(ngModel)]="userLocationOptions.unitType"
              name="unitType"
              placeholder="unitType"
              required
              #unitType="ngModel"
            >
              <option *ngFor="let option of HoaUnitTypes" [value]="option.value">{{ option.label }}</option>
              <option value="Townhouse">Townhouse</option>
              <option value="Single-Family Detached">Single-Family Detached</option>
            </select>
            <div [hidden]="unitType.valid || unitType.pristine" class="alert alert-danger">
              Please select Unit Type!
            </div>
          </div>
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="address">Address</label>
              <input
                type="text"
                class="form-control"
                id="address"
                [(ngModel)]="userLocationOptions.address"
                name="address"
                placeholder="eg. 22222 Street Ave. "
                required
                #address="ngModel"
              />
              <div [hidden]="address.valid || address.pristine" class="alert alert-danger">Address is required</div>
            </div>
          </div>
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="unit">Unit</label>
              <input
                type="text"
                class="form-control"
                id="unit"
                [(ngModel)]="userLocationOptions.unit"
                name="unit"
                placeholder="Unit"
                required
                #unit="ngModel"
              />
              <div [hidden]="unit.valid || unit.pristine" class="alert alert-danger">Unit is required</div>
            </div>
          </div>
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="city">City</label>
              <input
                type="text"
                class="form-control"
                id="city"
                [(ngModel)]="userLocationOptions.city"
                name="city"
                placeholder="City "
                required
                #city="ngModel"
              />
              <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
            </div>
          </div>
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="state">State</label>
              <select
                class="form-select form-control"
                id="state"
                [(ngModel)]="userLocationOptions.state"
                name="state"
                placeholder="State "
                required
                #state="ngModel"
              >
                <option value="{{ state?.state }}" *ngFor="let state of usStateList">{{ state?.state }}</option>
              </select>
              <div [hidden]="state.valid || state.pristine" class="alert alert-danger">State is required</div>
            </div>
          </div>

          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="zip">Zip</label>
              <input
                type="text"
                class="form-control"
                id="zip"
                [(ngModel)]="userLocationOptions.zip"
                name="zip"
                placeholder="Zip "
                required
                #zip="ngModel"
              />
              <div [hidden]="zip.valid || zip.pristine" class="alert alert-danger">Zip is required</div>
            </div>
          </div>
          <button class="btn btn-primary" (click)="saveResidentLocation()">Save</button>
          <hr />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isSchoolVillage && isSelectedUserFamilyType" class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">
            Edit Students (whom
            <span class="font-weight-bold">{{ selectedUserData.firstName }} {{ selectedUserData.lastName }}</span>
            belong to as parent/guardian)
          </h4>
          <div class="row">
            <div class="col-12 card" *ngFor="let student of selectedUserData.students; let inx = index">
              <div *ngIf="student">
                <h6 class="font-weight-bold">
                  {{ inx + 1 }}. <i class="zmdi zmdi-account"></i>&nbsp;&nbsp; {{ student.firstName | titlecase }}
                  {{ student.lastName | titlecase }}
                  <a
                    class="cursor-pointer"
                    style="margin-left: 24px"
                    (click)="openRemoveStudentModal(student, removeStudentModal)"
                  >
                    <i class="zmdi zmdi-delete text-danger" style="font-size: 16px"></i>
                  </a>
                </h6>
                <p style="margin-bottom: 2px"><i class="zmdi zmdi-email"></i>&nbsp;&nbsp;&nbsp;{{ student.email }}</p>
                <p *ngIf="student.phone"><i class="zmdi zmdi-phone"></i>&nbsp;&nbsp;&nbsp;{{ student.phone }}</p>
              </div>
            </div>
          </div>
          <hr />
          <h6>Add New Student</h6>
          <div class="ng-autocomplete">
            <ng-autocomplete
              [data]="allSchoolStudentUsers"
              searchKeyword="name"
              (selected)="studentSelectEvent($event)"
              placeholder="Search student by name..."
              (inputChanged)="onChangeSearch($event)"
              (inputFocused)="onFocused($event)"
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate"
            >
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
          <br />
          <button #addStudentButton class="btn btn-primary" (click)="addStudent(addStudentButton)">Add</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="defaultAlertSelectionOption === 'selectable'" class="row row-eq-height">
    <div class="col-md-6" *ngIf="userAlerts != null">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Edit Alerts Received</h4>
          <ul *ngIf="defaultAlertSelectionOption === 'all'">
            <li *ngFor="let alert of AllAlerts">
              <input
                type="checkbox"
                name="{{ alert.key }}"
                value="{{ userAlerts[alert.key] }}"
                readonly
                disabled
                [(ngModel)]="userAlerts[alert.key]"
              />
              {{ alert.value }}
            </li>
          </ul>
          <ul *ngIf="defaultAlertSelectionOption === 'selectable'">
            <li *ngFor="let alert of AllAlerts">
              <input
                type="checkbox"
                name="{{ alert.key }}"
                value="{{ userAlerts[alert.key] }}"
                [(ngModel)]="userAlerts[alert.key]"
              />
              {{ alert.value }}
            </li>
          </ul>
          <ul *ngIf="defaultAlertSelectionOption === 'none'">
            <li>
              User with
              {{ role.value }}
              role will receive no alerts!
            </li>
          </ul>
          <br />
          <button *ngIf="defaultAlertSelectionOption === 'selectable'" class="btn btn-primary" (click)="saveAlerts()">
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="userGroups != null">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Edit Groups (controls what notifications the selected user receives)</h4>
          <ul>
            <li *ngFor="let group of allGroups">
              <input
                type="checkbox"
                name="{{ group }}"
                value="{{ userGroups[group] }}"
                [(ngModel)]="userGroups[group]"
                disabled
                readonly
              />
              {{ group }}
            </li>
          </ul>
          <br />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Devices</h4>

          <div *ngFor="let device of devices">
            <h6>
              {{ selectedUserData["devices"][device] }}
              <i
                class="zmdi zmdi-delete"
                style="color: red"
                (click)="deleteDevice(device)"
                *ngIf="!isDeletingDevice"
              ></i>
            </h6>
            <small>{{ device }}</small
            ><br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      isHoaVillage &&
      (isOwner ||
        selectedRole === AllUserRoles.admin ||
        selectedRole === AllUserRoles.unit_owner ||
        selectedRole === AllUserRoles.district)
    "
  >
    <app-hoa-members
      [homeOwnerUserData]="selectedUserData"
      [selectedSchoolId]="selectedSchoolId"
      [schoolData]="schoolData"
      [selectedRole]="AllUserRoles.family"
      [selectedUsers]="familyMemberUsers"
      [homeOwnerUnitAddress]="userLocationOptions"
    ></app-hoa-members>
    <app-hoa-members
      [homeOwnerUserData]="selectedUserData"
      [selectedSchoolId]="selectedSchoolId"
      [schoolData]="schoolData"
      [selectedRole]="AllUserRoles.tenant"
      [selectedUsers]="tenantUsers"
      [homeOwnerUnitAddress]="userLocationOptions"
    ></app-hoa-members>
    <app-hoa-members
      [homeOwnerUserData]="selectedUserData"
      [selectedSchoolId]="selectedSchoolId"
      [schoolData]="schoolData"
      [selectedRole]="AllUserRoles.tenant_family"
      [selectedUsers]="tenantFamilyUsers"
      [homeOwnerUnitAddress]="userLocationOptions"
    ></app-hoa-members>
  </div>
</section>

<ng-template #removeStudentModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Student Removal</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="closeModal()">
      <span aria-hidden=" true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <strong
        >Are you sure you want to remove
        <span class="text-primary">
          {{ selectedStudentToRemove.firstName }} {{ selectedStudentToRemove.lastName }}</span
        >
        from guardianship of
        <span class="text-primary">{{ selectedUserData.firstName }} {{ selectedUserData.lastName }}</span> ?</strong
      >
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="removeStudent()">Yes</button>
  </div>
</ng-template>

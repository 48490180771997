import { Component, Input, OnChanges, TemplateRef } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "app/core/services/user.service";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
import { AllUserRoles } from "app/shared/generic_variables";
import { ToastrService } from "ngx-toastr";
import firebase from "firebase/compat/app";

@Component({
  selector: "app-hoa-members",
  templateUrl: "./hoa-members.component.html",
})
export class HoaMembersComponent implements OnChanges {
  constructor(
    public _modalService: NgbModal,
    public db: AngularFirestore,
    public toastr: ToastrService,
    private userService: UserService
  ) {}
  @Input() homeOwnerUserData: DocumentData;
  @Input() selectedSchoolId: string;
  @Input() schoolData: DocumentData;
  @Input() selectedUsers: DocumentData[] = [];
  @Input() selectedRole: AllUserRoles;
  @Input() homeOwnerUnitAddress = {};
  loading = false;
  roleLabel = "";

  model = {
    firstName: "",
    lastName: "",
    allowBillingResponsibility: false,
    allowAdminUIPermission: false,
    email: "",
    phone: "",
    officePhone: "",
    phone_extension: "",
    role: "",
    age: "",
    excludeInContactDirectory: false,
    ageIsUnder18: false,
  };

  ngOnChanges() {
    if (this.selectedRole === AllUserRoles.family) {
      this.roleLabel = "Family Member";
    } else if (this.selectedRole === AllUserRoles.tenant) {
      this.roleLabel = "Tenant";
    } else if (this.selectedRole === AllUserRoles.tenant_family) {
      this.roleLabel = "Tenant Family ";
    }
  }

  openAnotherUserModal(addUserTemplate: TemplateRef<any>) {
    this._modalService.open(addUserTemplate, { size: "lg", backdrop: "static", keyboard: false });
  }

  onSubmit() {
    if (!this.selectedSchoolId || !this.selectedRole) {
      this.toastr.error(`Oops, something went wrong. Please try again or contact customer service!`);
      return;
    }
    try {
      this.loading = true;
      let postData = this.model;
      const role = this.selectedRole;
      postData["groups"] = {
        role: true,
      };
      postData["alerts"] = {};
      postData["role"] = role;
      postData["schoolId"] = this.selectedSchoolId;
      postData["schoolName"] = this.schoolData.name ? this.schoolData.name : "";
      postData["homeOwnerUserId"] = this.homeOwnerUserData.id;
      if (postData.ageIsUnder18) {
        postData["excludeInContactDirectory"] = true;
      }
      if (this.homeOwnerUserData.location) {
        postData["location"] = this.homeOwnerUserData.location;
      }
      // TODO: How do we associated tenant_family to tenant? Let user choose tenant in add tenant_family interface??
      if (this.selectedRole === AllUserRoles.tenant_family) {
        // postData["tenantUserId"] = this.homeOwnerUserData.id;
      }
      this.userService.postUser(postData).subscribe(
        (response: any) => {
          const message = response.message
            ? response.message
            : `Great, user ${postData.email} has been successfully added to ${this.schoolData?.name} as ${postData.role} role!`;
          this.toastr.success(message);
          this.loading = false;
          this.closeModal();
        },
        (error) => {
          this.loading = false;
          this.toastr.error(`Oops, Something went wrong while adding the user. Please try again!`);
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  removeUser(userData: DocumentData) {
    const modalRef = this._modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = `Delete the ${this.selectedRole}`;
    modalRef.componentInstance.text = `Are you sure you want to delete this ${this.selectedRole}?`;
    modalRef.componentInstance.highlightedText = `${userData.firstName} ${userData.lastName} (${userData.email})`;
    modalRef.result
      .then((confirmed) => {
        if (confirmed) {
          try {
            this.db.doc(`users/${userData.id}`).update({
              deleted: true,
              homeOwnerUserId: firebase.firestore.FieldValue.delete(),
              [`associatedSchools.${this.selectedSchoolId}`]: firebase.firestore.FieldValue.delete(),
            });
            try {
              const removeIndex = this.selectedUsers.findIndex((item) => item.id === userData.id);
              this.selectedUsers.splice(removeIndex, 1);
            } catch {}
            this.toastr.success(`User has been successfully deleted.`, "Success");
          } catch (error) {
            console.error("error", error);
            this.toastr.error(`Sorry, we had trouble deleting the user .Please try again.`, "Error");
          }
        }
      })
      .catch();
  }

  saveUser(userData: DocumentData) {
    try {
      if (userData.ageIsUnder18) {
        userData["excludeInContactDirectory"] = true;
      }
      if (this.homeOwnerUserData.location) {
        userData["location"] = this.homeOwnerUserData.location;
      }
      this.db.doc(`users/${userData.id}`).update(userData);
      this.toastr.success(`User  has been successfully updated.`, "Success");
    } catch (error) {
      console.error("error", error);
      this.toastr.error(`Sorry, we had trouble updating the user .Please try again.`, "Error");
    }
  }

  closeModal() {
    this._modalService.dismissAll();
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DocumentData } from "@angular/fire/compat/firestore";
import { SchoolBulkUploadRequiredOrderOfSheetTitles } from "app/utils/school.util";
import { environment, strings } from "environments/environment";
import { GenericCollectionDataService } from "./generic-collection-data.service";
import writeXlsxFile from "write-excel-file";

@Injectable({
  providedIn: "root",
})
export class SchoolService {
  strings = strings;
  requiredOrderOfSheetTitles = SchoolBulkUploadRequiredOrderOfSheetTitles;
  constructor(private genericCollectionDataService: GenericCollectionDataService, private http: HttpClient) {}

  getAllSchools$() {
    const parameters = { colName: "schools", queryList: [] };
    return this.genericCollectionDataService.queryCollectionSnapshot(parameters);
  }

  postSchool(data: DocumentData) {
    return this.http.post(`${environment.apiEndPoint}/api/schools`, data);
  }

  async postBulkSchool(schoolList: DocumentData[]) {
    return await this.http.post(`${environment.apiEndPoint}/schoolBulkUpload`, { data: schoolList }).toPromise();
  }

  async downloadSchoolBulkUploadSpreadSheet() {
    let headers = [];
    this.requiredOrderOfSheetTitles.forEach((title) => {
      headers = [
        ...headers,
        {
          value: title,
          fontWeight: "bold",
        },
      ];
    });
    let fileData = [headers];
    await writeXlsxFile(fileData, {
      fileName: `${strings.villageType}_bulk_upload_template.xlsx`,
    });
  }
}

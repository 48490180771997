<section class="content">
  <div class="row">
    <div class="col-6">
      <app-school-detail-page-header [schoolData]="schoolData"></app-school-detail-page-header>
    </div>
    <div class="col-6">
      <div class="float-right">
        <a href="assets/{{ sampleTemplateFileName }}" class="sample-download-button">
          <button type="button" class="btn btn-outline-primary sample-download-button">
            Download Sample Excel Template<i class="ml-1 fa fa-download"></i>
          </button>
        </a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div>
        <h4>Bulk Upload Users</h4>
        <form>
          <h5>Choose Valid Excel (.xlsx) file to upload users to {{ schoolData?.name }}</h5>
          <input type="file" id="excel-upload" accept=".xlsx" multiple="false" (change)="inputExcelOnClick($event)" />
        </form>
        <div *ngIf="inValidOrderOfSheetTitles" class="mt-3">
          <div class="row">
            <div class="col-12">
              <p>
                <span class="text-danger"
                  >The either title name or order of the fields on uploaded sheet is wrong. The name of titles of fields
                  should as follows in order:
                </span>
              </p>
            </div>
            <div class="col-6">
              <p>
                <b>Expected Order:</b>
                <br />
                <span
                  *ngFor="
                    let title of requiredOrderOfSheetTitles.slice(1, requiredOrderOfSheetTitles.length);
                    let inx = index
                  "
                >
                  {{ title }},
                  <br />
                </span>
              </p>
            </div>
            <div class="col-6">
              <p>
                <b>But Got Current Order as:</b>
                <br />
                <span
                  *ngFor="
                    let title of currentOrderOfSheetTitles.slice(1, currentOrderOfSheetTitles.length);
                    let inx = index
                  "
                >
                  {{ title }},
                  <br />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="errorOccurred" class="mt-3">
          <p>
            <span class="text-danger"
              >Something went wrong while uploading the users. We could not upload all the users. Please refresh the
              page and try again!
            </span>
          </p>
        </div>

        <div *ngIf="invalidRolesInSheet && invalidRolesInSheet.length > 0" class="mt-3">
          <div class="row">
            <div class="col-12">
              <p>
                <span class="text-danger"
                  >The user data sheet contains some of the invalid roles. Please correct them, refresh the page and
                  re-upload them.
                </span>
              </p>
            </div>
            <div class="col-6">
              <p>
                <b>Expected Possible Roles:</b>
                <br />
                <span *ngFor="let role of Roles; let inx = index">
                  {{ role }},
                  <br />
                </span>
              </p>
            </div>
            <div class="col-6">
              <p>
                <b>But Got following invalid roles:</b>
                <br />
                <span *ngFor="let role of invalidRolesInSheet; let inx = index">
                  {{ role }},
                  <br />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="excelDataEncodeToJson">
          <div class="col-12 mb-4 mt-4">
            <button
              [disabled]="uploading || inValidOrderOfSheetTitles"
              class="btn btn-success"
              (click)="confirmBulkUpload()"
            >
              <span>{{
                uploading ? "Uploading, Please wait...It will take few minutes...." : "Confirm User Bulk Upload"
              }}</span>
            </button>
          </div>
          <div class="col-12">
            <h5>Preview Of users</h5>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12">
            <div class="display-sheet-js" [hidden]="!(!isEmptyDrop && isExcelDrop)">
              <div class="example-container">
                <table cdk-table [dataSource]="excelDataEncodeToJson">
                  <tr cdk-header-row *cdkHeaderRowDef="excelTransformNum; sticky: true"></tr>
                  <tr cdk-row *cdkRowDef="let row; columns: excelTransformNum"></tr>
                  <ng-container cdkColumnDef="order" sticky>
                    <th cdk-header-cell *cdkHeaderCellDef>{{ sheetMaxRow }}</th>
                    <td cdk-cell *cdkCellDef="let element; let idx = index">{{ idx }}</td>
                  </ng-container>
                  <ng-container
                    *ngFor="let disCol of excelTransformNum | slice : 1; let colIndex = index"
                    cdkColumnDef="{{ disCol }}"
                  >
                    <th cdk-header-cell *cdkHeaderCellDef>{{ disCol }}</th>
                    <td cdk-cell *cdkCellDef="let element">{{ element[disCol] }}</td>
                  </ng-container>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="widget-lists card-columns"></div>
</section>

import { Component, OnInit } from "@angular/core";
import { AngularFirestore, DocumentData, DocumentReference } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute, Router } from "@angular/router";
import { strings } from "environments/environment";
import { AssociationTypes, VillageGroup, VillageType } from "common/typings";
import { LengthUnit } from "common/typings";
import { CollectionNames, HoaUnitTypes, HoaUnitValues, RoutePaths } from "app/shared/generic_variables";
import { SchoolService } from "app/core/services/school.service";
import { map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { USTimezones } from "app/utils/school.util";

@Component({
  selector: "app-new-school",
  templateUrl: "./new-school.component.html",
  styleUrls: ["./new-school.component.scss"],
})
export class NewSchoolComponent implements OnInit {
  usStateList = [];
  selectedStateCounties = [];
  userName = "";
  isOwner = false;
  strings: { villageType?: VillageType; villageGroup?: VillageGroup } = strings;
  allRegions: DocumentData[] = [];
  allDistricts: DocumentData[] = [];
  filteredDistricts: DocumentData[] = [];
  loading = false;
  error = "";
  model = {
    schoolId: "",
    schoolName: "",
    associationTypes: {
      [AssociationTypes.hoa]: false,
      [AssociationTypes.condo]: false,
      [AssociationTypes.coop]: false,
    },
    business_phone: "",
    business_phone_extension: "",
    primary_phone: "",
    primary_phone_extension: "",
    contact_email: "",
    contact_firstName: "",
    contact_direct_phone: "",
    contact_direct_phone_extension: "",
    contact_lastName: "",
    address: "",
    unit: "",
    city: "",
    county: "",
    state: "",
    zip: "",
    region: "",
    district: null,
    timezone: "America/Los_Angeles",
    slipsCount: 0,
    residentialUnitsCount: 0,
    slipsSizeUnit: LengthUnit.FEET,
    slipsSizeRange: {
      from: 0,
      to: 0,
    },
    reportEmails: "",
    managed_by_outside_company: false,
    managementOrganization: {},
    hoaResidentUnitCounts: {
      singleFamilyDetached: 0,
      condominium_residential: 0,
      condominium_commercial: 0,
      townhouse: 0,
      total: 0,
    },
    website: "",
    map_marina: "",
    map_harbor: "",
    location: "",
  };

  managementOrganization = {
    name: "",
    phone: "",
    phone_extension: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    contact_firstName: "",
    contact_lastName: "",
    contact_email: "",
    contact_phone: "",
    contact_phone_extension: "",
  };

  hoaResidentUnitCounts = {
    singleFamilyDetached: 0,
    condominium_residential: 0,
    condominium_commercial: 0,
    townhouse: 0,
    total: 0,
  };

  timezones = USTimezones;
  submitted = false;
  selectedSchoolId: string;
  mode: "add" | "edit" = "add";

  constructor(
    public afAuth: AngularFireAuth,
    private schoolService: SchoolService,
    private router: Router,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private toastrService: ToastrService
  ) {
    this.getAllStates();
    this.getAllRegions();
    this.getAllDistricts();
    this.getSchoolInEditMode();
  }

  ngOnInit() {}

  getAllStates() {
    this.db
      .collection(CollectionNames.states)
      .valueChanges()
      .subscribe((states) => {
        if (states) this.usStateList = states;
        this.onStateSelection(false);
      });
  }

  onStateSelection(resetCounty = true) {
    if (this.model.state) {
      if (resetCounty) {
        this.model.county = "";
      }
      this.selectedStateCounties = this.usStateList.find((item) => item.state === this.model.state)?.counties;
    } else {
      this.selectedStateCounties = [];
    }
  }

  get getTotalResidentCount() {
    try {
      const totalResidentCounts =
        this.hoaResidentUnitCounts.singleFamilyDetached +
        this.hoaResidentUnitCounts.townhouse +
        this.hoaResidentUnitCounts.condominium_residential +
        this.hoaResidentUnitCounts.condominium_commercial;
      this.hoaResidentUnitCounts.total = totalResidentCounts;
      return this.hoaResidentUnitCounts.total;
    } catch {
      return 0;
    }
  }

  getSchoolInEditMode() {
    this.route.paramMap.subscribe((params) => {
      this.selectedSchoolId = params.get("id");
      if (this.selectedSchoolId) {
        const schoolRef = this.db.doc("schools/" + this.selectedSchoolId);
        schoolRef.valueChanges().subscribe((school: DocumentData) => {
          this.mode = "edit";
          this.model = { ...this.model, ...school, schoolName: school.name };
          if (school && school?.managementOrganization) {
            this.managementOrganization = { ...this.managementOrganization, ...school.managementOrganization };
          }
          if (school && school?.hoaResidentUnitCounts) {
            this.hoaResidentUnitCounts = { ...this.hoaResidentUnitCounts, ...school.hoaResidentUnitCounts };
          }
          if (school.region) {
            const regionRef: DocumentReference = school.region;
            this.model = { ...this.model, region: regionRef.id };
            this.onRegionFilterChange(regionRef.id);
          }
          if (school.district) {
            const districtRef: DocumentReference = school.district;
            this.model = { ...this.model, district: districtRef.id };
          }
          this.onStateSelection(false);
        });
      }
    });
  }

  getAllRegions() {
    this.db
      .collection(CollectionNames.regions, (ref) => ref.where("deleted", "==", false).orderBy("name", "asc"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((cb) => {
            const data: DocumentData = cb.payload.doc.data();
            const id = cb.payload.doc["id"];
            return { id, ...data };
          })
        )
      )
      .subscribe((regions) => {
        if (regions) this.allRegions = regions;
      });
  }

  getAllDistricts() {
    this.db
      .collection(CollectionNames.districts, (ref) => ref.where("deleted", "==", false).orderBy("name", "asc"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((cb) => {
            const data: DocumentData = cb.payload.doc.data();
            const id = cb.payload.doc["id"];
            return { id, ...data };
          })
        )
      )
      .subscribe((districts) => {
        if (districts) {
          this.allDistricts = districts;
          if (this.isSchoolVillage) {
            this.filteredDistricts = this.allDistricts;
          }
        }
      });
  }

  onRegionFilterChange(selectedRegionIdId: string) {
    this.filteredDistricts = this.allDistricts.filter((item) => item.region.id === selectedRegionIdId);
  }

  onSubmit() {
    this.loading = true;
    if (this.selectedSchoolId) {
      this.model = { ...this.model, schoolId: this.selectedSchoolId };
    }
    if (this.isHoaVillage && this.model.managed_by_outside_company === true) {
      this.model = { ...this.model, managementOrganization: this.managementOrganization };
    }
    if (this.isHoaVillage) {
      this.model = { ...this.model, hoaResidentUnitCounts: this.hoaResidentUnitCounts };
    }
    this.schoolService.postSchool(this.model).subscribe(
      (_) => {
        this.loading = false;
        this.toastrService.success(`Great, ${this.strings.villageType} data has been updated successfully!`);
        this.router.navigateByUrl(`${RoutePaths.schools}/${this.selectedSchoolId}`);
      },
      (error) => {
        console.log("Error", error);
        this.loading = false;
        this.error = error;
        this.toastrService.error(
          `Oops, something went wrong while updating ${this.strings.villageType} data. Please try again!`
        );
      }
    );
  }

  get isMarinaVillage() {
    return this.strings.villageType === VillageType.MARINA;
  }

  get isHoaVillage() {
    return this.strings.villageType === VillageType.HOA;
  }

  get isSchoolVillage() {
    return this.strings.villageType === VillageType.SCHOOL;
  }

  get getAssociationTypes() {
    return Object.values(AssociationTypes);
  }

  public get HoaUnitTypes() {
    return HoaUnitTypes;
  }

  public get AssociationTypes() {
    return AssociationTypes;
  }

  public get LengthUnit() {
    return LengthUnit;
  }
}

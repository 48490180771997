import { Component, Input, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Location } from "@angular/common";
import { strings } from "environments/environment";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.css"],
})
export class PageHeaderComponent implements OnInit {
  @Input() showBackArrow = true;
  @Input() pageTitle = "";
  @Input() schoolData: DocumentData;
  userName: string;
  isOwner: boolean;
  strings = strings;
  userData: DocumentData;
  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore, private _location: Location) {
    this.afAuth.authState.subscribe((currentUser) => {
      const uid = currentUser.uid;
      this.afAuth.idTokenResult.subscribe((idTokenResult) => {
        this.isOwner = idTokenResult["claims"]["owner"];
      });
      this.db
        .doc("users/" + uid)
        .valueChanges()
        .subscribe((userValue) => {
          this.userData = userValue;
          this.userName = userValue["firstName"] + " " + userValue["lastName"];
        });
    });
  }

  ngOnInit() {}

  getBackLink() {
    this._location.back();
  }
}

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="float-right mr-1">
          <button class="btn btn-primary cursor-pointer" type="button" (click)="openAnotherUserModal(addUserTemplate)">
            Add {{ roleLabel }} <i class="fa fa-plus-circle" aria-hidden="true"></i>
          </button>
        </div>
        <h4 class="card-title">{{ roleLabel }}</h4>
        <div
          *ngFor="let user of selectedUsers"
          style="margin-bottom: '20px'; border: 'solid grey 1px'; border-radius: '10px'; padding: '
      
      20px'"
          e
          class="row"
        >
          <div class="col-12 form-group">
            <button class="btn btn-danger float-right cursor-pointer" type="button" (click)="removeUser(user)">
              Remove {{ roleLabel }}
            </button>
          </div>
          <div class="col-6 form-group">
            <label for="name">First Name<span class="required-field"></span></label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              required
              [(ngModel)]="user.firstName"
              name="firstName"
              #firstName="ngModel"
            />
            <div [hidden]="firstName.valid || firstName.pristine" class="alert alert-danger">
              First Name is required
            </div>
          </div>

          <div class="col-6 form-group">
            <label for="name">Last Name<span class="required-field"></span></label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              required
              [(ngModel)]="user.lastName"
              name="lastName"
              #lastName="ngModel"
            />
            <div [hidden]="lastName.valid || lastName.pristine" class="alert alert-danger">Last Name is required</div>
          </div>

          <div class="col-6 form-group">
            <label for="email">Email<span class="required-field"></span></label>
            <input
              type="email"
              class="form-control"
              id="email"
              required
              email
              [(ngModel)]="user.email"
              name="email"
              #email="ngModel"
            />
            <div [hidden]="email.valid || email.pristine" class="alert alert-danger">Valid Email is required</div>
          </div>
          <div class="col-6 form-group">
            <label for="phone">Mobile Phone<span class="required-field"></span></label>
            <input
              type="text"
              class="form-control"
              id="phone"
              required
              [(ngModel)]="user.phone"
              name="phone"
              mask="(000) 000-0000"
              #phone="ngModel"
            />
            <div [hidden]="phone.valid || phone.pristine" class="alert alert-danger">Invalid phone number</div>
          </div>
          <div class="col-12 form-group">
            <ng-container
              *ngTemplateOutlet="homeOwnerUnitAddressTemplate; context: { homeOwnerUnitAddress }"
            ></ng-container>
          </div>

          <div class="col-6 form-group">
            <input
              class="form-check-input"
              type="checkbox"
              id="ageIsUnder18"
              [(ngModel)]="user.ageIsUnder18"
              name="ageIsUnder18"
              [checked]="user.ageIsUnder18 === true"
            />
            <label class="form-check-label">
              Check if Age is under 18 Years (Users who are under 18 are not listed in the HOA ContactDirectory)</label
            >
          </div>
          <div class="col-6 form-group">
            <input
              class="form-check-input"
              type="checkbox"
              id="excludeInContactDirectory"
              name="excludeInContactDirectory"
              [(ngModel)]="user.excludeInContactDirectory"
              [disabled]="user.ageIsUnder18"
              [checked]="user.excludeInContactDirectory || user.ageIsUnder18"
            />
            <label class="form-check-label">Do Not List in HOA Contact Directory</label>
          </div>
          <button class="btn btn-primary" (click)="saveUser(user)">Save</button>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addUserTemplate>
  <form (ngSubmit)="onSubmit()" #userForm="ngForm">
    <div class="modal-body" style="min-width: 500px">
      <div class="form-group">
        <label for="name">First Name<span class="required-field"></span></label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          required
          [(ngModel)]="model.firstName"
          name="firstName"
          #firstName="ngModel"
        />
        <div [hidden]="firstName.valid || firstName.pristine" class="alert alert-danger">First Name is required</div>
      </div>

      <div class="form-group">
        <label for="name">Last Name<span class="required-field"></span></label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          required
          [(ngModel)]="model.lastName"
          name="lastName"
          #lastName="ngModel"
        />
        <div [hidden]="lastName.valid || lastName.pristine" class="alert alert-danger">Last Name is required</div>
      </div>

      <div class="form-group">
        <label for="email">Email<span class="required-field"></span></label>
        <input
          type="email"
          class="form-control"
          id="email"
          required
          email
          [(ngModel)]="model.email"
          name="email"
          #email="ngModel"
        />
        <div [hidden]="email.valid || email.pristine" class="alert alert-danger">Valid Email is required</div>
      </div>
      <div class="form-group">
        <label for="phone">Mobile Phone<span class="required-field"></span></label>
        <input
          type="text"
          class="form-control"
          id="phone"
          required
          [(ngModel)]="model.phone"
          name="phone"
          mask="(000) 000-0000"
          #phone="ngModel"
        />
        <div [hidden]="phone.valid || phone.pristine" class="alert alert-danger">Invalid phone number</div>
      </div>

      <div class="col-12 form-group">
        <ng-container
          *ngTemplateOutlet="homeOwnerUnitAddressTemplate; context: { homeOwnerUnitAddress }"
        ></ng-container>
      </div>

      <div class="form-group">
        <input
          class="form-check-input"
          type="checkbox"
          id="ageIsUnder18"
          [(ngModel)]="model.ageIsUnder18"
          name="ageIsUnder18"
          [checked]="model.ageIsUnder18 === true"
        />
        <label class="form-check-label">
          Check if Age is under 18 Years (Users who are under 18 are not listed in the HOA ContactDirectory)</label
        >
      </div>
      <div class="form-group">
        <input
          class="form-check-input"
          type="checkbox"
          id="excludeInContactDirectory"
          name="excludeInContactDirectory"
          [disabled]="model.ageIsUnder18"
          [checked]="model.excludeInContactDirectory || model.ageIsUnder18"
        />
        <label class="form-check-label">Do Not List in HOA Contact Directory</label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" [disabled]="!userForm.form.valid || loading" class="btn btn-success">
        {{ loading ? "Creating...." : "Create" }}
      </button>
      <button type="button" class="ml-2 btn btn-secondary" (click)="userForm.reset(); closeModal()" [hidden]="loading">
        Cancel
      </button>
    </div>
  </form>
</ng-template>

<ng-template #homeOwnerUnitAddressTemplate let-homeOwnerUnitAddress="homeOwnerUnitAddress">
  <div class="form-check form-check-inline">
    <div class="form-group">
      <label for="unitType">Unit Type</label>
      <input type="text" class="form-control" id="unitType" [value]="homeOwnerUnitAddress?.unitType" readonly />
    </div>
    <div class="form-group">
      <label for="address">Address</label>
      <input type="text" class="form-control" id="address" [value]="homeOwnerUnitAddress?.address" readonly />
    </div>
  </div>
  <div class="form-check form-check-inline">
    <div class="form-group">
      <label for="unit">Unit</label>
      <input type="text" class="form-control" id="unit" [value]="homeOwnerUnitAddress?.unit" readonly />
    </div>
  </div>
  <div class="form-check form-check-inline">
    <div class="form-group">
      <label for="city">City</label>
      <input type="text" class="form-control" id="city" [value]="homeOwnerUnitAddress?.city" readonly />
    </div>
  </div>
  <div class="form-check form-check-inline">
    <div class="form-group">
      <label for="state">State</label>
      <input type="text" class="form-control" id="state" [value]="homeOwnerUnitAddress?.state" readonly />
    </div>
  </div>

  <div class="form-check form-check-inline">
    <div class="form-group">
      <label for="zip">Zip</label>
      <input type="text" class="form-control" id="zip" [value]="homeOwnerUnitAddress?.zip" readonly />
    </div>
  </div>
</ng-template>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { RouterModule } from "@angular/router";
import { SchoolDetailPageHeaderComponent } from "./components/school-detail-page-header/school-detail-page-header.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { LoadingModalComponent } from "./components/loading-modal/loading-modal.component";
import { SchoolFilterComponent } from "./components/school-filter/school-filter.component";
import { MaterialModule } from "app/core/material.module";

@NgModule({
  declarations: [
    PageHeaderComponent,
    SchoolDetailPageHeaderComponent,
    ConfirmationModalComponent,
    SpinnerComponent,
    LoadingModalComponent,
    SchoolFilterComponent,
  ],
  imports: [CommonModule, RouterModule, MaterialModule],
  exports: [
    PageHeaderComponent,
    SchoolDetailPageHeaderComponent,
    ConfirmationModalComponent,
    SpinnerComponent,
    LoadingModalComponent,
    SchoolFilterComponent,
  ],
})
export class SharedModule {}

<section class="content">
  <header class="content__title" *ngIf="schoolObj">
    <h1><a [routerLink]="getBackLink()">Back</a></h1>
    <br />
    <h1>{{ schoolObj.name }}</h1>
    <small>{{ schoolObj.city }}</small>
  </header>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h6 *ngIf="!isEditing()">Create New Sop</h6>
          <h6 *ngIf="isEditing()">Editing Sop</h6>
          <form (ngSubmit)="onSubmit(heroForm)" #heroForm="ngForm">
            <div class="form-group">
              <label for="title">Title (in app)</label>
              <input
                type="text"
                class="form-control"
                id="title"
                required
                [(ngModel)]="editing.title"
                name="title"
                #title="ngModel"
              />
              <div [hidden]="title.valid || title.pristine" class="alert alert-danger">Title (in app)</div>
            </div>

            <div class="form-group">
              <label for="type">Sop Type</label>
              <select
                class="form-control fa"
                id="type"
                required
                [(ngModel)]="editing.type"
                name="type"
                #type="ngModel"
                (ngModelChange)="openAddNewSopTypeModal($event, heroForm, addNewSopTypeTemplate)"
              >
                <option value="" [disabled]="true"></option>
                <option style="cursor: pointer !important; color: blue" value="fa fa-plus-circle">
                  Add New Type &#xf055;
                </option>
                <option *ngFor="let option of sopTypeOptions" [value]="option">{{ option }}</option>
              </select>
              <div [hidden]="type.valid || type.pristine" class="alert alert-danger">Type is required</div>
            </div>

            <div class="form-group">
              <label for="location">File</label>
              <select
                class="form-control"
                id="location"
                required
                [(ngModel)]="editing.location"
                name="location"
                #location="ngModel"
              >
                <option *ngFor="let f of pdfs" [value]="f.fullPath">{{ f.fullPath }}</option>
              </select>
              <div [hidden]="location.valid || location.pristine" class="alert alert-danger">Location is required</div>
            </div>

            <button
              type="submit"
              class="btn btn-success"
              [disabled]="!heroForm.form.valid || loading"
              *ngIf="!isEditing()"
            >
              {{ loading ? "Creating.." : "Create" }}
            </button>
            <button
              type="submit"
              class="btn btn-success"
              [disabled]="!heroForm.form.valid || loading"
              *ngIf="isEditing()"
            >
              {{ loading ? "Saving.." : "Save" }}
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-danger" (click)="resetSop()" [hidden]="loading">Reset</button>
            <br /><br /><br /><br />
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="panel-body">
        <div *ngFor="let item of sops; let i = index" style="list-style: none">
          <div class="card" style="cursor: pointer">
            <div class="card-body">
              <h4 class="card-title">{{ item.title }}</h4>
              <h6 *ngIf="item.location" class="card-subtitle" (click)="download(i)">
                {{ item.location }} &nbsp;&nbsp; <i class="zmdi zmdi-download"></i>
              </h6>
              <h6 class="card-subtitle">Type: {{ item.type }}</h6>
              <a (click)="edit(i)" class="card-link"><i class="zmdi zmdi-edit"></i> &nbsp;&nbsp;EDIT</a>
              <a (click)="deleteSop(item)" class="card-link" style="color: red"
                ><i class="zmdi zmdi-delete"></i> &nbsp;&nbsp;DELETE</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="widget-lists card-columns"></div>
</section>

<ng-template #addNewSopTypeTemplate>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Enter the new sop type</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="newSopType">New Sop Type</label>
      <input type="text" class="form-control" id="newSopType" required [(ngModel)]="newSopType" name="newSopType" />
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-success" [disabled]="!newSopType" (click)="addNewType()">
      Add
    </button>
  </div>
</ng-template>

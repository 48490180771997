import { Component, OnInit } from "@angular/core";
import * as XLSX from "xlsx";
import { strings } from "environments/environment";
import { isEqual, orderBy, uniq } from "lodash";
import { DocumentData } from "@angular/fire/compat/firestore";
import { BulkVillageUpload, VillageType } from "common/typings";
import { SchoolService } from "app/core/services/school.service";
import { Router } from "@angular/router";
import { RoutePaths } from "app/shared/generic_variables";
import { ToastrService } from "ngx-toastr";
import { SchoolBulkUploadRequiredOrderOfSheetTitles } from "app/utils/school.util";

// AOA : array of array
type AOA = any[][];

@Component({
  selector: "app-bulk-village-upload",
  templateUrl: "./bulk-village-upload.component.html",
  styleUrls: ["./bulk-village-upload.component.scss"],
})
export class BulkVillageUploadComponent implements OnInit {
  constructor(private schoolService: SchoolService, private router: Router, private toastr: ToastrService) {}
  isYachtClubUpload = false;
  errorOccurred: boolean;
  inValidOrderOfSheetTitles = false;
  currentOrderOfSheetTitles = [];
  uploading = false;
  requiredOrderOfSheetTitles = ["#", ...SchoolBulkUploadRequiredOrderOfSheetTitles];

  isMaxSelect = false;
  currentPage = 0;
  isEmptyDrop = true;
  isExcelDrop = true;
  strings = strings;

  origExcelData: AOA = [];
  refExcelData: Array<any>;
  excelDataEncodeToJson: any[];
  excelTransformNum = [];

  /** Default excel file-name */
  sheetJsExcelName = "marinas.xlsx";

  /* excel sheet.js */
  sheetCellRange: XLSX.Range;
  sheetMaxRow: number;
  localWSheet: XLSX.WorkSheet;
  localWorkBook: XLSX.WorkBook;
  sheetNameForTab: Array<string> = ["excel tab 1", "excel tab 2"];
  totalPage = this.sheetNameForTab.length;
  selectDefault: string;
  sheetBufferRender: File;

  inputExcelOnClick(evt: any) {
    this.inValidOrderOfSheetTitles = false;
    const target: HTMLInputElement = evt.target;
    if (target.files.length === 0) {
      throw new Error("Please upload at least a valid file!");
    }
    if (target.files.length > 1) {
      throw new Error("Cannot use multiple files");
    }
    this.sheetJsExcelName = evt.target.files.item(0).name;
    const reader: FileReader = new FileReader();
    this.readerExcel(reader);
    reader.readAsArrayBuffer(target.files[0]);
    this.sheetBufferRender = target.files[0];
    this.isEmptyDrop = false;
    this.isExcelDrop = true;
  }

  ngOnInit() {
    if (this.router.url.includes("bulk-yacht-upload")) {
      this.isYachtClubUpload = true;
      this.requiredOrderOfSheetTitles = uniq([
        ...this.requiredOrderOfSheetTitles,
        "associatedSchoolName",
        "associatedSchoolId",
      ]);
    } else {
      this.isYachtClubUpload = false;
    }
  }

  transform(value: number) {
    return (
      (value >= 28 ? this.transform(((value / 28) >> 0) - 1) : "") + "ABCDEFGHIJKLMNOPQRSTUVWXYZab"[value % 28 >> 0]
    );
  }

  readerExcel(reader: FileReader, index = 0) {
    /* reset array */
    this.origExcelData = [];
    reader.onload = (e: any) => {
      const data: string = e.target.result;
      const wBook: XLSX.WorkBook = XLSX.read(data, { type: "array" });
      this.localWorkBook = wBook;
      const wsName: string = wBook.SheetNames[index];
      this.sheetNameForTab = wBook.SheetNames;
      this.totalPage = this.sheetNameForTab.length;
      this.selectDefault = this.sheetNameForTab[index];
      const wSheet: XLSX.WorkSheet = wBook.Sheets[wsName];
      this.localWSheet = wSheet;
      this.sheetCellRange = XLSX.utils.decode_range(wSheet["!ref"]);
      this.sheetMaxRow = this.sheetCellRange.e.r;
      this.origExcelData = <AOA>XLSX.utils.sheet_to_json(wSheet, {
        header: 1,
        range: wSheet["!ref"],
        raw: true,
      });
      this.refExcelData = this.origExcelData.map((value) => Object.assign([], value));
      this.excelTransformNum = [];
      for (let idx = 0; idx <= this.sheetCellRange.e.c; idx++) {
        this.excelTransformNum[idx] = this.transform(idx);
      }
      this.refExcelData.map((x) => x.unshift("#"));
      this.excelTransformNum.unshift("order");
      this.excelDataEncodeToJson = this.refExcelData.slice(0).map((item) =>
        item.reduce((obj: any, val: any, i: any) => {
          obj[this.excelTransformNum[i]] = val;
          return obj;
        }, {})
      );
    };
  }

  async confirmBulkUpload() {
    if (this.inValidOrderOfSheetTitles) {
      this.toastr.warning(`Invalid formatted sheet data.
      The either title name or order of the fields on uploaded sheet is wrong.
      Please try again with valid sheet data!`);
      return;
    }
    const valid = this.validateSheetTitleFieldsOrder();
    if (valid) {
      this.errorOccurred = false;
      this.inValidOrderOfSheetTitles = false;
      this.uploading = true;
      let allVillageList: DocumentData[] = [];
      this.excelDataEncodeToJson.slice(1, this.excelDataEncodeToJson.length).forEach((sheetData) => {
        let villageData = BulkVillageUpload.constructData(sheetData, this.isYachtClubUpload);
        if (this.isYachtClubUpload) {
          villageData = { ...villageData, isYachtClub: true };
        }
        if (villageData.schoolName) {
          allVillageList = [...allVillageList, villageData];
        }
      });
      allVillageList = orderBy(allVillageList, ["schoolName"], ["asc"]);
      console.log("allVillageList", allVillageList);
      const chunkedVillageList = this.splitArrayIntoChunksOfLen(allVillageList, 50);
      await Promise.all(
        chunkedVillageList.map(async (villageList) => {
          await this.schoolService.postBulkSchool(villageList);
        })
      )
        .then((_) => {
          this.uploading = false;
          this.toastr.success(`Success in uploading the ${strings.villageType}s.`);
          this.router.navigate([RoutePaths.home]);
        })
        .catch((error) => {
          console.log("error", error);
          this.uploading = false;
          this.errorOccurred = true;
          this.toastr.error(`Something went wrong while uploading the ${strings.villageType}s.
        Please refresh the page and try again!`);
        });
    } else {
      this.inValidOrderOfSheetTitles = true;
    }
  }

  validateSheetTitleFieldsOrder(): boolean {
    let valid = false;
    const sheetTitlesObj = this.excelDataEncodeToJson[0];
    let sheetTitles = [];
    if (sheetTitles && Object.keys(sheetTitlesObj).length) {
      Object.values(sheetTitlesObj).forEach((title) => {
        sheetTitles = [...sheetTitles, title];
      });
    }
    if (this.isYachtClubUpload) {
      sheetTitles = uniq([...sheetTitles, "associatedSchoolName", "associatedSchoolId"]);
    }
    this.currentOrderOfSheetTitles = sheetTitles;
    console.log("currentOrderOfSheetTitles", this.currentOrderOfSheetTitles);
    if (
      isEqual(sheetTitles, this.requiredOrderOfSheetTitles) ||
      isEqual(sheetTitles, this.requiredOrderOfSheetTitles.slice(0, this.requiredOrderOfSheetTitles.length - 1))
    ) {
      valid = true;
    }
    return valid;
  }

  splitArrayIntoChunksOfLen(arr: any[], len: number) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }

  downloadSchoolBulkUploadSpreadSheet() {
    this.schoolService.downloadSchoolBulkUploadSpreadSheet();
  }

  goToDownloadYachtSpreadSheetPage() {
    this.router.navigate([RoutePaths.yachtSpreadSheet]);
  }
  get isMarina() {
    return this.strings.villageType === VillageType.MARINA;
  }
}

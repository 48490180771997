<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    <strong
      >{{ text }} <span class="text-primary"> {{ highlightedText }}</span
      >?</strong
    >
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss(false)">Cancel</button>
  <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close(true)">Yes</button>
</div>

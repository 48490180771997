<section class="content">
  <header class="content__title" *ngIf="schoolData">
    <h1><a [routerLink]="getBackLink()">Back</a></h1>
    <br />
    <h1>{{ schoolData.name }}</h1>
    <small>{{ schoolData.city }}</small>
  </header>

  <!-- <div class="row">
        <div class="col-md-12">
            <div class="panel-body panel" >
                    <iframe width="1200" height="500" src="https://datastudio.google.com/embed/reporting/1NiryW1ctrXK9VkDnzYfNTVvatWj8ym_-/page/G6QY" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>
        </div>
    </div> -->

  <div class="row">
    <div class="col-md-12">
      <div class="panel-body panel">
        <app-school-explorer-alerts></app-school-explorer-alerts>
      </div>
    </div>
  </div>

  <br /><br />
  <hr />

  <div class="row">
    <div class="col-md-12">
      <div class="panel-body panel">
        <app-school-explorer-hotline></app-school-explorer-hotline>
      </div>
    </div>
  </div>

  <br /><br />
  <hr />

  <div class="row">
    <div class="col-md-12">
      <div class="panel-body panel">
        <app-school-explorer-broadcasts></app-school-explorer-broadcasts>
      </div>
    </div>
  </div>

  <br /><br />
  <hr />

  <div class="widget-lists card-columns"></div>
</section>

import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { strings } from "environments/environment";
import { AllUserRoles, RoutePaths } from "app/shared/generic_variables";
import { ComponentDataInteractService } from "../services/component-data-interact.service";

@Injectable({
  providedIn: "root",
})
export class IsMySchoolGuard implements CanActivate {
  constructor(
    private router: Router,
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private componentDataInteractService: ComponentDataInteractService
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const villagesAllowed = next?.data?.villages;
      if (villagesAllowed && villagesAllowed.includes(strings.villageType)) {
        this.afAuth.authState.subscribe((currentUser) => {
          if (currentUser != null) {
            this.afAuth.idTokenResult.subscribe((idTokenResult) => {
              const isOwner = idTokenResult["claims"]["owner"];
              const selectedSchoolId = next.paramMap.get("id");
              const uid = currentUser.uid;
              const userRef = this.db.doc("users/" + uid);
              const userDoc = userRef.valueChanges();
              userDoc.subscribe(
                (userValue) => {
                  this.componentDataInteractService.setCurrentUserData(userValue);
                  if (isOwner) {
                    return resolve(true);
                  } else if (userValue["isAssociatedToAllSchools"] === true) {
                    return resolve(true);
                  } else if (
                    userValue.hasOwnProperty("associatedSchools") &&
                    Object.keys(userValue["associatedSchools"])
                  ) {
                    const role = userValue["associatedSchools"][selectedSchoolId]["role"];
                    const schools = Object.keys(userValue["associatedSchools"]);
                    if (
                      schools &&
                      schools.includes(selectedSchoolId) &&
                      (role === AllUserRoles.district ||
                        ((role === AllUserRoles.admin || role === AllUserRoles.unit_owner_admin) &&
                          userValue["allowAdminUIPermission"]))
                    ) {
                      return resolve(true);
                    } else {
                      resolve(false);
                      return this.router.navigateByUrl(RoutePaths.home);
                    }
                  } else {
                    resolve(false);
                    return this.router.navigateByUrl(RoutePaths.home);
                  }
                },
                (error) => {
                  resolve(false);
                  this.router.navigateByUrl(RoutePaths.home);
                }
              );
            });
          } else {
            resolve(false);
            this.router.navigateByUrl(RoutePaths.home);
          }
        });
      } else {
        resolve(false);
        this.router.navigateByUrl(RoutePaths.home);
      }
    });
  }
}

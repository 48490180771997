import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { DocumentData } from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";

@Injectable()
export class GenericCollectionDataService {
  constructor(private angularFireStore: AngularFirestore) {}

  getDocData(parameters: { colName: string; colDocUID: string }) {
    const { colName, colDocUID } = parameters;
    return this.angularFireStore.collection(colName).doc(colDocUID).valueChanges();
  }

  setDocData(parameters: { colName: string; colDocUID: string; colDocData: any }) {
    const { colName, colDocUID, colDocData } = parameters;
    return this.angularFireStore.collection(colName).doc(colDocUID).set(colDocData, { merge: true });
  }

  updateDocData(parameters: { colName: string; colDocUID: string; colDocData: any }) {
    const { colName, colDocUID, colDocData } = parameters;
    return this.angularFireStore.collection(colName).doc(colDocUID).update(colDocData);
  }

  /**
   * Queries the  collection for documents where 'key' equals 'value'.{ key: any, operator: any, value: any }
   */
  queryCollection(parameters: { colName: string; queryList?: Array<{ key: any; operator: any; value: any }> }) {
    const { colName, queryList } = parameters;
    if (queryList) {
      const queryLength: number = queryList.length;
      if (queryLength == 0) {
        return this.angularFireStore.collection(colName).valueChanges();
      } else if (queryLength == 1) {
        const query = queryList[0];

        return this.angularFireStore
          .collection(colName, (ref) => ref.where(query.key, query.operator, query.value))
          .valueChanges();
      } else if (queryLength == 2) {
        const query1 = queryList[0];
        const query2 = queryList[1];

        return this.angularFireStore
          .collection(colName, (ref) =>
            ref.where(query1.key, query1.operator, query1.value).where(query2.key, query2.operator, query2.value)
          )
          .valueChanges();
      } else if (queryLength == 3) {
        const query1 = queryList[0];
        const query2 = queryList[1];
        const query3 = queryList[2];

        return this.angularFireStore
          .collection(colName, (ref) =>
            ref
              .where(query1.key, query1.operator, query1.value)
              .where(query2.key, query2.operator, query2.value)
              .where(query3.key, query3.operator, query3.value)
          )
          .valueChanges();
      } else if (queryLength == 4) {
        const query1 = queryList[0];
        const query2 = queryList[1];
        const query3 = queryList[2];
        const query4 = queryList[3];

        return this.angularFireStore
          .collection(colName, (ref) =>
            ref
              .where(query1.key, query1.operator, query1.value)
              .where(query2.key, query2.operator, query2.value)
              .where(query3.key, query3.operator, query3.value)
              .where(query4.key, query4.operator, query4.value)
          )
          .valueChanges();
      }
    } else {
      return this.angularFireStore.collection(colName).valueChanges();
    }
  }

  queryCollectionSnapshot(parameters: { colName: string; queryList: Array<{ key: any; operator: any; value: any }> }) {
    const { colName, queryList } = parameters;
    if (queryList) {
      const queryLength: number = queryList.length;
      if (queryLength == 0) {
        return this.angularFireStore.collection(colName).snapshotChanges();
      } else if (queryLength == 1) {
        const query = queryList[0];

        return this.angularFireStore
          .collection(colName, (ref) => ref.where(query.key, query.operator, query.value))
          .snapshotChanges();
      } else if (queryLength == 2) {
        const query1 = queryList[0];
        const query2 = queryList[1];

        return this.angularFireStore
          .collection(colName, (ref) =>
            ref.where(query1.key, query1.operator, query1.value).where(query2.key, query2.operator, query2.value)
          )
          .snapshotChanges();
      } else if (queryLength == 3) {
        const query1 = queryList[0];
        const query2 = queryList[1];
        const query3 = queryList[2];

        return this.angularFireStore
          .collection(colName, (ref) =>
            ref
              .where(query1.key, query1.operator, query1.value)
              .where(query2.key, query2.operator, query2.value)
              .where(query3.key, query3.operator, query3.value)
          )
          .snapshotChanges();
      } else if (queryLength == 4) {
        const query1 = queryList[0];
        const query2 = queryList[1];
        const query3 = queryList[2];
        const query4 = queryList[3];

        return this.angularFireStore
          .collection(colName, (ref) =>
            ref
              .where(query1.key, query1.operator, query1.value)
              .where(query2.key, query2.operator, query2.value)
              .where(query3.key, query3.operator, query3.value)
              .where(query4.key, query4.operator, query4.value)
          )
          .snapshotChanges();
      }
    } else {
      return this.angularFireStore.collection(colName).valueChanges();
    }
  }

  /**
   * Queries the  sub collection for documents where 'key' equals 'value'.
   */
  querySubCollection(parameters: {
    colName: string;
    colDocUID: string;
    subColName: string;
    queryList?: Array<{ key: any; operator: any; value: any }>;
  }) {
    const { colName, colDocUID, subColName, queryList } = parameters;
    if (queryList) {
      const queryLength: number = queryList.length;
      if (queryLength == 0) {
        return this.angularFireStore.collection(colName).doc(colDocUID).collection(subColName).valueChanges();
      } else if (queryLength == 1) {
        const query = queryList[0];
        return this.angularFireStore
          .collection(colName)
          .doc(colDocUID)
          .collection(subColName, (ref) => ref.where(query.key, query.operator, query.value))
          .valueChanges();
      } else if (queryLength == 2) {
        const query1 = queryList[0];
        const query2 = queryList[1];

        return this.angularFireStore
          .collection(colName)
          .doc(colDocUID)
          .collection(subColName, (ref) =>
            ref.where(query1.key, query1.operator, query1.value).where(query2.key, query2.operator, query2.value)
          )
          .valueChanges();
      } else if (queryLength == 3) {
        const query1 = queryList[0];
        const query2 = queryList[1];
        const query3 = queryList[2];
        return this.angularFireStore
          .collection(colName)
          .doc(colDocUID)
          .collection(subColName, (ref) =>
            ref
              .where(query1.key, query1.operator, query1.value)
              .where(query2.key, query2.operator, query2.value)
              .where(query3.key, query3.operator, query3.value)
          )
          .valueChanges();
      } else if (queryLength == 4) {
        const query1 = queryList[0];
        const query2 = queryList[1];
        const query3 = queryList[2];
        const query4 = queryList[3];

        return this.angularFireStore
          .collection(colName)
          .doc(colDocUID)
          .collection(subColName, (ref) =>
            ref
              .where(query1.key, query1.operator, query1.value)
              .where(query2.key, query2.operator, query2.value)
              .where(query3.key, query3.operator, query3.value)
              .where(query4.key, query4.operator, query4.value)
          )
          .valueChanges();
      } else {
        return this.angularFireStore.collection(colName).doc(colDocUID).collection(subColName).valueChanges();
      }
    } else {
      return this.angularFireStore.collection(colName).doc(colDocUID).collection(subColName).valueChanges();
    }
  }

  /**
   * Queries the  sub collection snapshots for documents where 'key' equals 'value'.
   */
  querySubCollectionSnapshot(parameters: {
    colName: string;
    colDocUID: string;
    subColName: string;
    queryList?: Array<{ key: any; operator: any; value: any }>;
  }) {
    const { colName, colDocUID, subColName, queryList } = parameters;
    if (queryList) {
      const queryLength: number = queryList.length;
      if (queryLength == 0) {
        return this.angularFireStore.collection(colName).doc(colDocUID).collection(subColName).snapshotChanges();
      } else if (queryLength == 1) {
        const query = queryList[0];

        return this.angularFireStore
          .collection(colName)
          .doc(colDocUID)
          .collection(subColName, (ref) => ref.where(query.key, query.operator, query.value))
          .snapshotChanges();
      } else if (queryLength == 2) {
        const query1 = queryList[0];
        const query2 = queryList[1];

        return this.angularFireStore
          .collection(colName)
          .doc(colDocUID)
          .collection(subColName, (ref) =>
            ref.where(query1.key, query1.operator, query1.value).where(query2.key, query2.operator, query2.value)
          )
          .snapshotChanges();
      } else if (queryLength == 3) {
        const query1 = queryList[0];
        const query2 = queryList[1];
        const query3 = queryList[2];

        return this.angularFireStore
          .collection(colName)
          .doc(colDocUID)
          .collection(subColName, (ref) =>
            ref
              .where(query1.key, query1.operator, query1.value)
              .where(query2.key, query2.operator, query2.value)
              .where(query3.key, query3.operator, query3.value)
          )
          .snapshotChanges();
      } else if (queryLength == 4) {
        const query1 = queryList[0];
        const query2 = queryList[1];
        const query3 = queryList[2];
        const query4 = queryList[3];

        return this.angularFireStore
          .collection(colName)
          .doc(colDocUID)
          .collection(subColName, (ref) =>
            ref
              .where(query1.key, query1.operator, query1.value)
              .where(query2.key, query2.operator, query2.value)
              .where(query3.key, query3.operator, query3.value)
              .where(query4.key, query4.operator, query4.value)
          )
          .snapshotChanges();
      } else {
        return this.angularFireStore.collection(colName).doc(colDocUID).collection(subColName).snapshotChanges();
      }
    } else {
      return this.angularFireStore.collection(colName).doc(colDocUID).collection(subColName).snapshotChanges();
    }
  }

  addNewSubCollectionDocData(parameters: {
    colName: string;
    colDocUID: string;
    subColName: string;
    data: DocumentData;
  }): Promise<firebase.firestore.DocumentReference> {
    const { colName, colDocUID, subColName, data } = parameters;
    return this.angularFireStore.collection(colName).doc(colDocUID).collection(subColName).add(data);
  }
}

import { Component, TemplateRef } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { VillageType } from "common/typings";
import { strings } from "environments/environment";
import * as path from "path";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { CollectionNames, RoutePaths } from "app/shared/generic_variables";
import firebase from "firebase/compat/app";

@Component({
  selector: "app-school",
  templateUrl: "./school.component.html",
  styleUrls: ["./school.component.css"],
})
export class SchoolComponent {
  imageFiles: firebase.storage.Reference[] = [];
  selectedId = "";
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  logo = "//placehold.it/72";
  strings = strings;
  selectedLogoImage: string;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private storage: AngularFireStorage,
    private _modalService: NgbModal
  ) {
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      this.loadFiles();
      const schoolRef = db.doc("schools/" + this.selectedId);
      this.school = schoolRef.valueChanges();
      this.school.subscribe((scl) => {
        this.schoolData = scl;
        if (scl["logo"] !== undefined && scl["logo"].trim() !== "") {
          const imageRef = this.storage.ref(scl["logo"]);
          imageRef.getDownloadURL().subscribe((url) => {
            this.logo = url;
          });
        }
      });
    });
  }

  loadFiles() {
    this.storage.storage
      .ref("Schools/" + this.selectedId + "/Documents")
      .listAll()
      .then((files) => {
        files.items.forEach((file) => {
          const ext = path.extname(file.fullPath);
          if ([".png", ".jpg", ".jpeg"].indexOf(ext.toLowerCase()) > -1) {
            this.imageFiles.push(file);
          }
        });
      });
  }

  openChangeLogoModal(templateRef: TemplateRef<any>) {
    this._modalService.open(templateRef, { size: "lg" });
  }

  uploadLogo() {
    if (!this.selectedLogoImage) {
      return;
    }
    try {
      this.db.collection(CollectionNames.schools).doc(this.selectedId).update({ logo: this.selectedLogoImage });
      this.closeModal();
    } catch (error) {
      console.log(error);
    }
  }

  onLogoSelectChange(event) {
    this.selectedLogoImage = event.target.value;
  }

  editVillage() {
    if (this.schoolData?.isYachtClub) {
      this.router.navigateByUrl(RoutePaths.schools + "/edit-yacht/" + this.selectedId);
    } else {
      this.router.navigateByUrl(RoutePaths.schools + "/edit/" + this.selectedId);
    }
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  goToUsers() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/users");
  }

  goToGroups() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/groups");
  }

  goToStudents() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/students");
  }

  goToDocuments() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/documents");
  }

  goToSops() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/sops");
  }

  goToAssets() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/assets");
  }

  goToReports() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/reports");
  }

  goToVisitorsLog() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/visitors");
  }

  goToSchoolSettings() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/settings");
  }

  goToRecentActivities() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/explorer");
  }

  goToEvents() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/events");
  }

  goToSettings() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/settings");
  }

  goToIncidents() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/incidents");
  }

  goToServices() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/vendors");
  }

  goToChannels() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/channels");
  }

  goToRegion() {
    this.router.navigateByUrl("app/schools/" + this.selectedId + "/region");
  }

  get isSchool() {
    return this.strings.villageType === VillageType.SCHOOL;
  }

  get isMarina() {
    return this.strings.villageType === VillageType.MARINA;
  }

  get isHoa() {
    return strings.villageType === VillageType.HOA;
  }
}

import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { GenericCollectionDataService } from "./generic-collection-data.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { saveAs } from "file-saver";

@Injectable()
export class UserService {
  userDoc: DocumentData;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private genericCollectionDataService: GenericCollectionDataService,
    private http: HttpClient
  ) {
    this.afAuth.authState.subscribe((currentUser) => {
      this.userDoc = this.afs.doc("/users/" + currentUser.uid);
    });
  }

  getAllUser$() {
    const parameters = { colName: "users", queryList: [] };
    return this.genericCollectionDataService.queryCollectionSnapshot(parameters);
  }

  postUser(postData: DocumentData) {
    return this.http.post(`${environment.apiEndPoint}/api/user`, postData);
  }

  async postBulkUser(userList: DocumentData[], schoolId: string) {
    return await this.http
      .post(`${environment.apiEndPoint}/api/user-bulk-upload/${schoolId}`, { data: userList })
      .toPromise();
  }

  downloadCsvFile(data: any) {
    const replacer = (key: string, value: any) => (!value ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map((row: any) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));
    csv.unshift(header.join(","));
    const csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, `report_${new Date()}`);
  }
}

import { Component, TemplateRef } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute } from "@angular/router";
import { CollectionNames } from "app/shared/generic_variables";
import { GenericCollectionDataService } from "app/core/services/generic-collection-data.service";
import { FormGroup } from "@angular/forms";
import { Roles } from "environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  AngularFirestore,
  DocumentChangeAction,
  DocumentData,
  DocumentReference,
} from "@angular/fire/compat/firestore";
import { map } from "rxjs/operators";
@Component({
  selector: "school-channels",
  templateUrl: "./school-channels.component.html",
})
export class SchoolChannelsComponent {
  items = [];
  selectedId = "";
  otherChannels: DocumentData[];
  school: DocumentData;
  newChannel = "";
  loading = false;
  allRoles: string[] = Roles;
  model = {
    name: "",
    roles: [],
    allowImdAccess: false,
  };
  selectedChannel: DocumentData;
  talkAroundChannels: DocumentData[] = [];

  constructor(
    public afAuth: AngularFireAuth,
    private genericCollectionDataService: GenericCollectionDataService,
    private route: ActivatedRoute,
    private _modalService: NgbModal,
    private db: AngularFirestore
  ) {
    this.afAuth.authState.subscribe((currentUser) => {
      const uid = currentUser.uid;
      this.route.paramMap.subscribe((params) => {
        this.selectedId = params.get("id");
        const schoolChannelParams = {
          colName: CollectionNames.schools,
          colDocUID: this.selectedId,
          subColName: CollectionNames.messages,
        };
        this.genericCollectionDataService
          .querySubCollectionSnapshot(schoolChannelParams)
          .pipe(
            map((documents: DocumentChangeAction<any>[]) =>
              documents.map((snap) => {
                const channelData = snap.payload.doc.data();
                const id = snap.payload.doc["id"];
                return { id, ...channelData };
              })
            )
          )
          .subscribe(async (channels) => {
            let allChannels = [];
            const nonDeletedChannels = channels.filter((item) => !item.deleted);
            for await (let channelData of nonDeletedChannels) {
              if ((channelData.class || channelData.direct) && channelData.members) {
                let members = [];
                for await (let member of channelData.members) {
                  const memberRef: DocumentReference = member;
                  const memberData = (await memberRef.get()).data();
                  if (memberData) members = [...members, memberData];
                }
                channelData = { ...channelData, members: members };
              }
              allChannels = [...allChannels, channelData];
            }
            this.talkAroundChannels = allChannels.filter(
              (channel) => channel.roles && channel.roles.length > 0 && !channel.isIntradoEvent
            );
            this.otherChannels = allChannels.filter((channel) => !this.talkAroundChannels.includes(channel));
          });
        const schoolParam = { colName: CollectionNames.schools, colDocUID: this.selectedId };
        this.genericCollectionDataService.getDocData(schoolParam).subscribe((school) => {
          this.school = school;
        });
      });
    });
  }

  createNewChannel(heroForm: FormGroup) {
    const schoolChannelParams = {
      colName: CollectionNames.schools,
      colDocUID: this.selectedId,
      subColName: CollectionNames.messages,
      data: this.model,
    };
    this.genericCollectionDataService.addNewSubCollectionDocData(schoolChannelParams).then((_) => {
      heroForm.reset();
    });
  }

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }

  formatRole(verificationStatus: string) {
    return verificationStatus.replace(/_/g, " ");
  }

  openDeleteChannelModal(channel: DocumentData, templateRef: TemplateRef<any>) {
    this.selectedChannel = channel;
    const modalRef = this._modalService.open(templateRef);
  }

  async deleteChannel() {
    try {
      await this.db
        .doc(`${CollectionNames.schools}/${this.selectedId}/${CollectionNames.messages}/${this.selectedChannel.id}`)
        .set({ deleted: true }, { merge: true });
      this._modalService.dismissAll();
    } catch (error) {
      console.log("error", error);
    }
  }

  async editChannel(channel: DocumentData, event: any) {
    try {
      await this.db
        .doc(`${CollectionNames.schools}/${this.selectedId}/${CollectionNames.messages}/${channel.id}`)
        .set({ allowImdAccess: event.target.checked }, { merge: true });
    } catch (error) {
      console.log("error", error);
    }
  }

  closeModal() {
    this._modalService.dismissAll();
    this.selectedChannel = null;
  }
}

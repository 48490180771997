import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from "angular-calendar";
import { CollectionNames } from "app/shared/generic_variables";
import { addDays, addHours, endOfDay, endOfMonth, isSameDay, isSameMonth, startOfDay, subDays } from "date-fns";
import { Subject } from "rxjs";
import { Observable } from "rxjs/Observable";

@Component({
  selector: "app-school-incidents",
  templateUrl: "./school-incidents.component.html",
  styles: [""],
})
export class SchoolIncidentsComponent implements OnInit {
  items: DocumentData[] = [];
  selectedId = "";
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  loading = false;

  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore, private route: ActivatedRoute) {
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      const schoolRef = this.db.doc("schools/" + this.selectedId);
      this.school = schoolRef.valueChanges();
      this.school.subscribe((scl) => {
        this.schoolData = scl;
      });
      const reportsRef = db
        .collection(CollectionNames.schools)
        .doc(this.selectedId)
        .collection("incident_reports", (ref) => ref.orderBy("createdAt", "desc"));
      reportsRef.valueChanges().subscribe((incidents) => {
        this.items = incidents;
        // dailyReports.forEach(dailyReport => {
        //     const reportDate = Date.parse(dailyReport['reportDate']) + (1000 * 60 * 60 * 24);
        //     this.events.push(<CalendarEvent>
        //         {
        //             start: endOfDay(reportDate),
        //             end: endOfDay(reportDate),
        //             title: 'Download Daily Report for ' +  dailyReport['reportDate'],
        //             color: colors.blue,
        //             draggable: false,
        //             meta: {
        //               report : dailyReport,
        //               incrementsBadgeTotal: false
        //             }
        //         }
        //     );
        // });
      });
    });
  }

  ngOnInit() {}

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }

  getImgUrl(item: DocumentData) {
    return "https://via.placeholder.com/640x480?text=SchoolVillage";
    // if (item.image == null || item.image.trim === '') {
    //     return '';
    // }
    // const downloadRef = this.storage.ref(item.image);
    // return await downloadRef.getDownloadURL().take(1);
  }
}

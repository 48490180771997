import { Component, OnInit } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs/Observable";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-school-explorer",
  templateUrl: "./school-explorer.component.html",
})
export class SchoolExplorerComponent implements OnInit {
  selectedId = "";
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  loading = false;

  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore, private route: ActivatedRoute) {
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      const schoolRef = this.db.doc("schools/" + this.selectedId);
      this.school = schoolRef.valueChanges();
      this.school.subscribe((scl) => {
        this.schoolData = scl;
      });
    });
  }

  ngOnInit() {}

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }
}

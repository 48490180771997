<section class="content">
  <header class="content__title" *ngIf="school">
    <h1><a [routerLink]="getBackLink()">Back</a></h1>
    <br />
    <h1>{{ school.name }}</h1>
    <small>{{ school.city }}</small>
  </header>

  <div class="row" style="margin-bottom: 1rem">
    <div class="col-md-12">
      <form (ngSubmit)="createNewChannel(heroForm)" #heroForm="ngForm">
        <div class="form-group">
          <label for="name">New Channel Name</label>
          <input
            type="text"
            class="form-control"
            id="channelName"
            pattern="[A-Za-z][A-Za-z_/-\s]+"
            required
            #channelName="ngModel"
            [(ngModel)]="model.name"
            name="channelName"
          />
          <div [hidden]="channelName.valid || channelName.pristine" class="alert alert-danger">
            Valid Name is required
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="allowImdAccess"
              #allowImdAccess="ngModel"
              [(ngModel)]="model.allowImdAccess"
              name="allowImdAccess"
            />
            <label class="form-check-label">
              Include this channel's communications log in Incident Management Dashboard.
            </label>
          </div>
        </div>
        <div class="form-group">
          <label for="roles">Roles</label>
          <select
            class="form-control"
            id="roles"
            multiple
            required
            [(ngModel)]="model.roles"
            name="roles"
            #roles="ngModel"
          >
            <option *ngFor="let role of allRoles" [value]="role">{{ formatRole(role) | titlecase }}</option>
          </select>
          <div [hidden]="roles.valid || roles.pristine" class="alert alert-danger">At least a role is required</div>
        </div>
        <button type="submit" class="btn btn-success" [disabled]="!heroForm.form.valid || loading">
          {{ loading ? "Creating.." : "Create" }}
        </button>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3 *ngIf="talkAroundChannels?.length > 0" style="text-decoration: underline">Channels</h3>
      <div class="card" style="cursor: pointer" *ngFor="let item of talkAroundChannels">
        <ng-container *ngTemplateOutlet="eachChannelTemplate; context: { item: item }"> </ng-container>
      </div>
    </div>
    <div class="col-md-12">
      <h3 *ngIf="otherChannels?.length > 0" style="text-decoration: underline">Groups</h3>
      <div class="card" style="cursor: pointer" *ngFor="let item of otherChannels">
        <ng-container *ngTemplateOutlet="eachChannelTemplate; context: { item: item }"> </ng-container>
      </div>
    </div>
  </div>
  <div class="widget-lists card-columns"></div>
</section>
<ng-template #eachChannelTemplate let-item="item">
  <div class="card-body">
    <div class="row">
      <div class="col-md-9">
        <h2 class="card-title font-weight-bold">
          <span *ngIf="item.class || item.direct; else nonGroupChannelTitle">
            <span *ngIf="item.class">
              {{ item.name ? item.name + " (Group Chat)" : "Group Chat" }}
            </span>
            <span *ngIf="item.direct">
              {{ item.name ? item.name + " (Direct Chat)" : "Direct Chat" }}
            </span>
          </span>
          <ng-template #nonGroupChannelTitle>
            <span>
              {{ item.name }}
            </span>
          </ng-template>
        </h2>
        <div *ngIf="!item.class && !item.direct" class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="item.allowImdAccess"
              (change)="editChannel(item, $event)"
            />
            <label class="form-check-label">
              Include this channel's communications log in Incident Management Dashboard.
            </label>
          </div>
        </div>

        <div *ngIf="item.roles" style="cursor: pointer">
          <h5>Roles:</h5>
          <ul>
            <li *ngFor="let role of item.roles">{{ formatRole(role) | titlecase }}</li>
          </ul>
        </div>
        <div *ngIf="item.members" style="cursor: pointer">
          <h5>Members:</h5>
          <div class="row">
            <div class="col-12 card" *ngFor="let member of item.members; let inx = index">
              <div *ngIf="member">
                <h6 class="font-weight-bold">
                  {{ inx + 1 }}. <i class="zmdi zmdi-account"></i>&nbsp;&nbsp; {{ member.firstName | titlecase }}
                  {{ member.lastName | titlecase }}
                </h6>
                <p style="margin-bottom: 2px"><i class="zmdi zmdi-email"></i>&nbsp;&nbsp;&nbsp;{{ member.email }}</p>
                <p *ngIf="member.phone">
                  <i class="zmdi zmdi-phone"></i>&nbsp;&nbsp;&nbsp;{{ member.phone }}
                  <span *ngIf="member?.phone_extension"
                    ><span class="pl-1 pr-1">X</span>{{ user.phone_extension }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="float-right">
          <div *ngIf="item.isIntradoEvent && item.isActive; else deleteButton">
            <span class="badge badge-primary">Is Active</span>
          </div>
          <ng-template #deleteButton>
            <button
              type="button"
              class="float-right btn btn-outline-danger"
              (click)="openDeleteChannelModal(item, deleteModal); $event.stopPropagation()"
            >
              DELETE
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Channel Deletion</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="closeModal()">
      <span aria-hidden=" true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <strong
        >Are you sure you want to delete
        <span class="text-primary"> {{ this.selectedChannel.name }}</span> Channel?</strong
      >
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="deleteChannel()">Yes</button>
  </div>
</ng-template>

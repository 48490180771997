import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CollectionNames } from "app/shared/generic_variables";
import { getBoatLocation, IBoatLocationOptions } from "app/utils/school.util";
import { strings } from "environments/environment";
import { uniqBy } from "lodash";
import { v4 } from "uuid";

@Component({
  selector: "app-boat",
  templateUrl: "./boat.component.html",
  styleUrls: ["./boat.component.css"],
})
export class BoatComponent implements OnInit {
  @ViewChild("ngAutoComplete") ngAutoComplete: any;
  @Input() showSchoolOptions = true;
  usStateList = [];
  boatModel = { boatName: "", boatLocationType: "", boatLocation: {}, schools: [] };
  locationOptions: IBoatLocationOptions = {
    dockNumber: "",
    slipNumber: "",
    mooring: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    description: "",
  };
  strings = strings;

  constructor(public activeModal: NgbActiveModal, private db: AngularFirestore) {}
  schoolsOptions: DocumentData[];

  ngOnInit(): void {
    this.getAllStates();
  }

  getAllStates() {
    this.db
      .collection(CollectionNames.states)
      .valueChanges()
      .subscribe((states) => {
        if (states) this.usStateList = states;
      });
  }

  villageSelectEvent(selectedSchool: DocumentData) {
    this.boatModel.schools = uniqBy([...this.boatModel.schools, selectedSchool], "id");
    this.ngAutoComplete.clear();
    this.schoolsOptions = [];
  }

  onChangeSearch(val: string) {
    this.queryVillageByName(val);
  }

  onFocused(e) {}

  onAdd() {
    const boatData = {
      ...{
        ...this.boatModel,
        boaterId: v4(),
        schools: this.boatModel.schools.map((item) => ({ id: item.id, name: item.name })),
      },
      boatLocation: getBoatLocation(this.boatModel.boatLocationType, this.locationOptions),
    };
    this.activeModal.close({ added: true, boatData: boatData });
  }

  queryVillageByName(schoolName: string) {
    this.db
      .collection(CollectionNames.schools, (ref) => ref.where("searchKeys", "array-contains", schoolName))
      .valueChanges()
      .subscribe((schools: DocumentData[]) => {
        this.schoolsOptions = schools;
      });
  }

  removeSelectedSchool(index: number) {
    this.boatModel.schools.splice(index, 1);
  }

  get getPlaceholder() {
    return `search ${strings.villageType} by name`;
  }
}

<section class="content">
  <div class="row">
    <div class="col-6">
      <app-page-header></app-page-header>
    </div>
    <div class="col-6">
      <div class="float-right">
        <a
          *ngIf="isMarina && isYachtClubUpload"
          class="btn btn-link mr-3"
          style="text-decoration: 'underline'"
          (click)="goToDownloadYachtSpreadSheetPage()"
        >
          <span class="mr-1">Download Yachts Template</span>
        </a>
        <button *ngIf="!isYachtClubUpload" class="btn btn-primary" (click)="downloadSchoolBulkUploadSpreadSheet()">
          <span class="mr-1">Download {{ strings.villageType }}s Template</span><i class="zmdi zmdi-download"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form>
            <h5>Choose Valid Excel (.xlsx) file to upload {{ isYachtClubUpload ? "Yacht" : strings.villageType }}s</h5>
            <input type="file" id="excel-upload" accept=".xlsx" multiple="false" (change)="inputExcelOnClick($event)" />
          </form>
          <div *ngIf="inValidOrderOfSheetTitles" class="mt-3">
            <div class="row">
              <div class="col-12">
                <p>
                  <span class="text-danger"
                    >The either title name or order of the fields on uploaded sheet is wrong. The name of titles of
                    fields should as follows in order:
                  </span>
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b>Expected Order:</b>
                  <br />
                  <span
                    *ngFor="
                      let title of requiredOrderOfSheetTitles.slice(1, requiredOrderOfSheetTitles.length);
                      let inx = index
                    "
                  >
                    {{ title }},
                    <br />
                  </span>
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b>But Got Current Order as:</b>
                  <br />
                  <span
                    *ngFor="
                      let title of currentOrderOfSheetTitles.slice(1, currentOrderOfSheetTitles.length);
                      let inx = index
                    "
                  >
                    {{ title }},
                    <br />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="errorOccurred" class="mt-3">
            <p>
              <span class="text-danger"
                >Something went wrong while uploading the {{ isYachtClubUpload ? "Yacht" : strings.villageType }}s. We
                could not upload all the {{ isYachtClubUpload ? "Yacht" : strings.villageType }}s. Please refresh the
                page and try again!
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="excelDataEncodeToJson">
    <div class="col-12 mb-4">
      <button [disabled]="uploading || inValidOrderOfSheetTitles" class="btn btn-success" (click)="confirmBulkUpload()">
        <span>{{ uploading ? "Uploading, Please wait...It will take few minutes...." : "Confirm Bulk Upload" }}</span>
      </button>
    </div>
    <div class="col-12">
      <h5>Preview Of {{ isYachtClubUpload ? "Yacht" : strings.villageType }}s</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="display-sheet-js" [hidden]="!(!isEmptyDrop && isExcelDrop)">
        <div class="example-container">
          <table cdk-table [dataSource]="excelDataEncodeToJson">
            <tr cdk-header-row *cdkHeaderRowDef="excelTransformNum; sticky: true"></tr>
            <tr cdk-row *cdkRowDef="let row; columns: excelTransformNum"></tr>
            <ng-container cdkColumnDef="order" sticky>
              <th cdk-header-cell *cdkHeaderCellDef>{{ sheetMaxRow }}</th>
              <td cdk-cell *cdkCellDef="let element; let idx = index">{{ idx }}</td>
            </ng-container>
            <ng-container
              *ngFor="let disCol of excelTransformNum | slice: 1; let colIndex = index"
              cdkColumnDef="{{ disCol }}"
            >
              <th cdk-header-cell *cdkHeaderCellDef>{{ disCol }}</th>
              <td cdk-cell *cdkCellDef="let element">{{ element[disCol] }}</td>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

import { DocumentData } from "@angular/fire/compat/firestore";
import { AllUserRoles } from "app/shared/generic_variables";

export enum LengthUnit {
  FEET = "f",
  METERS = "m",
}

export enum VillageType {
  SCHOOL = "School",
  MARINA = "Marina",
  HOA = "Association",
}

export enum VillageGroup {
  Harbor = "Harbor",
  District = "District",
  Area = "Area",
}

export enum permissions {
  owner = "owner",
  admin = "admin",
}

export enum AssociationTypes {
  hoa = "Hoa",
  condo = "Condo",
  coop = "Co-Op",
}

/**
 * Reference data obtained from sheet
  A: "S_name"
  B: "l_slipsCount"
  C: "l_slipsSizeRange.from"
  D: "l_slipsSizeRange.to"
  E: "S_slipsSizeUnit"
  F: "S_business_phone"
  G: "S_contact_name"
  H: "S_contact_email"
  I: "S_contact_phone"
  J: "S_harbor_name"
  K: "S_region_name"
  L: "S_address"
  M: "S_city"
  N: "S_state"
  O: "S_zip"
  P: "S_county"
  Q: "S_tz"
  R: "S_website"
  S: "S_location"
  T: "S_map_marina"
  U: "S_map_harbor"
  V: "S_comments"
  W: "B_has_moorings"
  X:"Notes"
*/

export class BulkVillageUpload {
  static constructData(sheetData: DocumentData, isYachtClubUpload: boolean) {
    let data: any = {
      schoolName: sheetData["A"] ? sheetData["A"] : "",
      slipsCount: sheetData["B"] ? sheetData["B"] : "",
      slipsSizeRange: {
        from: sheetData["C"] ? sheetData["C"] : 0,
        to: sheetData["D"] ? sheetData["D"] : 0,
      },
      slipsSizeUnit: sheetData["E"] ? sheetData["E"] : 0,
      business_phone: sheetData["F"] ? sheetData["F"] : "",
      contact_name: sheetData["G"] ? sheetData["G"] : "",
      contact_email: sheetData["H"] ? sheetData["H"] : "",
      contact_phone: sheetData["I"] ? sheetData["I"] : "",
      harbor_name: sheetData["J"] ? sheetData["J"] : "",
      region_name: sheetData["K"] ? sheetData["K"] : "",
      address: sheetData["L"] ? sheetData["L"] : "",
      city: sheetData["M"] ? sheetData["M"] : "",
      state: sheetData["N"] ? sheetData["N"] : "",
      zip: sheetData["O"] ? sheetData["O"] : "",
      county: sheetData["P"] ? sheetData["P"] : "US",
      timezone: sheetData["Q"] ? sheetData["Q"] : "",
      schoolWebsite: sheetData["R"] ? sheetData["R"] : "",
      schoolLocation: sheetData["S"] ? sheetData["S"] : "",
      schoolMarinaMap: sheetData["T"] ? sheetData["T"] : "",
      schoolHarborMap: sheetData["U"] ? sheetData["U"] : "",
      yacht_clubs: sheetData["V"] ? sheetData["V"] : "",
      has_moorings: sheetData["W"] ? true : false,
      vhf: sheetData["X"] ? sheetData["X"] : 0,
      notes: sheetData["Y"] ? sheetData["Y"] : "",
    };
    if (isYachtClubUpload) {
      data = {
        ...data,
        associatedSchoolName: sheetData["Z"] ? sheetData["Z"] : "",
        associatedSchoolId: sheetData["a"] ? sheetData["a"] : "",
      };
    }
    return data;
  }
}

export enum DocumentTypes {
  url = "url",
  pdf = "pdf",
  linkedPdf = "linked-pdf",
  internalLink = "internal-link",
  media = "media",
}

export enum DocumentCategories {
  MAP = "Map",
  SAFETY_PLAN = "Safety Plan",
  BUILDING_PLAN = "Building Plan",
  WEBSITE_LINK = "Website Link",
  OTHER = "Other",
}
export interface ISchoolDocument {
  type: DocumentTypes;
  location: string;
  icon: string;
  title: string;
  category?: DocumentCategories;
  accessRoles: AllUserRoles[];
  downloadOnLogin: boolean;
  id: string;
  media_type?: string;
  display: boolean;
  item_location?: "grid" | "list" | "header_left" | "header_right";
  item_position?: number;
}

export interface ISchoolSop {
  type: string;
  location: string;
  title: string;
}

export interface ICalendarEventData {
  title: string;
  description: string;
  startTimestamp: string;
  endTimestamp: string;
  location: "";
  attendees: [];
  isRecurring: false;
  recurrenceRule?: "" | "daily" | "weekly" | "monthly";
  createdBy?: "";
  createdById?: "";
  updatedBy?: "";
  updatedByById?: "";
}

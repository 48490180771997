import { Component, Input } from "@angular/core";
import { Location } from "@angular/common";
import { DocumentData } from "@angular/fire/compat/firestore";

@Component({
  selector: "app-school-detail-page-header",
  templateUrl: "./school-detail-page-header.component.html",
  styleUrls: ["./school-detail-page-header.component.css"],
})
export class SchoolDetailPageHeaderComponent {
  @Input() schoolData: DocumentData;
  constructor(private _location: Location) {}

  getBackLink() {
    this._location.back();
  }
}

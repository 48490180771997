import { AllUserRoles } from "app/shared/generic_variables";
import { VillageGroup, VillageType } from "common/typings";

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyB_8ZwG1B0g1N9F4KYnekydjhMkZInuFsY",
    authDomain: "hoavillage-1.firebaseapp.com",
    projectId: "hoavillage-1",
    storageBucket: "hoavillage-1.appspot.com",
    messagingSenderId: "921929035769",
    appId: "1:921929035769:web:e4db56d5049ae0aabd5728",
    measurementId: "G-GQ10P2FBVB",
  },
  apiEndPoint: "https://us-central1-hoavillage-1.cloudfunctions.net",
  frontendUrl: "https://hoavillage-1.web.app",
};

export const appName = "HoaVillage Prod";

export const Roles = [
  AllUserRoles.admin,
  AllUserRoles.maintenance,
  AllUserRoles.vendor,
  AllUserRoles.vendor_employee,
  AllUserRoles.security,
  AllUserRoles.pdFireEms,
  AllUserRoles.district,
  AllUserRoles.unit_owner,
  AllUserRoles.unit_owner_admin,
  AllUserRoles.family,
  AllUserRoles.tenant,
  AllUserRoles.tenant_family,
];

export const strings = {
  appName: "HoaVillage",
  villageType: VillageType.HOA,
  villageGroup: VillageGroup.Area,
};

export const AllAlerts = [
  {
    key: "armed_assailant",
    value: "Armed Assailant",
    title: "An ARMED ASSAILANT has been reported",
  },
  {
    key: "fight",
    value: "Fight",
    title: "A FIGHT has been reported",
  },
  {
    key: "medical_emergency",
    value: "Medical Emergency",
    title: "A MEDICAL EMERGENCY has been reported",
  },
  {
    key: "auto_accident",
    value: "Auto Accident/Injury",
    title: "An AUTO ACCIDENT/INJURY has been reported",
  },
  {
    key: "abduction_kidnapping",
    value: "Abduction/Kidnapping",
    title: "An ABDUCTION/KIDNAPPING has been reported",
  },
  {
    key: "mental_health",
    value: "Mental Health",
    title: "A MENTAL HEALTH has been reported",
  },
  {
    key: "fire",
    value: "fire",
    title: "A FIRE has been reported",
  },
  {
    key: "intruder",
    value: "Intruder",
    title: "An INTRUDER has been reported",
  },
  {
    key: "other_emergency",
    value: "Other Emergency",
    title: "An OTHER EMERGENCY has been reported",
  },
];

export const UserBulkUploadFileSampleTemplateFileName = "hv-user-bulk-upload-sample.xlsx";

import { DocumentData } from "@angular/fire/compat/firestore";
import { VillageType } from "common/typings";
import { AllAlerts, Roles } from "environments/environment";
import { getBoatLocation, IBoatLocationOptions } from "./school.util";

export class BulkUserUpload {
  static constructData(villageType: VillageType, sheetData: DocumentData) {
    let returnData: any = {};
    let basicData = {
      firstName: sheetData["A"] ? sheetData["A"] : "",
      lastName: sheetData["B"] ? sheetData["B"] : "",
      email: sheetData["C"] ? sheetData["C"] : "",
      role: sheetData["D"] ? sheetData["D"] : "",
      phone: sheetData["E"] ? sheetData["E"] : "",
      officePhone: sheetData["F"] ? sheetData["F"] : "",
      phone_extension: sheetData["G"] ? sheetData["G"] : "",
      company: sheetData["H"] ? sheetData["H"] : "",
    };
    returnData = { ...returnData, ...basicData };
    if (villageType === VillageType.MARINA) {
      try {
        let boatLocationType = "";
        const boatName = sheetData["I"] ? sheetData["I"] : "";
        const boatLocationOptions: IBoatLocationOptions = {
          dockNumber: sheetData["J"] ? sheetData["J"] : "",
          slipNumber: sheetData["K"] ? sheetData["K"] : "",
          mooring: sheetData["L"] ? sheetData["L"] : "",
          street: sheetData["M"] ? sheetData["M"] : "",
          city: sheetData["N"] ? sheetData["N"] : "",
          state: sheetData["O"] ? sheetData["O"] : "",
          zip: sheetData["P"] ? sheetData["P"] : "",
          description: "",
        };
        if (boatLocationOptions.dockNumber || boatLocationOptions.slipNumber) {
          boatLocationType = "dockSlip";
        } else if (boatLocationOptions.mooring) {
          boatLocationType = "mooring";
        } else if (
          boatLocationOptions.street &&
          boatLocationOptions.city &&
          boatLocationOptions.state &&
          boatLocationOptions.zip
        ) {
          boatLocationType = "address";
        } else {
          boatLocationType = "others";
        }
        const boatData = {
          boatLocation: getBoatLocation(boatLocationType, boatLocationOptions),
          boatName,
          boatLocationType,
        };
        if (boatData && Object.keys(boatData).length > 0) {
          returnData = { ...returnData, ...boatData };
        }
      } catch (error) {
        console.log("Error", error);
      }
    } else if (villageType === VillageType.SCHOOL) {
      returnData = {
        ...returnData,
        ...{
          family_first_name: sheetData["I"] ? sheetData["I"] : "",
          family_last_name: sheetData["J"] ? sheetData["J"] : "",
          family_email: sheetData["K"] ? sheetData["K"] : "",
          family_phone: sheetData["L"] ? sheetData["L"] : "",
        },
      };
    } else if (villageType === VillageType.HOA) {
    }
    return returnData;
  }
}

export function getAllGroups(): Object {
  let groups = {};
  Roles.forEach((role) => {
    groups[role] = true;
  });
  return groups;
}

export function getAllAlerts(): Object {
  let alerts = {};
  AllAlerts.forEach((alert) => {
    alerts[alert.key] = true;
  });
  return alerts;
}

import { Component, OnInit } from "@angular/core";
import { AngularFirestore, DocumentChangeAction, DocumentData } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs/Observable";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { ActivatedRoute } from "@angular/router";
import firebase from "firebase/compat/app";
import { map } from "rxjs/operators";

@Component({
  selector: "app-school-assets",
  templateUrl: "./school-assets.component.html",
})
export class SchoolAssetsComponent implements OnInit {
  items: firebase.storage.Reference[] = [];
  filesToUpload = [];
  selectedId = "";
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  loading = false;
  uploadPercent: Observable<number>;
  uploadingCount = 1;
  uploadedCount = 1;
  linkedPdfs: Observable<DocumentData[]>;

  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private storage: AngularFireStorage
  ) {
    this.afAuth.authState.subscribe((currentUser) => {
      const uid = currentUser.uid;
      this.route.paramMap.subscribe((params) => {
        this.selectedId = params.get("id");
        const schoolRef = db.doc("schools/" + this.selectedId);
        this.school = schoolRef.valueChanges();
        this.school.subscribe((scl) => {
          this.schoolData = scl;
        });
        this.linkedPdfs = db
          .collection("schools/" + this.selectedId + "/linked-pdfs")
          .snapshotChanges()
          .pipe(
            map((actions: DocumentChangeAction<DocumentData>[]) => {
              return actions.map((a) => {
                let data: DocumentData = a.payload.doc.data();
                console.log(data);

                const id = a.payload.doc["id"];
                const hello = { id, ...data };
                console.log(hello);

                return { id, ...data };
              });
            })
          );
        this.loadFiles();
      });
    });
  }

  loadFiles() {
    this.storage.storage
      .ref("Schools/" + this.selectedId + "/Documents")
      .listAll()
      .then((files) => {
        this.items = files.items;
      });
  }

  ngOnInit() {}

  download(ref: any) {
    const downloadRef = this.storage.ref(ref.fullPath);
    downloadRef.getDownloadURL().subscribe((url) => {
      window.open(url, "_blank");
    });
  }

  deleteItem(ref: any) {
    const downloadRef = this.storage.ref(ref.fullPath);
    const r = confirm("Are you sure you want to delete?\n" + ref.fullPath);
    if (r === true) {
      downloadRef.delete().subscribe((_) => {
        this.loadFiles();
      });
    }
  }

  deleteFolder(item: DocumentData) {
    this.db.collection(`schools/${this.selectedId}/linked-pdfs`).doc(item.id).delete();
  }

  uploadFile(event: any) {
    const file = event.target.files[0];
    const filePath = "Schools/" + this.selectedId + "/Documents/" + file.name;
    this.loading = true;
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.then((_) => {
      this.loadFiles();
      this.loading = false;
    });
  }

  uploadFolder(event: any) {
    this.filesToUpload = event.target.files;
  }

  async setAsRoot(item: DocumentData) {
    this.loading = true;
    this.uploadingCount = this.filesToUpload.length;
    const name = item.webkitRelativePath.split("/")[0];
    for (let i = 0; i < this.filesToUpload.length; i++) {
      const filePath = "Schools/" + this.selectedId + "/Documents/" + this.filesToUpload[i].webkitRelativePath;
      const task = this.storage.upload(filePath, this.filesToUpload[i]);
      this.uploadPercent = task.percentageChanges();
      await task;
      this.uploadedCount++;
    }

    try {
      await this.db.collection(`schools/${this.selectedId}/linked-pdfs`).add({
        name: name,
        root: item.webkitRelativePath,
      });
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
    this.filesToUpload = [];
    this.uploadingCount = 1;
    this.uploadedCount = 1;
  }

  getObject() {
    return Object;
  }

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }

  getLinkedLink() {
    return `/app/schools/${this.selectedId}/linked-pdfs`;
  }
}

import { Component, OnInit } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { ActivatedRoute } from "@angular/router";
import { AllUserRoles, CollectionNames } from "app/shared/generic_variables";
import { VillageGroup, VillageType } from "common/typings";
import { Roles, strings } from "environments/environment";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SchoolSettingsComponent implements OnInit {
  allRoles: string[] = Roles;
  selectedAlertReceivingRoles = {};
  schoolId: string;
  schoolData: DocumentData;
  formIsSubmitted = false;
  isTraining = false;
  sendAlertEmailNotification = false;
  recipientEmails: string[] = [];
  strings: { villageType?: VillageType; villageGroup?: VillageGroup } = strings;

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private toastr: ToastrService) {
    this.schoolId = this.route.snapshot.paramMap.get("id");
    if (this.schoolId) {
      this.getSchoolData();
    }
  }

  ngOnInit(): void {
    if (this.isMarinaVillage || this.isSchoolVillage) {
      this.allRoles = [...this.allRoles, AllUserRoles.guest];
    }
  }

  getSchoolData() {
    this.db
      .collection(CollectionNames.schools)
      .doc(this.schoolId)
      .valueChanges()
      .subscribe((schoolData: DocumentData) => {
        this.schoolData = schoolData;
        if (this.schoolData?.isTraining) {
          this.isTraining = true;
        } else {
          this.isTraining = false;
        }
        if (this.schoolData?.sendAlertEmailNotification) {
          this.sendAlertEmailNotification = true;
        } else {
          this.sendAlertEmailNotification = false;
        }
        if (this.schoolData && this.schoolData.alertReceivingRoles) {
          Object.entries(this.schoolData.alertReceivingRoles).forEach(([role, value]) => {
            this.selectedAlertReceivingRoles[role] = !!value;
          });
        } else {
          this.allRoles.forEach((role) => {
            this.selectedAlertReceivingRoles[role] = false;
          });
        }
        let recipientEmails = [];
        if (schoolData?.contact_email) {
          recipientEmails = [...recipientEmails, schoolData?.contact_email];
        }
        if (schoolData["reportEmails"] && schoolData["reportEmails"].length > 0) {
          recipientEmails = [...recipientEmails, ...schoolData["reportEmails"].split(",")];
        }
        this.recipientEmails = recipientEmails;
      });
  }

  updateAlertSettings() {
    this.formIsSubmitted = true;
    this.db
      .collection(CollectionNames.schools)
      .doc(this.schoolId)
      .set({ alertReceivingRoles: this.selectedAlertReceivingRoles }, { merge: true })
      .then((_) => {
        this.formIsSubmitted = false;
        this.toastr.success("Great, alert notifications settings have been updated!");
      })
      .catch((error) => {
        console.dir(error);
        this.formIsSubmitted = false;
        this.toastr.error("Oops, something went wrong!");
      });
  }

  onCheckBoxChange(event: any, role: any) {
    this.selectedAlertReceivingRoles[role] = event.target.checked;
  }

  onTrainingCheckBoxChange(event: any) {
    this.isTraining = event.target.checked;
  }

  onSendAlertEmailNotificationChange(event: any) {
    this.sendAlertEmailNotification = event.target.checked;
  }

  updateIsTrainingSettings() {
    this.formIsSubmitted = true;
    this.db
      .collection(CollectionNames.schools)
      .doc(this.schoolId)
      .update({ isTraining: this.isTraining })
      .then((_) => {
        this.formIsSubmitted = false;
        this.toastr.success("Great, 911 notifications delivery settings have been updated!");
      })
      .catch((error) => {
        console.dir(error);
        this.formIsSubmitted = false;
        this.toastr.error("Oops, something went wrong!");
      });
  }

  get isMarinaVillage() {
    return this.strings.villageType === VillageType.MARINA;
  }

  get isHoaVillage() {
    return this.strings.villageType === VillageType.HOA;
  }

  get isSchoolVillage() {
    return this.strings.villageType === VillageType.SCHOOL;
  }

  updateSendAlertEmailNotificationSettings() {
    this.formIsSubmitted = true;
    this.db
      .collection(CollectionNames.schools)
      .doc(this.schoolId)
      .update({ sendAlertEmailNotification: this.sendAlertEmailNotification })
      .then((_) => {
        this.formIsSubmitted = false;
        this.toastr.success("Great, email notification on alert settings have been updated!");
      })
      .catch((error) => {
        console.dir(error);
        this.formIsSubmitted = false;
        this.toastr.error("Oops, something went wrong!");
      });
  }
}

import { Routes, RouterModule } from "@angular/router";

const ROUTES: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
  { path: "app", loadChildren: () => import("./layout/layout.module").then((m) => m.LayoutModule) },
  { path: "auth", loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule) },
];

export const routing = RouterModule.forRoot(ROUTES, {
  useHash: false,
  relativeLinkResolution: "legacy",
  // enableTracing: true,
});

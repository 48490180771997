<header class="content__title">
  <h1 *ngIf="showBackArrow" class="mb-3">
    <a class="link" (click)="getBackLink()">Back</a>
    <br />
  </h1>
  <h1>Welcome {{ userName }}!</h1>
  <small *ngIf="isOwner">Owner</small>
  <div *ngIf="schoolData" class="row no-gutters">
    <div class="col">
      <h1 style="font-size: 18px">
        {{ schoolData?.name }}
      </h1>
    </div>
  </div>
  <small>{{ pageTitle }}</small>
  <small *ngIf="!isOwner && userData && (userData['isDistrictAdmin'] || userData['isAssociatedToAllSchools'])"
    >{{ strings.villageType }} District Admin</small
  >
  <small *ngIf="!isOwner && userData && userData['allowAdminUIPermission']">{{ strings.villageType }} Admin</small>
</header>

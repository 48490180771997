<section class="content">
  <app-page-header></app-page-header>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
            <div class="form-group">
              <label for="schoolName">{{ strings.villageType }} Name<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="schoolName"
                required
                [(ngModel)]="model.schoolName"
                name="firstName"
                #schoolName="ngModel"
              />
              <div [hidden]="schoolName.valid || schoolName.pristine" class="alert alert-danger">
                {{ strings.villageType }} Name is required
              </div>
            </div>

            <div *ngIf="isHoaVillage">
              <div class="form-check form-check-inline mr-5">
                <label for="associationTye">{{ strings.villageType }} Type<span class="required-field"></span></label>
              </div>
              <div class="form-check form-check-inline mr-5">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="association_type_hoa"
                  [(ngModel)]="model.associationTypes.hoa"
                  name="association_type_hoa"
                />
                <label class="form-check-label" for="association_type_hoa">
                  {{ AssociationTypes.hoa }}
                </label>
              </div>
              <div class="form-check form-check-inline mr-5">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="association_type_condo"
                  [(ngModel)]="model.associationTypes.condo"
                  name="association_type_condo"
                />
                <label class="form-check-label" for="association_type_condo">
                  {{ AssociationTypes.condo }}
                </label>
              </div>
              <div class="form-check form-check-inline mr-5">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="association_type_coop"
                  [(ngModel)]="model.associationTypes.coop"
                  name="association_type_coop"
                />
                <label class="form-check-label" for="association_type_coop">
                  {{ AssociationTypes.coop }}
                </label>
              </div>
            </div>

            <div class="form-group" *ngIf="isMarinaVillage">
              <label for="slipsCount">Number of Slips</label>
              <input
                type="number"
                min="0"
                step="1"
                class="form-control"
                id="slipsCount"
                [(ngModel)]="model.slipsCount"
                name="slipsCount"
                #slipsCount="ngModel"
              />
              <div [hidden]="slipsCount.valid || slipsCount.pristine" class="alert alert-danger">
                Number of slips is required
              </div>
            </div>

            <div *ngIf="!isHoaVillage" class="form-group">
              <label for="residentialUnitsCount">Number of Residential Units</label>
              <input
                type="number"
                min="0"
                step="1"
                class="form-control"
                id="residentialUnitsCount"
                [(ngModel)]="model.residentialUnitsCount"
                name="residentialUnitsCount"
                #residentialUnitsCount="ngModel"
              />
              <div [hidden]="residentialUnitsCount.valid || residentialUnitsCount.pristine" class="alert alert-danger">
                Number of Residential Units is required
              </div>
            </div>

            <div class="form-group" *ngIf="isHoaVillage">
              <label for="residentialUnitsCount">Type/Number of Units</label>
              <div class="row">
                <div class="col-2">
                  <label for="singleFamilyDetached">Single Family Detached</label>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    class="form-control"
                    id="singleFamilyDetached"
                    [(ngModel)]="hoaResidentUnitCounts.singleFamilyDetached"
                    name="singleFamilyDetached"
                    #singleFamilyDetached="ngModel"
                  />
                </div>
                <div class="col-2">
                  <label for="condominium_residential">Condominium-Residential</label>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    class="form-control"
                    id="condominium_residential"
                    [(ngModel)]="hoaResidentUnitCounts.condominium_residential"
                    name="condominium_residential"
                    #condominium_residential="ngModel"
                  />
                </div>
                <div class="col-2">
                  <label for="condominium_commercial">Condominium-Commercial</label>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    class="form-control"
                    id="condominium_commercial"
                    [(ngModel)]="hoaResidentUnitCounts.condominium_commercial"
                    name="condominium_commercial"
                    #condominium_commercial="ngModel"
                  />
                </div>
                <div class="col-2">
                  <label for="townhouse">Townhouse</label>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    class="form-control"
                    id="townhouse"
                    [(ngModel)]="hoaResidentUnitCounts.townhouse"
                    name="townhouse"
                    #townhouse="ngModel"
                  />
                </div>
                <div class="col-2">
                  <label for="total">Total</label>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    class="form-control"
                    [readonly]="true"
                    id="total"
                    [(ngModel)]="getTotalResidentCount"
                    name="total"
                    #total="ngModel"
                  />
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isMarinaVillage">
              <div class="form-group col">
                <label for="slipsSizeFrom">Slips size from</label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  class="form-control"
                  id="slipsSizeFrom"
                  [(ngModel)]="model.slipsSizeRange.from"
                  name="slipsSizeFrom"
                  #slipsSizeFrom="ngModel"
                />
                <div [hidden]="slipsSizeFrom.valid || slipsSizeFrom.pristine" class="alert alert-danger">
                  Minimum number of slips is required
                </div>
              </div>

              <div class="form-group col">
                <label for="slipsSizeTo">Slips size to</label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  class="form-control"
                  id="slipsSizeTo"
                  [(ngModel)]="model.slipsSizeRange.to"
                  name="slipsSizeTo"
                  #slipsSizeTo="ngModel"
                />
                <div [hidden]="slipsSizeTo.valid || slipsSizeTo.pristine" class="alert alert-danger">
                  Maximum number of slips is required
                </div>
              </div>

              <div class="form-check col">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="slipsSizeUnitFeet"
                    [(ngModel)]="model.slipsSizeUnit"
                    value="{{ LengthUnit.FEET }}"
                    name="slipsSizeUnitFeet"
                    #slipsSizeUnitFeet="ngModel"
                  />
                  <label class="form-check-label" for="slipsSizeUnitFeet">
                    {{ LengthUnit.FEET }}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="slipsSizeUnitMeters"
                    [(ngModel)]="model.slipsSizeUnit"
                    value="{{ LengthUnit.METERS }}"
                    name="slipsSizeUnitMeters"
                    #slipsSizeUnitMeters="ngModel"
                  />
                  <label class="form-check-label" for="slipsSizeUnitMeters">
                    {{ LengthUnit.METERS }}
                  </label>
                </div>
              </div>
            </div>

            <div class="form-row" *ngIf="isMarinaVillage || isSchoolVillage">
              <div class="col">
                <label for="business_phone">Business Phone</label>
                <input
                  type="tel"
                  class="form-control"
                  id="business_phone"
                  [(ngModel)]="model.business_phone"
                  name="business_phone"
                  #business_phone="ngModel"
                />
                <div [hidden]="business_phone.valid || business_phone.pristine" class="alert alert-danger">
                  Invalid phone number
                </div>
              </div>
              <div class="col">
                <label for="business_phone_extension">Business Phone Extension</label>
                <input
                  type="tel"
                  class="form-control"
                  id="business_phone_extension"
                  [(ngModel)]="model.business_phone_extension"
                  name="business_phone_extension"
                  #business_phone_extension="ngModel"
                />
                <div
                  [hidden]="business_phone_extension.valid || business_phone_extension.pristine"
                  class="alert alert-danger"
                >
                  Invalid phone extension (should be between 2 to 5 characters)
                </div>
              </div>
            </div>

            <div *ngIf="isHoaVillage" class="form-check mt-5 mb-5">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="managed_by_outside_company"
                [(ngModel)]="model.managed_by_outside_company"
                name="managed_by_outside_company"
              />
              <label class="form-check-label" for="managed_by_outside_company">
                Check if Association is managed by an outside company and complete info below
              </label>
            </div>

            <div *ngIf="model.managed_by_outside_company === true && isHoaVillage">
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="office_name">Management Entity Name (Association or outside manager)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="office_name"
                      [(ngModel)]="managementOrganization.name"
                      name="office_name"
                      #office_name="ngModel"
                    />
                    <div [hidden]="office_name.valid || office_name.pristine" class="alert alert-danger">
                      Organization Name is required
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="office_phone">Office Phone</label>
                    <input
                      type="tel"
                      class="form-control"
                      id="office_phone"
                      [(ngModel)]="managementOrganization.phone"
                      name="office_phone"
                      mask="(000) 000-0000"
                      #office_phone="ngModel"
                    />
                    <div [hidden]="office_phone.valid || office_phone.pristine" class="alert alert-danger">
                      Invalid phone number
                    </div>
                  </div>
                </div>
                <div class="col">
                  <label for="office_phone_extension">Office Phone Extension</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="office_phone_extension"
                    [(ngModel)]="model.phone_extension"
                    name="office_phone_extension"
                    #office_phone_extension="ngModel"
                  />
                  <div
                    [hidden]="office_phone_extension.valid || office_phone_extension.pristine"
                    class="alert alert-danger"
                  >
                    Invalid phone extension (should be between 2 to 5 characters)
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="office_address">Office Street Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="office_address"
                      [(ngModel)]="managementOrganization.address"
                      name="office_address"
                      placeholder="eg. 22222 Street Ave. "
                      #office_address="ngModel"
                    />
                    <div [hidden]="office_address.valid || office_address.pristine" class="alert alert-danger">
                      Address is required
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="office_city">Office City</label>
                    <input
                      type="text"
                      class="form-control"
                      id="office_city"
                      [(ngModel)]="managementOrganization.city"
                      name="office_city"
                      #office_city="ngModel"
                    />
                    <div [hidden]="office_city.valid || office_city.pristine" class="alert alert-danger">
                      City is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="office_state">Office State</label>
                    <select
                      class="form-select form-control"
                      id="office_state"
                      [(ngModel)]="managementOrganization.state"
                      name="office_state"
                      #office_state="ngModel"
                    >
                      <option value="{{ state?.state }}" *ngFor="let state of usStateList">{{ state?.state }}</option>
                    </select>
                    <div [hidden]="office_state.valid || office_state.pristine" class="alert alert-danger">
                      State is required
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="office_zip">Office Zip Code</label>
                    <input
                      type="text"
                      class="form-control"
                      id="office_zip"
                      [(ngModel)]="managementOrganization.zip"
                      name="office_zip"
                      #office_zip="ngModel"
                    />
                    <div [hidden]="office_zip.valid || office_zip.pristine" class="alert alert-danger">
                      Office Zip is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <div class="form-group mt-3">
                    <label for="office_contact_firstName"
                      >Primary Contact First Name<span class="required-field"></span
                    ></label>
                    <input
                      type="text"
                      class="form-control"
                      id="office_contact_firstName"
                      required
                      [(ngModel)]="managementOrganization.contact_firstName"
                      name="office_contact_firstName"
                      #office_contact_firstName="ngModel"
                    />
                    <div
                      [hidden]="office_contact_firstName.valid || office_contact_firstName.pristine"
                      class="alert alert-danger"
                    >
                      Primary Contact First Name is required
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group mt-3">
                    <label for="office_contact_lastName"
                      >Primary Contact Last Name<span class="required-field"></span
                    ></label>
                    <input
                      type="text"
                      class="form-control"
                      id="office_contact_lastName"
                      required
                      [(ngModel)]="managementOrganization.contact_lastName"
                      name="office_contact_lastName"
                      #office_contact_lastName="ngModel"
                    />
                    <div
                      [hidden]="office_contact_lastName.valid || office_contact_lastName.pristine"
                      class="alert alert-danger"
                    >
                      Primary Contact Last Name is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="office_contact_email">Primary Contact Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="office_contact_email"
                      [(ngModel)]="managementOrganization.contact_email"
                      name="office_contact_email"
                      #office_contact_email="ngModel"
                    />
                    <div
                      [hidden]="office_contact_email.valid || office_contact_email.pristine"
                      class="alert alert-danger"
                    >
                      Invalid email
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="office_primary_phone">Primary Contact Phone</label>
                    <input
                      type="tel"
                      class="form-control"
                      id="office_primary_phone"
                      [(ngModel)]="managementOrganization.contact_phone"
                      name="office_primary_phone"
                      mask="(000) 000-0000"
                      #office_primary_phone="ngModel"
                    />
                    <div
                      [hidden]="office_primary_phone.valid || office_primary_phone.pristine"
                      class="alert alert-danger"
                    >
                      Invalid phone number
                    </div>
                  </div>
                </div>
                <div class="col">
                  <label for="office_primary_phone_extension">Primary Contact Phone Extension</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="office_primary_phone_extension"
                    [(ngModel)]="managementOrganization.contact_phone_extension"
                    name="office_primary_phone_extension"
                    #office_primary_phone_extension="ngModel"
                  />
                  <div
                    [hidden]="office_primary_phone_extension.valid || office_primary_phone_extension.pristine"
                    class="alert alert-danger"
                  >
                    Invalid phone extension (should be between 2 to 5 characters)
                  </div>
                </div>
              </div>
            </div>

            <!-- <ng-template #primaryPhoneTemplateRef>
              <div class="form-row">
                <div class="col">
                  <label for="primary_phone">Primary Phone</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="primary_phone"
                    [(ngModel)]="model.primary_phone"
                    name="primary_phone"
                    mask="(000) 000-0000"
                    #primary_phone="ngModel"
                  />
                  <div [hidden]="primary_phone.valid || primary_phone.pristine" class="alert alert-danger">
                    Invalid phone number
                  </div>
                </div>
                <div class="col">
                  <label for="primary_phone_extension">Business Phone Extension</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="primary_phone_extension"
                    [(ngModel)]="model.primary_phone_extension"
                    name="primary_phone_extension"
                    #primary_phone_extension="ngModel"
                  />
                  <div
                    [hidden]="primary_phone_extension.valid || primary_phone_extension.pristine"
                    class="alert alert-danger"
                  >
                    Invalid phone extension (should be between 2 to 5 characters)
                  </div>
                </div>
              </div>
            </ng-template> -->

            <div *ngIf="isMarinaVillage || isSchoolVillage" class="form-row">
              <div class="col">
                <div class="form-group mt-3">
                  <label for="contact_firstName">Contact First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="contact_firstName"
                    [(ngModel)]="model.contact_firstName"
                    name="contact_firstName"
                    #contact_firstName="ngModel"
                  />
                  <div [hidden]="contact_firstName.valid || contact_firstName.pristine" class="alert alert-danger">
                    Contact First Name is required
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group mt-3">
                  <label for="contact_lastName">Contact Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="contact_lastName"
                    [(ngModel)]="model.contact_lastName"
                    name="contact_lastName"
                    #contact_lastName="ngModel"
                  />
                  <div [hidden]="contact_lastName.valid || contact_lastName.pristine" class="alert alert-danger">
                    Contact Last Name is required
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isMarinaVillage || isSchoolVillage" class="form-group mt-3">
              <label for="contact_email">Contact Email</label>
              <input
                type="email"
                class="form-control"
                id="contact_email"
                [(ngModel)]="model.contact_email"
                name="contact_email"
                #contact_email="ngModel"
              />
              <div [hidden]="contact_email.valid || contact_email.pristine" class="alert alert-danger">
                Invalid email
              </div>
            </div>

            <!-- <div *ngIf="isHoaVillage" class="form-row">
              <div class="col">
                <label for="contact_direct_phone">Contact Direct Phone (optional)</label>
                <input
                  type="tel"
                  class="form-control"
                  id="contact_direct_phone"
                  [(ngModel)]="model.contact_direct_phone"
                  name="contact_direct_phone"
                  mask="(000) 000-0000"
                  #contact_direct_phone="ngModel"
                />
                <div [hidden]="contact_direct_phone.valid || contact_direct_phone.pristine" class="alert alert-danger">
                  Invalid phone number
                </div>
              </div>
              <div class="col">
                <label for="contact_direct_phone_extension">Contact Direct Phone Extension</label>
                <input
                  type="tel"
                  class="form-control"
                  id="contact_direct_phone_extension"
                  [(ngModel)]="model.contact_direct_phone_extension"
                  name="contact_direct_phone_extension"
                  #contact_direct_phone_extension="ngModel"
                />
                <div
                  [hidden]="contact_direct_phone_extension.valid || contact_direct_phone_extension.pristine"
                  class="alert alert-danger"
                >
                  Invalid phone extension (should be between 2 to 5 characters)
                </div>
              </div>
            </div> -->

            <div *ngIf="isMarinaVillage" class="form-group">
              <label for="region">Region<span class="required-field"></span></label>
              <select
                class="form-control"
                id="region"
                required
                [(ngModel)]="model.region"
                (ngModelChange)="onRegionFilterChange($event)"
                name="region"
                #region="ngModel"
              >
                <option *ngFor="let region of allRegions" [value]="region.id">
                  {{ region.name }}
                </option>
              </select>
              <div [hidden]="region.valid || region.pristine" class="alert alert-danger">Region is required</div>
            </div>

            <div *ngIf="isMarinaVillage || isSchoolVillage; else hoaAddressTemplateRef">
              <div class="form-group">
                <label for="district">{{ strings.villageGroup }}<span class="required-field"></span></label>
                <select
                  class="form-control"
                  id="district"
                  required
                  [(ngModel)]="model.district"
                  name="district"
                  #district="ngModel"
                >
                  <option *ngFor="let district of filteredDistricts" [value]="district.id">
                    {{ district["name"] }}
                  </option>
                </select>
                <div [hidden]="district.valid || district.pristine" class="alert alert-danger">
                  {{ strings.villageGroup }} is required
                </div>
              </div>

              <div class="form-group">
                <label for="address">Full Address</label>
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  [(ngModel)]="model.address"
                  name="address"
                  #address="ngModel"
                />
                <div [hidden]="address.valid || address.pristine" class="alert alert-danger">Address is required</div>
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  [(ngModel)]="model.city"
                  name="city"
                  #city="ngModel"
                />
                <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
              </div>

              <div class="form-group">
                <label for="state">State</label>
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  [(ngModel)]="model.state"
                  name="state"
                  #state="ngModel"
                />
                <div [hidden]="state.valid || state.pristine" class="alert alert-danger">State is required</div>
              </div>
            </div>
            <ng-template #hoaAddressTemplateRef>
              <div class="form-row">
                <h5>Association Property Physical Location</h5>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="address"
                      >Address -provide a primary street name or inspection if no onsite office</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      [(ngModel)]="model.address"
                      name="address"
                      placeholder="eg. 22222 Street Ave. "
                      #address="ngModel"
                    />
                    <div [hidden]="address.valid || address.pristine" class="alert alert-danger">
                      Address is required
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="unit">Unit# (optional)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="unit"
                      [(ngModel)]="model.unit"
                      name="unit"
                      #unit="ngModel"
                    />
                    <div [hidden]="unit.valid || unit.pristine" class="alert alert-danger">Unit is required</div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="state">State</label>
                    <select
                      class="form-select form-control"
                      id="state"
                      [(ngModel)]="model.state"
                      name="state"
                      #state="ngModel"
                      (ngModelChange)="onStateSelection()"
                    >
                      <option value="{{ state?.state }}" *ngFor="let state of usStateList">{{ state?.state }}</option>
                    </select>
                    <div [hidden]="state.valid || state.pristine" class="alert alert-danger">State is required</div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="county">county</label>
                    <select
                      class="form-select form-control"
                      id="county"
                      [(ngModel)]="model.county"
                      name="county"
                      placeholder="county "
                      required
                      #county="ngModel"
                    >
                      <option value="{{ cty }}" *ngFor="let cty of selectedStateCounties">{{ cty }}</option>
                    </select>
                    <div [hidden]="county.valid || county.pristine" class="alert alert-danger">County is required</div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="city">City</label>
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      [(ngModel)]="model.city"
                      name="city"
                      #city="ngModel"
                    />
                    <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="zip">Zip</label>
                    <input
                      type="text"
                      class="form-control"
                      id="zip"
                      [(ngModel)]="model.zip"
                      name="zip"
                      #zip="ngModel"
                    />
                    <div [hidden]="zip.valid || zip.pristine" class="alert alert-danger">Zip is required</div>
                  </div>
                </div>
              </div>
            </ng-template>

            <div class="form-group">
              <label for="timezone">Timezone (For Reports)<span class="required-field"></span></label>
              <select
                class="form-control"
                id="timezone"
                required
                [(ngModel)]="model.timezone"
                name="timezone"
                #timezone="ngModel"
              >
                <option *ngFor="let zone of timezones" [value]="zone">
                  {{ zone }}
                </option>
              </select>
              <div [hidden]="timezone.valid || timezone.pristine" class="alert alert-danger">Timezone is required</div>
            </div>

            <div class="form-group">
              <label for="website"> {{ strings.villageType }} Website URL</label>
              <input
                type="text"
                class="form-control"
                id="website"
                [(ngModel)]="model.website"
                name="website"
                #website="ngModel"
              />
            </div>

            <div class="form-group">
              <label for="map_marina">{{ strings.villageType }} map URL</label>
              <input
                type="text"
                class="form-control"
                id="map_marina"
                [(ngModel)]="model.map_marina"
                name="map_marina"
                #map_marina="ngModel"
              />
            </div>

            <div *ngIf="isMarinaVillage" class="form-group">
              <label for="map_harbor">{{ strings.villageGroup }} map URL</label>
              <input
                type="text"
                class="form-control"
                id="map_harbor"
                [(ngModel)]="model.map_harbor"
                name="map_harbor"
                #map_harbor="ngModel"
              />
            </div>

            <div class="form-group">
              <label for="location">Lat/Long coordinates</label>
              <input
                type="text"
                class="form-control"
                id="location"
                [(ngModel)]="model.location"
                name="location"
                #location="ngModel"
              />
            </div>

            <div class="form-group">
              <label for="reportEmails">Email Distribution of Daily Safety Report to: (comma separated emails):</label>
              <input
                type="text"
                class="form-control"
                id="reportEmails"
                [(ngModel)]="model.reportEmails"
                name="reportEmails"
                #reportEmails="ngModel"
                min="3"
                pattern="^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$"
              />
              <div [hidden]="reportEmails.valid || reportEmails.pristine" class="alert alert-danger">
                Valid Comma Separated Emails required
              </div>
            </div>

            <button type="submit" class="btn btn-success mr-2" [disabled]="!heroForm.form.valid || loading">
              {{ mode === "add" ? (loading ? "Creating.." : "Create") : loading ? "Saving.." : "Save" }}
            </button>
            <button
              *ngIf="mode === 'add'"
              type="button"
              class="btn btn-danger"
              (click)="heroForm.reset()"
              [hidden]="loading"
            >
              Reset
            </button>
            <br /><br /><br /><br />
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content">
  <app-page-header></app-page-header>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
            <div class="form-group">
              <label for="schoolName">Yacht Name<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="schoolName"
                required
                [(ngModel)]="model.schoolName"
                name="firstName"
                #schoolName="ngModel"
              />
              <div [hidden]="schoolName.valid || schoolName.pristine" class="alert alert-danger">
                Yacht Name is required
              </div>
            </div>

            <div class="form-group">
              <label for="slipsCount">Number of Slips</label>
              <input
                type="number"
                min="0"
                step="1"
                class="form-control"
                id="slipsCount"
                [(ngModel)]="model.slipsCount"
                name="slipsCount"
                #slipsCount="ngModel"
              />
              <div [hidden]="slipsCount.valid || slipsCount.pristine" class="alert alert-danger">
                Number of slips is required
              </div>
            </div>

            <div class="row">
              <div class="form-group col">
                <label for="slipsSizeFrom">Slips size from</label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  class="form-control"
                  id="slipsSizeFrom"
                  [(ngModel)]="model.slipsSizeRange.from"
                  name="slipsSizeFrom"
                  #slipsSizeFrom="ngModel"
                />
                <div [hidden]="slipsSizeFrom.valid || slipsSizeFrom.pristine" class="alert alert-danger">
                  Minimum number of slips is required
                </div>
              </div>

              <div class="form-group col">
                <label for="slipsSizeTo">Slips size to</label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  class="form-control"
                  id="slipsSizeTo"
                  [(ngModel)]="model.slipsSizeRange.to"
                  name="slipsSizeTo"
                  #slipsSizeTo="ngModel"
                />
                <div [hidden]="slipsSizeTo.valid || slipsSizeTo.pristine" class="alert alert-danger">
                  Maximum number of slips is required
                </div>
              </div>

              <div class="form-check col">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="slipsSizeUnitFeet"
                    [(ngModel)]="model.slipsSizeUnit"
                    value="{{ LengthUnit.FEET }}"
                    name="slipsSizeUnitFeet"
                    #slipsSizeUnitFeet="ngModel"
                  />
                  <label class="form-check-label" for="slipsSizeUnitFeet">
                    {{ LengthUnit.FEET }}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="slipsSizeUnitMeters"
                    [(ngModel)]="model.slipsSizeUnit"
                    value="{{ LengthUnit.METERS }}"
                    name="slipsSizeUnitMeters"
                    #slipsSizeUnitMeters="ngModel"
                  />
                  <label class="form-check-label" for="slipsSizeUnitMeters">
                    {{ LengthUnit.METERS }}
                  </label>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <label for="business_phone">Business Phone</label>
                <input
                  type="tel"
                  class="form-control"
                  id="business_phone"
                  [(ngModel)]="model.business_phone"
                  name="business_phone"
                  #business_phone="ngModel"
                />
                <div [hidden]="business_phone.valid || business_phone.pristine" class="alert alert-danger">
                  Invalid phone number
                </div>
              </div>
              <div class="col">
                <label for="business_phone_extension">Business Phone Extension</label>
                <input
                  type="tel"
                  class="form-control"
                  id="business_phone_extension"
                  [(ngModel)]="model.business_phone_extension"
                  name="business_phone_extension"
                  #business_phone_extension="ngModel"
                />
                <div
                  [hidden]="business_phone_extension.valid || business_phone_extension.pristine"
                  class="alert alert-danger"
                >
                  Invalid phone extension (should be between 2 to 5 characters)
                </div>
              </div>
            </div>

            <ng-template #primaryPhoneTemplateRef>
              <div class="form-row">
                <div class="col">
                  <label for="primary_phone">Primary Phone</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="primary_phone"
                    [(ngModel)]="model.primary_phone"
                    name="primary_phone"
                    mask="(000) 000-0000"
                    #primary_phone="ngModel"
                  />
                  <div [hidden]="primary_phone.valid || primary_phone.pristine" class="alert alert-danger">
                    Invalid phone number
                  </div>
                </div>
                <div class="col">
                  <label for="primary_phone_extension">Business Phone Extension</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="primary_phone_extension"
                    [(ngModel)]="model.primary_phone_extension"
                    name="primary_phone_extension"
                    #primary_phone_extension="ngModel"
                  />
                  <div
                    [hidden]="primary_phone_extension.valid || primary_phone_extension.pristine"
                    class="alert alert-danger"
                  >
                    Invalid phone extension (should be between 2 to 5 characters)
                  </div>
                </div>
              </div>
            </ng-template>

            <div class="form-row">
              <div class="col">
                <div class="form-group mt-3">
                  <label for="contact_firstName">Contact First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="contact_firstName"
                    [(ngModel)]="model.contact_firstName"
                    name="contact_firstName"
                    #contact_firstName="ngModel"
                  />
                  <div [hidden]="contact_firstName.valid || contact_firstName.pristine" class="alert alert-danger">
                    Contact First Name is required
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group mt-3">
                  <label for="contact_lastName">Contact Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="contact_lastName"
                    [(ngModel)]="model.contact_lastName"
                    name="contact_lastName"
                    #contact_lastName="ngModel"
                  />
                  <div [hidden]="contact_lastName.valid || contact_lastName.pristine" class="alert alert-danger">
                    Contact Last Name is required
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group mt-3">
              <label for="contact_email">Contact Email</label>
              <input
                type="email"
                class="form-control"
                id="contact_email"
                [(ngModel)]="model.contact_email"
                name="contact_email"
                #contact_email="ngModel"
              />
              <div [hidden]="contact_email.valid || contact_email.pristine" class="alert alert-danger">
                Invalid email
              </div>
            </div>

            <div class="form-group">
              <label for="region">Region<span class="required-field"></span></label>
              <select
                class="form-control"
                id="region"
                required
                [(ngModel)]="model.region"
                (ngModelChange)="onRegionFilterChange($event)"
                name="region"
                #region="ngModel"
              >
                <option *ngFor="let region of allRegions" [value]="region.id">
                  {{ region.name }}
                </option>
              </select>
              <div [hidden]="region.valid || region.pristine" class="alert alert-danger">Region is required</div>
            </div>

            <div>
              <div class="form-group">
                <label for="district">{{ strings.villageGroup }}<span class="required-field"></span></label>
                <select
                  class="form-control"
                  id="district"
                  required
                  [(ngModel)]="model.district"
                  (ngModelChange)="onDistrictFilterChange($event)"
                  name="district"
                  #district="ngModel"
                >
                  <option *ngFor="let district of filteredDistricts" [value]="district.id">
                    {{ district["name"] }}
                  </option>
                </select>
                <div [hidden]="district.valid || district.pristine" class="alert alert-danger">
                  {{ strings.villageGroup }} is required
                </div>
              </div>
              <div class="form-group">
                <label for="school">{{ strings.villageType }}</label>
                <select
                  class="form-control"
                  id="school"
                  [(ngModel)]="model.associatedSchoolId"
                  name="school"
                  #school="ngModel"
                >
                  <option *ngFor="let school of filteredSchools" [value]="school.id">
                    {{ school["name"] }}
                  </option>
                </select>
                <div [hidden]="school.valid || school.pristine" class="alert alert-danger">
                  {{ strings.villageType }} is required
                </div>
              </div>

              <div class="form-group">
                <label for="address">Full Address</label>
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  [(ngModel)]="model.address"
                  name="address"
                  #address="ngModel"
                />
                <div [hidden]="address.valid || address.pristine" class="alert alert-danger">Address is required</div>
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  [(ngModel)]="model.city"
                  name="city"
                  #city="ngModel"
                />
                <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
              </div>

              <div class="form-group">
                <label for="state">State</label>
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  [(ngModel)]="model.state"
                  name="state"
                  #state="ngModel"
                />
                <div [hidden]="state.valid || state.pristine" class="alert alert-danger">State is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="timezone">Timezone (For Reports)<span class="required-field"></span></label>
              <select
                class="form-control"
                id="timezone"
                required
                [(ngModel)]="model.timezone"
                name="timezone"
                #timezone="ngModel"
              >
                <option *ngFor="let zone of timezones" [value]="zone">
                  {{ zone }}
                </option>
              </select>
              <div [hidden]="timezone.valid || timezone.pristine" class="alert alert-danger">Timezone is required</div>
            </div>

            <div class="form-group">
              <label for="website"> Yacht Website URL</label>
              <input
                type="text"
                class="form-control"
                id="website"
                [(ngModel)]="model.website"
                name="website"
                #website="ngModel"
              />
            </div>

            <div class="form-group">
              <label for="map_marina">Yacht map URL</label>
              <input
                type="text"
                class="form-control"
                id="map_marina"
                [(ngModel)]="model.map_marina"
                name="map_marina"
                #map_marina="ngModel"
              />
            </div>

            <div class="form-group">
              <label for="map_harbor">{{ strings.villageGroup }} map URL</label>
              <input
                type="text"
                class="form-control"
                id="map_harbor"
                [(ngModel)]="model.map_harbor"
                name="map_harbor"
                #map_harbor="ngModel"
              />
            </div>

            <div class="form-group">
              <label for="location">Lat/Long coordinates</label>
              <input
                type="text"
                class="form-control"
                id="location"
                [(ngModel)]="model.location"
                name="location"
                #location="ngModel"
              />
            </div>

            <div class="form-group">
              <label for="reportEmails">Email Distribution of Daily Safety Report to: (comma separated emails):</label>
              <input
                type="text"
                class="form-control"
                id="reportEmails"
                [(ngModel)]="model.reportEmails"
                name="reportEmails"
                #reportEmails="ngModel"
                min="3"
                pattern="^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$"
              />
              <div [hidden]="reportEmails.valid || reportEmails.pristine" class="alert alert-danger">
                Valid Comma Separated Emails required
              </div>
            </div>

            <button type="submit" class="btn btn-success mr-2" [disabled]="!heroForm.form.valid || loading">
              {{ mode === "add" ? (loading ? "Creating.." : "Create") : loading ? "Saving.." : "Save" }}
            </button>
            <button
              *ngIf="mode === 'add'"
              type="button"
              class="btn btn-danger"
              (click)="heroForm.reset()"
              [hidden]="loading"
            >
              Reset
            </button>
            <br /><br /><br /><br />
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore, DocumentData, DocumentReference } from "@angular/fire/compat/firestore";
import { CollectionNames } from "app/shared/generic_variables";
import { map } from "rxjs/operators";

@Component({
  selector: "school-region",
  templateUrl: "./region.component.html",
  styleUrls: ["./region.component.css"],
})
export class SchoolRegionComponent {
  allRegions: DocumentData[];
  selectedSchoolId: string;
  school: DocumentData;
  loading = false;
  model = {
    region: "",
  };
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private db: AngularFirestore) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.selectedSchoolId = params.get("id");
      this.getSchool();
    });
    this.getAllRegions();
  }

  getAllRegions() {
    this.db
      .collection(CollectionNames.regions, (ref) => ref.where("deleted", "==", false))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((cb) => {
            const data: DocumentData = cb.payload.doc.data();
            const id = cb.payload.doc["id"];
            return { id, ...data };
          })
        )
      )
      .subscribe((regions) => {
        this.allRegions = regions;
      });
  }

  getSchool() {
    this.db
      .doc("schools/" + this.selectedSchoolId)
      .valueChanges()
      .subscribe(async (school: DocumentData) => {
        if (school.region) {
          const regionRef: DocumentReference = school.region;
          const regionId = (await regionRef.get()).id;
          this.model = { ...this.model, region: regionId };
        }
      });
  }

  async onSubmit() {
    try {
      this.loading = true;
      const regionRef = this.db.collection(CollectionNames.regions).doc(this.model.region).ref;
      await this.db
        .doc(`${CollectionNames.schools}/${this.selectedSchoolId}`)
        .set({ region: regionRef }, { merge: true });
      this.router.navigateByUrl("app/schools/" + this.selectedSchoolId);
    } catch (error) {
      console.log("result", error);
      this.loading = false;
    }
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as path from "path";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";

import { AllUserRoles } from "app/shared/generic_variables";
import { Roles, strings } from "environments/environment";
import { DocumentCategories, DocumentTypes, ISchoolDocument, VillageGroup, VillageType } from "common/typings";
import { orderBy } from "lodash";

@Component({
  selector: "app-school-documents",
  templateUrl: "./school-documents.component.html",
})
export class SchoolDocumentsComponent implements OnInit, OnDestroy {
  files = [];
  imageFiles = [];
  pdfs = [];
  selectedId: string = "";
  documents: ISchoolDocument[] = [];
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  newGroup = "";
  loading = false;
  images = {};
  userRoles = [...Roles.filter((role) => role !== AllUserRoles.pdFireEms)];
  strings: { villageType?: VillageType; villageGroup?: VillageGroup } = strings;
  editing: ISchoolDocument = {
    id: "",
    type: DocumentTypes.url,
    location: "",
    icon: "",
    title: "",
    category: DocumentCategories.OTHER,
    item_location: "list",
    item_position: null,
    accessRoles: this.userRoles,
    downloadOnLogin: false,
    display: true,
  };
  editingIndex: number;
  linkedPdfs: DocumentData[] = [];

  categories = DocumentCategories;
  subs = new SubSink();
  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private storage: AngularFireStorage,
    private toastr: ToastrService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      const schoolRef = db.doc("schools/" + this.selectedId);
      this.school = schoolRef.valueChanges();
      this.subs.sink = this.school.subscribe((scl) => {
        this.schoolData = scl;
        this.documents = orderBy(scl["documents"] === undefined ? [] : scl["documents"], "item_position");
        this.loadImages();
        this.setItemPosition();
      });
      this.subs.sink = this.db
        .collection("schools/" + this.selectedId + "/linked-pdfs")
        .snapshotChanges()
        .pipe(
          map((actions) => {
            return actions.map((a) => {
              const data: DocumentData = a.payload.doc.data();
              const id = a.payload.doc["id"];
              return { id, ...data };
            });
          })
        )
        .subscribe((links) => {
          this.linkedPdfs = links;
        });
      this.loadFiles();
    });
  }

  ngOnInit() {
    if (this.isMarinaVillage || this.isSchoolVillage) {
      this.userRoles = [...this.userRoles, AllUserRoles.guest];
    }
  }

  loadFiles() {
    this.storage.storage
      .ref("Schools/" + this.selectedId + "/Documents")
      .listAll()
      .then((files) => {
        this.files = files.items;
        this.files.forEach((file) => {
          const ext = path.extname(file.fullPath);
          if ([".png", ".jpg", ".jpeg"].indexOf(ext.toLowerCase()) > -1) {
            this.imageFiles.push(file);
          }
          if ([".pdf"].indexOf(ext.toLowerCase()) > -1) {
            this.pdfs.push(file);
          }
        });
      });
  }

  async saveUpdatedOrder(docList: ISchoolDocument[]) {
    const newDocs = {
      documents: docList,
    };
    try {
      await this.db.doc(`schools/${this.selectedId}`).update(newDocs);
    } catch (error) {
      console.log(error);
    }
  }

  deleteDocument(index: number) {
    if (confirm("Are you sure to delete this document?")) {
      this.documents.splice(index, 1);
      this.saveUpdatedOrder(this.documents);
      this.toastr.success("Great, document has been deleted successfully!");
    }
  }

  createNewGroup() {
    const changes = {
      groups: {},
    };
    changes.groups[this.newGroup] = {
      sortOrder: this.documents.length,
    };
    this.db
      .doc(`schools/${this.selectedId}`)
      .set(changes, { merge: true })
      .then(() => {});
  }

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }

  async loadImages() {
    for (let i = 0; i < this.documents.length; i++) {
      if (this.documents[i]["icon"]) {
        const downloadRef = this.storage.ref(this.documents[i]["icon"]);
        downloadRef.getDownloadURL().subscribe((image) => {
          this.images[`${i}`] = image;
        });
      }
    }
  }

  download(i: number) {
    if (this.documents[i]["id"].toLowerCase() === "photo_of_the_week") {
      const downloadRef = this.storage.ref(this.documents[i]["location"]);
      downloadRef.getDownloadURL().subscribe((url) => {
        window.open(url, "_blank");
      });
    } else if (this.documents[i]["type"].toLowerCase() === "url") {
      window.open(this.documents[i]["location"], "_blank");
    } else {
      const downloadRef = this.storage.ref(this.documents[i]["location"]);
      downloadRef.getDownloadURL().subscribe((url) => {
        window.open(url, "_blank");
      });
    }
  }

  getLinkedPdfFullPath(location: string) {
    return `Schools/${this.selectedId}/Documents/${location}`;
  }

  getImgUrl(i: number) {
    return this.images[`${i}`];
  }

  isEditingPdf() {
    return this.editing["type"] === "pdf" ? true : false;
  }

  isEditingUrl() {
    return this.editing["type"] === "url" ? true : false;
  }

  isEditingLinkedPdf() {
    return this.editing["type"] === "linked-pdf" ? true : false;
  }

  isEditing() {
    return this.editingIndex === undefined ? false : true;
  }

  resetDocument() {
    this.editingIndex = undefined;
    this.editing = {
      id: "",
      type: DocumentTypes.url,
      location: "",
      icon: "",
      title: "",
      item_location: "list",
      item_position: null,
      category: DocumentCategories.OTHER,
      accessRoles: this.userRoles,
      downloadOnLogin: false,
      display: true,
    };
    this.setItemPosition();
  }

  edit(i: number) {
    this.editingIndex = i;
    const selectedDocument = this.documents[i];
    this.editing = {
      ...selectedDocument,
      type: selectedDocument["type"],
      location: selectedDocument["location"],
      icon: selectedDocument["icon"],
      title: selectedDocument["title"],
      item_location: selectedDocument["item_location"] ? selectedDocument["item_location"] : "list",
      category: selectedDocument["category"],
      accessRoles: selectedDocument["accessRoles"] === undefined ? [] : selectedDocument["accessRoles"],
      downloadOnLogin: selectedDocument["downloadOnLogin"],
      display: selectedDocument["display"],
    };
    if (selectedDocument["item_position"]) {
      this.editing = { ...this.editing, item_position: selectedDocument["item_position"] };
    } else {
      this.setItemPosition();
    }
    window.scroll(0, 0);
  }

  setItemPosition() {
    const currentHighestPositionDoc = this.findDocumentWithHighestPosition();
    if (
      currentHighestPositionDoc?.item_position !== undefined &&
      typeof currentHighestPositionDoc?.item_position === "number"
    ) {
      if (this.isHoaVillage) {
        this.editing = { ...this.editing, item_position: currentHighestPositionDoc["item_position"] };
      } else {
        this.editing = { ...this.editing, item_position: currentHighestPositionDoc["item_position"] + 1 };
      }
    }
  }

  async onSubmit(form: NgForm) {
    try {
      this.loading = true;
      let successMessage: string;
      if (!this.editing["item_position"]) {
        this.setItemPosition();
      }
      if (!this.editing["item_location"]) {
        this.editing["item_location"] = "list";
      }
      if (this.editingIndex !== undefined) {
        this.documents[this.editingIndex] = this.editing;
        successMessage = "Great, document has been updated successfully!";
      } else {
        this.documents.push(this.editing);
        successMessage = "Great, new document has been added successfully!";
      }
      if (this.isHoaVillage) {
        this.updatePositionOfSupportAndTutorial();
      }
      await this.saveUpdatedOrder([...this.documents]);
      form.reset();
      form.control.patchValue({
        type: "url",
        location: "",
        icon: "",
        title: "",
        category: "",
        item_location: "list",
        item_position: null,
        accessRoles: this.userRoles,
        downloadOnLogin: false,
        display: true,
      });
      this.setItemPosition();
      this.toastr.success(successMessage);
      this.loading = false;
    } catch (error) {
      console.error("error", error);
      this.toastr.error(`Oops, something went wrong while adding documents. Please try again!`);
      this.loading = false;
    }
  }

  findDocumentWithHighestPosition() {
    const highestPositionedDocument = this.documents?.reduce((highest: ISchoolDocument, current: ISchoolDocument) => {
      if (current.item_position > highest.item_position) {
        return current;
      } else {
        return highest;
      }
    });
    return highestPositionedDocument;
  }

  /** we update item_position of the support_and_tutorial document to be last of all documents to place it at last of mobile app */
  updatePositionOfSupportAndTutorial() {
    try {
      const supportAndTutorialDocIndex = this.documents?.findIndex((item) => item.id === "support_and_tutorial");
      if (supportAndTutorialDocIndex > -1 && this.documents[supportAndTutorialDocIndex]) {
        this.documents[supportAndTutorialDocIndex]["item_position"] =
          this.documents[supportAndTutorialDocIndex]["item_position"] + 1;
      }
    } catch {}
  }

  get isMarinaVillage() {
    return this.strings.villageType === VillageType.MARINA;
  }

  get isHoaVillage() {
    return this.strings.villageType === VillageType.HOA;
  }

  get isSchoolVillage() {
    return this.strings.villageType === VillageType.SCHOOL;
  }

  ngOnDestroy(): void {}
}

<style type="text/css">
  .cal-month-view .cal-day-badge {
    display: none;
  }
</style>

<section class="content">
  <app-school-detail-page-header [schoolData]="schoolData"></app-school-detail-page-header>
  <h3>Visitors Log</h3>
  <div class="row">
    <div class="col-md-6">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          Previous
        </div>
        <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">Today</div>
        <div
          class="btn btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          Next
        </div>
      </div>
    </div>
    <div class="col-md-6 right">
      <h3 style="text-align: right">{{ viewDate | calendarDate : view + "ViewTitle" : "en" }}</h3>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-12">
      <mwl-calendar-month-view
        [(events)]="events"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent($event.event)"
        [viewDate]="viewDate"
      >
      </mwl-calendar-month-view>
    </div>
  </div>
  <div class="widget-lists card-columns"></div>
</section>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";

import { SchoolComponent } from "./school.component";
import { SchoolUsersComponent } from "./users/school-users.component";
import { SchoolLinkedPDFsComponent } from "./linked-pdfs/school-linked-pdfs.component";
import { SchoolUserDetailComponent } from "./users/user-detail/user.component";
import { SchoolGroupsComponent } from "./groups/school-groups.component";
import { SchoolDocumentsComponent } from "./documents/school-documents.component";
import { SchoolAssetsComponent } from "./assets/school-assets.component";
import { SchoolIncidentsComponent } from "./incidents/school-incidents.component";
import { UserCreateComponent } from "./users/user-create/school-user-create.component";
import { UserBulkComponent } from "./users/user-bulk/school-user-bulk.component";
import { SchoolReportsComponent } from "./reports/school-reports.component";
import { SchoolExplorerComponent } from "./explorer/school-explorer.component";
import { ExplorerAlertsComponent } from "./explorer/alerts/explorer-alerts.component";
import { ExplorerHotlineComponent } from "./explorer/hotline/explorer-hotline.component";
import { ExplorerBroadcastsComponent } from "./explorer/broadcasts/explorer-broadcasts.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AutocompleteLibModule } from "angular-ng-autocomplete";

import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { SchoolChannelsComponent } from "./channels/school-channels.component";
import { SchoolRegionComponent } from "./region/region.component";
import { NewSchoolComponent } from "./new-school/new-school.component";
import { SharedModule } from "app/shared/shared.module";
import { BulkVillageUploadComponent } from "../home/bulk-village-upload/bulk-village-upload.component";
import { MatTableModule } from "@angular/material/table";
import { CdkTableModule } from "@angular/cdk/table";
import { IsMySchoolGuard } from "app/core/guards/is-my-school.guard";
import { IsOwnerGuard } from "app/core/guards/is-owner.guard";
import { VisitorsComponent } from "./visitors/visitors.component";
import { SingleDateVisitorsComponentModal } from "./visitors/single-date-visitors/single-date-visitors.component";
import { VillageType } from "common/typings";
import { BoatComponent } from "./users/boat/boat.component";
import { SchoolSettingsComponent } from "./settings/settings.component";
import { NewYachtComponent } from "./new-yacht/new-yacht.component";
import { SchoolSopsComponent } from "./sops/sops.component";
import { HoaMembersComponent } from "./users/hoa-members/hoa-members.component";
import { EventsCalendarComponent } from "./events-calendar/events-calendar.component";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";

export let maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

const HOME_ROUTE = [
  {
    path: "bulk-upload",
    canActivate: [IsOwnerGuard],
    data: {
      villages: [VillageType.SCHOOL, VillageType.MARINA, VillageType.HOA],
    },
    component: BulkVillageUploadComponent,
  },
  {
    path: "bulk-yacht-upload",
    canActivate: [IsOwnerGuard],
    data: {
      villages: [VillageType.MARINA],
    },
    component: BulkVillageUploadComponent,
  },
  {
    path: "new",
    canActivate: [IsOwnerGuard],
    data: {
      villages: [VillageType.SCHOOL, VillageType.MARINA, VillageType.HOA],
    },
    component: NewSchoolComponent,
  },
  {
    path: "new-yacht",
    canActivate: [IsOwnerGuard],
    data: {
      villages: [VillageType.MARINA],
    },
    component: NewYachtComponent,
  },
  {
    path: "edit-yacht/:id",
    canActivate: [IsOwnerGuard],
    data: {
      villages: [VillageType.MARINA],
    },
    component: NewYachtComponent,
  },
  {
    path: "edit/:id",
    canActivate: [IsMySchoolGuard],
    data: {
      villages: [VillageType.SCHOOL, VillageType.MARINA, VillageType.HOA],
    },
    component: NewSchoolComponent,
  },
  {
    path: ":id",
    canActivate: [IsMySchoolGuard],
    data: {
      villages: [VillageType.SCHOOL, VillageType.MARINA, VillageType.HOA],
    },
    children: [
      { path: "", component: SchoolComponent },
      { path: "users", component: SchoolUsersComponent },
      { path: "create-user", component: UserCreateComponent },
      { path: "create-user-bulk", component: UserBulkComponent },
      { path: "groups", component: SchoolGroupsComponent },
      { path: "sops", component: SchoolSopsComponent },
      { path: "documents", component: SchoolDocumentsComponent },
      { path: "assets", component: SchoolAssetsComponent },
      { path: "reports", component: SchoolReportsComponent },
      { path: "users/:userId", component: SchoolUserDetailComponent },
      { path: "explorer", component: SchoolExplorerComponent },
      { path: "settings", component: SchoolSettingsComponent },
      { path: "incidents", component: SchoolIncidentsComponent },
      { path: "linked-pdfs", component: SchoolLinkedPDFsComponent },
      { path: "channels", component: SchoolChannelsComponent },
      { path: "region", component: SchoolRegionComponent },
      { path: "visitors", component: VisitorsComponent },
      { path: "events", component: EventsCalendarComponent },
    ],
  },
];

@NgModule({
  declarations: [
    NewSchoolComponent,
    SchoolComponent,
    SchoolUsersComponent,
    SchoolGroupsComponent,
    UserCreateComponent,
    UserBulkComponent,
    SchoolUserDetailComponent,
    SchoolDocumentsComponent,
    SchoolAssetsComponent,
    SchoolReportsComponent,
    SchoolExplorerComponent,
    SchoolIncidentsComponent,
    SchoolSettingsComponent,
    ExplorerAlertsComponent,
    ExplorerHotlineComponent,
    ExplorerBroadcastsComponent,
    SchoolLinkedPDFsComponent,
    SchoolChannelsComponent,
    SchoolRegionComponent,
    BulkVillageUploadComponent,
    SingleDateVisitorsComponentModal,
    VisitorsComponent,
    BoatComponent,
    SchoolSettingsComponent,
    NewYachtComponent,
    SchoolSopsComponent,
    HoaMembersComponent,
    EventsCalendarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    AutocompleteLibModule,
    MatTableModule,
    CdkTableModule,
    NgxDatatableModule,
    BsDropdownModule.forRoot(),
    RouterModule.forChild(HOME_ROUTE),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMaskModule.forRoot(maskOptions),
    SharedModule,
    NgbModalModule,
  ],
  providers: [BsModalService],
  exports: [SingleDateVisitorsComponentModal],
})
export class SchoolModule {}

import { Injectable } from "@angular/core";
import { DocumentData } from "@angular/fire/compat/firestore";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ComponentDataInteractService {
  currentUserData = new BehaviorSubject<DocumentData>(null);
  allSchoolIds = new BehaviorSubject<string[]>([]);
  isOwner$ = new BehaviorSubject<boolean>(false);
  constructor() {}

  setCurrentUserData(userData: DocumentData) {
    this.currentUserData.next(userData);
  }

  getCurrentUserData() {
    return this.currentUserData.asObservable();
  }
}

<section class="content">
  <app-school-detail-page-header [schoolData]="schoolData"></app-school-detail-page-header>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="fileupload" *ngIf="!loading">Select a file to upload</label>
        <label for="fileupload" *ngIf="loading"
          >Uploading
          <div>{{ uploadPercent | async }}%</div></label
        >
        <input type="file" class="form-control" (change)="uploadFile($event)" *ngIf="!loading" />
      </div>
      <p>If you are updating a file, please use a different name and relink it under "Documents"</p>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label for="fileupload" *ngIf="!loading">Select a folder to upload</label>
        <label for="fileupload" *ngIf="loading"
          >Uploading
          <div>{{ uploadedCount }}/{{ uploadingCount }}</div></label
        >
        <input
          type="file"
          class="form-control"
          (change)="uploadFolder($event)"
          *ngIf="!loading"
          webkitdirectory
          data-buttonText="Select Folder"
        />
      </div>
    </div>
  </div>

  <div class="row" *ngIf="filesToUpload.length > 0 && !loading">
    <div class="col-md-6">
      <h3>Select your root file</h3>
    </div>

    <div class="col-md-12" *ngFor="let item of filesToUpload">
      <div class="card" style="cursor: pointer">
        <div class="card-body">
          <h4 class="card-title">{{ item.name }}</h4>
          <h6 class="card-subtitle">{{ item.webkitRelativePath }}</h6>
          <a (click)="setAsRoot(item)" class="card-link"
            ><i class="zmdi zmdi-home"></i> &nbsp;&nbsp;Set as Root and Begin Upload</a
          >
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="filesToUpload.length > 0">
    <div class="col-md-6">
      <h3>Uploaded Files</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6" *ngFor="let item of linkedPdfs | async">
      <div class="card" style="cursor: pointer">
        <div class="card-body">
          <h4 class="card-title">{{ item.name }} <small style="color: grey">Linked Folder</small></h4>
          <h6 class="card-subtitle">Root: {{ item.root }}</h6>
          <a (click)="deleteFolder(item)" class="card-link" style="color: red"
            ><i class="zmdi zmdi-delete"></i> &nbsp;&nbsp;DELETE</a
          >
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngFor="let item of items">
      <div class="card" style="cursor: pointer">
        <div class="card-body">
          <h4 class="card-title">{{ item.name }}</h4>
          <h6 class="card-subtitle">{{ item.fullPath }}</h6>
          <a (click)="download(item)" class="card-link"><i class="zmdi zmdi-download"></i> &nbsp;&nbsp;Download</a>
          <a (click)="deleteItem(item)" class="card-link" style="color: red"
            ><i class="zmdi zmdi-delete"></i> &nbsp;&nbsp;DELETE</a
          >
        </div>
      </div>
    </div>
  </div>

  <div class="widget-lists card-columns"></div>
</section>

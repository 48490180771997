<div class="section">
  <form (ngSubmit)="onAdd()" #boatForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Add Boat</h4>
      <button
        type="button"
        class="close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="boatName">Boat Name<span class="required-field"></span></label>
        <input
          type="text"
          class="form-control"
          id="boatName"
          [(ngModel)]="boatModel.boatName"
          name="boatName"
          required
          #boatName="ngModel"
        />
        <div [hidden]="boatName.valid || boatName.pristine" class="alert alert-danger">Boat Name is required</div>
      </div>

      <div class="form-group">
        <label for="boatLocationType"
          >Location (Select one format and enter location of your boat) <span class="required-field"></span
        ></label>
        <div class="form-check">
          <input
            class="form-check-input"
            [(ngModel)]="boatModel.boatLocationType"
            value="dockSlip"
            required
            name="boatLocationType"
            type="radio"
            name="boatLocationType"
            id="dockSlip"
          />
          <label class="form-check-label" for="dockSlip"> Dock Slip </label>
        </div>
        <div *ngIf="boatModel.boatLocationType === 'dockSlip'" class="mt-4">
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="dockNumber">Dock<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="dockNumber"
                [(ngModel)]="locationOptions.dockNumber"
                name="dockNumber"
                placeholder="Sample: Dock C"
                required
                #dockNumber="ngModel"
              />
              <div [hidden]="dockNumber.valid || dockNumber.pristine" class="alert alert-danger">Dock is required</div>
            </div>
          </div>
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="slipNumber">Slip<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="slipNumber"
                [(ngModel)]="locationOptions.slipNumber"
                name="slipNumber"
                placeholder="Sample: Slip 27"
                required
                #slipNumber="ngModel"
              />
              <div [hidden]="slipNumber.valid || slipNumber.pristine" class="alert alert-danger">Slip is required</div>
            </div>
          </div>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            [(ngModel)]="boatModel.boatLocationType"
            value="mooring"
            name="boatLocationType"
            type="radio"
            name="boatLocationType"
            id="mooring"
            checked
          />
          <label class="form-check-label" for="mooring"> Mooring </label>
        </div>
        <div *ngIf="boatModel.boatLocationType === 'mooring'" class="mt-4">
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="mooringInput">Mooring<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="mooringInput"
                [(ngModel)]="locationOptions.mooring"
                placeholder="Sample: Mooring #G23"
                name="mooring"
                required
                #mooring="ngModel"
              />
              <div [hidden]="mooring.valid || mooring.pristine" class="alert alert-danger">Mooring is required</div>
            </div>
          </div>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            value="address"
            [(ngModel)]="boatModel.boatLocationType"
            name="boatLocationType"
            type="radio"
            name="boatLocationType"
            id="address"
            checked
          />
          <label class="form-check-label" for="address"> Address </label>
        </div>
        <div *ngIf="boatModel.boatLocationType === 'address'" class="mt-4">
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="street">Street<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="street"
                [(ngModel)]="locationOptions.street"
                name="street"
                placeholder="eg. 22222 Street Ave. "
                required
                #street="ngModel"
              />
              <div [hidden]="street.valid || street.pristine" class="alert alert-danger">Street is required</div>
            </div>
          </div>
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="city">City<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="city"
                [(ngModel)]="locationOptions.city"
                name="city"
                placeholder="City "
                required
                #city="ngModel"
              />
              <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
            </div>
          </div>
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="state">State<span class="required-field"></span></label>
              <select
                class="form-select form-control"
                id="state"
                [(ngModel)]="locationOptions.state"
                name="state"
                placeholder="State "
                required
                #state="ngModel"
              >
                <option value="{{ state?.state }}" *ngFor="let state of usStateList">
                  {{ state?.state }}
                </option>
              </select>
              <div [hidden]="state.valid || state.pristine" class="alert alert-danger">State is required</div>
            </div>
          </div>
          <div class="form-check form-check-inline">
            <div class="form-group">
              <label for="zip">Zip<span class="required-field"></span></label>
              <input
                type="text"
                class="form-control"
                id="zip"
                [(ngModel)]="locationOptions.zip"
                name="zip"
                placeholder="Zip"
                required
                #zip="ngModel"
              />
              <div [hidden]="zip.valid || zip.pristine" class="alert alert-danger">Zip is required</div>
            </div>
          </div>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            value="others"
            [(ngModel)]="boatModel.boatLocationType"
            name="boatLocationType"
            type="radio"
            id="others"
            checked
          />
          <label class="form-check-label" for="others"> Others -- Description </label>
        </div>
        <div *ngIf="boatModel.boatLocationType === 'others'" class="mt-4">
          <div class="form-check">
            <div class="form-group">
              <textarea
                class="form-control"
                id="description"
                [(ngModel)]="locationOptions.description"
                name="description"
                placeholder="Provide description if none of the above formats apply"
                required
                #description="ngModel"
                rows="5"
              ></textarea>
              <div [hidden]="description.valid || description.pristine" class="alert alert-danger">
                Description is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="boatModel.schools.length > 0">
        <h6>Selected {{ strings.villageType }}(s)</h6>
        <p *ngFor="let school of boatModel.schools; let i = index">
          <span>{{ i + 1 }}.{{ school?.name }}</span>
          <a class="cursor-pointer ml-4 text-danger" (click)="removeSelectedSchool(i)"
            >Remove To Select Another {{ strings.villageType }}</a
          >
        </p>
      </div>
      <div *ngIf="showSchoolOptions && boatModel.schools?.length === 0" class="form-group">
        <label>Select {{ strings.villageType }}(s) <span class="required-field"></span></label>
        <div class="ng-autocomplete">
          <ng-autocomplete
            #ngAutoComplete
            [data]="schoolsOptions"
            searchKeyword="name"
            (selected)="villageSelectEvent($event)"
            placeholder="{{ getPlaceholder }}"
            (inputChanged)="onChangeSearch($event)"
            (inputFocused)="onFocused($event)"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
          >
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        ngbAutofocus
        class="btn btn-danger"
        [disabled]="boatForm.invalid || (boatModel.schools.length < 1 && showSchoolOptions)"
        (click)="onAdd()"
      >
        Add
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss(false)">Cancel</button>
    </div>
  </form>
</div>

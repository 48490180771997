<section class="content">
  <app-page-header [schoolData]="schoolData"></app-page-header>
  <div class="row">
    <div class="col-6">
      <div class="card" style="cursor: pointer">
        <div class="card-body">
          <h5>Alert Notification Settings</h5>
          <div *ngFor="let role of allRoles" class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="{{ role }}"
              name="{{ role }}"
              (change)="onCheckBoxChange($event, role)"
              [checked]="selectedAlertReceivingRoles[role] === true"
            />
            <label class="form-check-label">{{ role }}</label>
          </div>
          <button type="button" class="btn btn-success mt-4" (click)="updateAlertSettings()">Save</button>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card" style="cursor: pointer">
        <div class="card-body">
          <h5>911 Notifications Delivery settings</h5>
          <p class="mt-0">
            <span
              style="font-weight: 900; font-size: 14px"
              [ngStyle]="{ color: schoolData?.isTraining ? 'red' : 'green' }"
            >
              {{ schoolData?.name }} is currently in
              <span style="font-weight: 900; font-size: 18px">{{
                schoolData?.isTraining ? "Test Mode" : "Live Mode"
              }}</span
              >.</span
            >
            <span class="ml-2"
              >Thus, 911 notifications are {{ schoolData?.isTraining ? "disabled" : "enabled" }}. Please, check/uncheck
              and click Save button to toggle the Test Mode.
            </span>
          </p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="isTraining"
              name="isTraining"
              (change)="onTrainingCheckBoxChange($event)"
              [checked]="isTraining === true"
            />
            <label class="form-check-label">Test Mode</label>
          </div>
          <button type="button" class="btn btn-success mt-4" (click)="updateIsTrainingSettings()">Save</button>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card" style="cursor: pointer">
        <div class="card-body">
          <h5>Email Notifications of Alert Settings</h5>
          <p class="mt-0">
            <span style="font-weight: 900; font-size: 16px"> {{ schoolData?.name }} currently </span>
            <span
              style="font-weight: 900; font-size: 18px"
              [ngStyle]="{ color: schoolData?.sendAlertEmailNotification ? 'green' : 'red' }"
              >{{
                schoolData?.sendAlertEmailNotification ? "sends Email on Alert" : "does not send Email on Alert"
              }}.</span
            >
            <span class="ml-2"
              >i.e, email notifications on alert is
              {{ schoolData?.sendAlertEmailNotification ? "enabled" : "disabled" }}. Please, check/uncheck and click
              Save button to toggle the email notification on Alert.
            </span>
          </p>
          <ul *ngIf="schoolData?.sendAlertEmailNotification" style="list-style-type: none">
            <li>We are sending emails notification to following emails:</li>
            <li *ngFor="let email of recipientEmails; let eInx = index">{{ eInx + 1 }}. {{ email }}</li>
          </ul>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="sendAlertEmailNotification"
              name="sendAlertEmailNotification"
              (change)="onSendAlertEmailNotificationChange($event)"
              [checked]="sendAlertEmailNotification === true"
            />
            <label class="form-check-label">Enable Email Notification Alert</label>
          </div>
          <button type="button" class="btn btn-success mt-4" (click)="updateSendAlertEmailNotificationSettings()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

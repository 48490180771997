import { Component, ViewChild, TemplateRef, OnInit } from "@angular/core";
import { endOfDay, isSameDay, isSameMonth } from "date-fns";
import { CalendarEvent, CalendarView } from "angular-calendar";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Observable } from "rxjs";
import { CollectionNames } from "app/shared/generic_variables";

const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-school-reports",
  templateUrl: "./school-reports.component.html",
  styles: [""],
})
export class SchoolReportsComponent implements OnInit {
  @ViewChild("modalContent") modalContent: TemplateRef<any>;
  selectedId = "";
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  loading = false;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  activeDayIsOpen = false;

  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private storage: AngularFireStorage
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      const schoolRef = this.db.doc("schools/" + this.selectedId);
      this.school = schoolRef.valueChanges();
      this.school.subscribe((scl) => {
        this.schoolData = scl;
      });
      const reportsRef = db
        .collection(CollectionNames.schools)
        .doc(this.selectedId)
        .collection(CollectionNames.dailyReports, (ref) => ref.orderBy("reportDate", "desc"));
      reportsRef.valueChanges().subscribe((dailyReports: DocumentData[]) => {
        this.events = [];
        dailyReports.forEach((dailyReport) => {
          const reportDate = Date.parse(dailyReport["reportDate"]) + 1000 * 60 * 60 * 24;
          this.events.push(<CalendarEvent>{
            start: endOfDay(reportDate),
            end: endOfDay(reportDate),
            title: "Download Daily Report for " + dailyReport["reportDate"],
            color: colors.blue,
            draggable: false,
            meta: {
              report: dailyReport,
              incrementsBadgeTotal: false,
            },
          });
        });
      });
    });
  }

  ngOnInit() {}

  handleEvent(event: CalendarEvent): void {
    const downloadRef = this.storage.ref(event.meta.report.location);
    downloadRef.getDownloadURL().subscribe((url) => {
      window.open(url, "_blank");
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}

<section class="content">
  <app-page-header></app-page-header>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4>Edit Region</h4>
          <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
            <div class="form-group">
              <label for="region">Region</label>
              <select
                class="form-control"
                id="region"
                required
                [(ngModel)]="model.region"
                name="region"
                #region="ngModel"
              >
                <option *ngFor="let region of allRegions" [value]="region.id">
                  {{ region.name }}
                </option>
              </select>
              <div [hidden]="region.valid || region.pristine" class="alert alert-danger">Region is required</div>
            </div>
            <button type="submit" class="btn btn-success" [disabled]="!heroForm.form.valid || loading">
              {{ loading ? "Updating.." : "Update" }}
            </button>
            &nbsp;&nbsp;
            <button type="button" class="btn btn-danger" (click)="heroForm.reset()" [hidden]="loading">Reset</button>
            <br /><br /><br /><br />
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, DocumentChangeAction, DocumentData } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import firebase from "firebase/compat/app";
import { uniqBy } from "lodash";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UserService } from "app//core/services/user.service";
import { AllUserRoles, CollectionNames, HoaUnitTypes } from "app/shared/generic_variables";
import { getBoatLocation } from "app/utils/school.util";
import { VillageType } from "common/typings";
import { AllAlerts, strings } from "environments/environment";
import { Roles } from "environments/environment";

import { BoatComponent } from "../boat/boat.component";

@Component({
  selector: "app-school-users-create",
  templateUrl: "./school-user-create.component.html",
})
export class UserCreateComponent implements OnInit {
  items = [];
  selectedId = "";
  school: Observable<DocumentData>;
  loading = false;
  error = "";
  showNoStudentsError = false;

  boatLocationOptions = {
    dockNumber: "",
    slipNumber: "",
    mooring: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    description: "",
  };

  model = {
    firstName: "",
    lastName: "",
    company: "",
    allowBillingResponsibility: false,
    allowAdminUIPermission: false,
    email: "",
    phone: "",
    officePhone: "",
    phone_extension: "",
    role: "",
    schoolId: "",
    schoolName: "",
    boatName: "",
    boatLocationType: "",
    boatLocation: {},
    location: {},
    age: "",
    unitType: "",
  };

  userLocationOptions = {
    address: "",
    city: "",
    state: "",
    unit: "",
    zip: "",
    county: "",
  };

  submitted = false;

  // roles = Roles.filter((r) => ![AllUserRoles.tenant, AllUserRoles.family, AllUserRoles.tenant_family].includes(r));
  roles = Roles.filter((r) => ![AllUserRoles.tenant, AllUserRoles.tenant_family].includes(r));
  AllAlerts = AllAlerts;
  usStateList = [];

  userAlerts = {};
  allGroups = [];
  userGroups = {};
  strings = strings;
  marinaVillageType = VillageType.MARINA;
  allSchoolStudentUsers: DocumentData[] = [];
  selectedStudentsToAdd: DocumentData[] = [];
  currentStudentToAdd: DocumentData;
  defaultAlertSelectionOption: "all" | "none" | "selectable";
  schoolData: DocumentData;
  selectedStateCounties = [];

  additionalBoats: DocumentData[] = [];

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private userService: UserService,
    private _modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      this.model.schoolId = this.selectedId;
      const schoolRef = db.doc("schools/" + this.selectedId);
      this.school = schoolRef.valueChanges();
      this.school.subscribe((_school) => {
        this.schoolData = _school;
        this.model.schoolName = this.schoolData?.name ? this.schoolData?.name : "";
        this.allGroups = _school.groups ? Object.keys(_school.groups) : null;
      });
      if (this.isSchool) this.getAllStudents();
    });
  }

  ngOnInit() {
    this.getAllStates();
  }

  prePopulateUserLocations() {
    const { city, state, zip, unit, county } = this.schoolData;
    this.userLocationOptions = {
      address: "",
      city: city ? city : "",
      county: county ? county : "",
      state: state ? state : "",
      zip: zip ? zip : "",
      unit: unit ? unit : "",
    };
    this.onStateSelection(false);
  }

  getAllStates() {
    this.db
      .collection(CollectionNames.states)
      .valueChanges()
      .subscribe((states) => {
        if (states) this.usStateList = states;
        this.prePopulateUserLocations();
      });
  }

  getAllStudents() {
    const queryKey = "associatedSchools." + this.selectedId + ".role";
    this.db
      .collection(CollectionNames.users, (ref) => ref.where(queryKey, "==", AllUserRoles.student))
      .snapshotChanges()
      .pipe(
        map((documents: DocumentChangeAction<DocumentData>[]) =>
          documents.map((userSnap) => {
            const userData = userSnap.payload.doc.data();
            const name =
              (userData.firstName ? userData.firstName : "") + " " + (userData.lastName ? userData.lastName : "");
            return { id: userSnap.payload.doc["id"], ...userData, name: name };
          })
        )
      )
      .subscribe((users) => {
        this.allSchoolStudentUsers = users;
      });
  }

  onRoleChange(role: AllUserRoles) {
    if (this.isMarina) {
      this.selectAllGroups();
      this.selectAllAlerts();
      this.defaultAlertSelectionOption = "all";
    } else if (this.isSchool) {
      switch (role) {
        case AllUserRoles.admin:
        case AllUserRoles.security:
        case AllUserRoles.district:
        case AllUserRoles.maintenance:
        case AllUserRoles.pdFireEms:
          this.selectAllGroups();
          this.selectAllAlerts();
          this.defaultAlertSelectionOption = "all";
          break;
        case AllUserRoles.staff:
        case AllUserRoles.student:
        case AllUserRoles.family:
          this.selectNoAlerts();
          this.selectOnlyGroup(role);
          this.defaultAlertSelectionOption = "none";
          break;
        default:
          this.defaultAlertSelectionOption = "none";
          this.selectNoAlerts();
          this.selectOnlyGroup(role);
          break;
      }
    } else if (this.isHoa) {
      this.selectAllGroups();
      this.selectAllAlerts();
      this.defaultAlertSelectionOption = "all";
    }
  }

  selectAllGroups() {
    this.userGroups = {};
    this.allGroups.forEach((group) => {
      this.userGroups[group] = true;
    });
  }

  selectAllAlerts() {
    this.userAlerts = {};
    this.AllAlerts.forEach((alert) => {
      this.userAlerts[alert.key] = true;
    });
  }

  selectNoAlerts() {
    this.userAlerts = {};
  }

  selectNoGroups() {
    this.userGroups = {};
  }

  selectOnlyGroup(group: string) {
    this.userGroups = {};
    this.userGroups[group] = true;
  }

  getTrueGroups() {
    if (this.userGroups == null) {
      return {};
    }
    const cleanGroups = this.userGroups;
    const keys = Object.keys(cleanGroups);
    for (const key of keys) {
      if (cleanGroups[key] === false) {
        cleanGroups[key] = firebase.firestore.FieldValue.delete();
      }
    }
    return cleanGroups;
  }

  getTrueAlerts() {
    if (this.userAlerts == null) {
      return {};
    }
    const cleanAlerts = this.userAlerts;
    const keys = Object.keys(cleanAlerts);
    for (const key of keys) {
      if (cleanAlerts[key] === false) {
        cleanAlerts[key] = firebase.firestore.FieldValue.delete();
      }
    }
    return cleanAlerts;
  }

  formatRole(verificationStatus: string) {
    return verificationStatus.replace(/_/g, " ");
  }

  onSubmit() {
    if (this.isSchool && this.isSelectedRoleFamilyType && this.selectedStudentsToAdd.length < 1) {
      this.showNoStudentsError = true;
      return;
    }
    try {
      this.loading = true;
      this.showNoStudentsError = false;
      this.model["groups"] = this.getTrueGroups();
      this.model["alerts"] = this.getTrueAlerts();
      if (this.isMarina) {
        this.model["boatLocation"] = getBoatLocation(this.model.boatLocationType, this.boatLocationOptions);
      }
      if (this.isHoa) {
        this.model["location"] = this.getUserLocation;
      }
      let postData = this.getPostData;
      postData["groups"] = this.getTrueGroups();
      postData["alerts"] = this.getTrueAlerts();
      postData["additionalBoats"] = this.additionalBoats;
      this.userService.postUser(postData).subscribe(
        (response: any) => {
          const message = response.message
            ? response.message
            : `Great, user ${postData.email} has been successfully added to ${this.schoolData?.name} as ${postData.role} role!`;
          this.toastr.success(message);
          this.loading = false;
          this.router.navigateByUrl(`app/schools/${this.selectedId}/users`);
        },
        (error) => {
          this.loading = false;
          this.error = error;
          this.toastr.error(`Oops, Something went wrong while adding the user. Please try again!`);
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  get getStudents(): string[] {
    let students: string[] = [];
    this.selectedStudentsToAdd.forEach((stu) => {
      students = [...students, stu.id];
    });
    return students;
  }

  get getPostData(): DocumentData {
    const {
      firstName,
      lastName,
      email,
      phone,
      officePhone,
      phone_extension,
      role,
      schoolId,
      boatName,
      boatLocationType,
      boatLocation,
      age,
      unitType,
      location,
      company,
      allowBillingResponsibility,
      allowAdminUIPermission,
    } = this.model;
    let returnData: DocumentData = {
      firstName,
      lastName,
      email,
      phone,
      officePhone,
      phone_extension,
      role,
      schoolId,
      boatName,
      boatLocationType,
      boatLocation,
      age,
      unitType,
      location,
      company,
      allowBillingResponsibility,
      allowAdminUIPermission,
    };
    if (this.isMarina) {
      returnData = {
        firstName,
        lastName,
        email,
        phone,
        officePhone,
        phone_extension,
        role,
        schoolId,
        boatName,
        boatLocationType,
        boatLocation,
        company,
        allowBillingResponsibility,
        allowAdminUIPermission,
      };
    } else if (this.isSchool) {
      returnData = {
        firstName,
        lastName,
        email,
        phone,
        officePhone,
        phone_extension,
        role,
        schoolId,
        company,
        allowBillingResponsibility,
        allowAdminUIPermission,
      };
      if (this.isSelectedRoleFamilyType) returnData = { ...returnData, students: this.getStudents };
    } else if (this.isHoa) {
      returnData = {
        firstName,
        lastName,
        email,
        phone,
        officePhone,
        phone_extension,
        role,
        schoolId,
        age,
        unitType,
        location,
        allowBillingResponsibility,
        allowAdminUIPermission,
        company,
      };
    }
    return returnData;
  }

  private get getUserLocation() {
    const { address, city, state, zip, unit, county } = this.userLocationOptions;
    return {
      address: address ? address : "",
      city: city ? city : "",
      county: county ? county : "",
      state: state ? state : "",
      zip: zip ? zip : "",
      unit: unit ? unit : "",
    };
  }

  openRemoveStudentModal(student: DocumentData) {
    this.selectedStudentsToAdd = this.selectedStudentsToAdd.filter((item) => item.id !== student.id);
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  addStudent() {
    const toAddStudent = this.currentStudentToAdd;
    if (toAddStudent && typeof toAddStudent === "object" && Object.keys(toAddStudent).length > 0) {
      this.selectedStudentsToAdd = uniqBy([...this.selectedStudentsToAdd, toAddStudent], "id");
    }
    this.currentStudentToAdd = null;
  }

  addAnotherBoat() {
    const modalRef = this._modalService.open(BoatComponent, { size: "lg" });
    modalRef.result.then((res) => {
      if (res) {
        const { added, boatData } = res;
        if (added) {
          this.additionalBoats = [...this.additionalBoats, boatData];
        }
      }
    });
  }

  removeAdditionalBoat(index: number) {
    this.additionalBoats.splice(index, 1);
  }

  onFocused(e) {
    // do something when input is focused
  }

  onStateSelection(resetCounty = true) {
    if (this.userLocationOptions.state) {
      if (resetCounty) {
        this.userLocationOptions.county = "";
      }
      this.selectedStateCounties = this.usStateList.find(
        (item) => item.state === this.userLocationOptions.state
      )?.counties;
    } else {
      this.selectedStateCounties = [];
    }
  }

  get isSchool() {
    return this.strings.villageType === VillageType.SCHOOL;
  }

  get isMarina() {
    return this.strings.villageType === VillageType.MARINA;
  }

  get isHoa() {
    return strings.villageType === VillageType.HOA;
  }

  get showAgeField() {
    return (
      strings.villageType === VillageType.HOA &&
      (this.model.role === AllUserRoles.family || this.model.role === AllUserRoles.tenant_family)
    );
  }

  get showUnitType() {
    return (
      strings.villageType === VillageType.HOA &&
      (this.model.role === AllUserRoles.unit_owner || this.model.role === AllUserRoles.unit_owner_admin)
    );
  }

  get HoaUnitTypes() {
    return HoaUnitTypes;
  }

  get showOfficePhoneNumberField() {
    return !(this.model.role == AllUserRoles.unit_owner || this.model.role == AllUserRoles.unit_owner_admin);
  }

  get showOfficePhoneExtensionField() {
    return !(this.model.role == AllUserRoles.unit_owner || this.model.role == AllUserRoles.unit_owner_admin);
  }

  get showBoaterFields() {
    return this.isMarina && this.model.role === AllUserRoles.boater;
  }

  get showUserLocationFields() {
    return (
      this.isHoa &&
      (this.model.role === AllUserRoles.unit_owner ||
        this.model.role === AllUserRoles.unit_owner_admin ||
        this.model.role === AllUserRoles.vendor)
    );
  }

  get isSelectedRoleFamilyType() {
    return this.model.role === AllUserRoles.family;
  }

  get isAdmin() {
    return this.model.role === AllUserRoles.admin || this.model.role === AllUserRoles.unit_owner_admin;
  }

  get showCompanyField() {
    return (
      this.model.role === AllUserRoles.admin ||
      this.model.role === AllUserRoles.maintenance ||
      this.model.role === AllUserRoles.district ||
      this.model.role === AllUserRoles.security ||
      this.model.role === AllUserRoles.pdFireEms
    );
  }
}

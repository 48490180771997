import { Component, OnInit } from "@angular/core";
import { AngularFirestore, DocumentData, DocumentReference } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute, Router } from "@angular/router";
import { strings } from "environments/environment";
import { VillageGroup, VillageType } from "common/typings";
import { LengthUnit } from "common/typings";
import { CollectionNames, RoutePaths } from "app/shared/generic_variables";
import { SchoolService } from "app/core/services/school.service";
import { map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { USTimezones } from "app/utils/school.util";
import { orderBy, uniqBy } from "lodash";

@Component({
  selector: "app-new-yacht",
  templateUrl: "./new-yacht.component.html",
})
export class NewYachtComponent implements OnInit {
  subs = new SubSink();
  usStateList = [];
  userName = "";
  isOwner = false;
  strings: { villageType?: VillageType; villageGroup?: VillageGroup } = strings;
  allRegions: DocumentData[] = [];
  allDistricts: DocumentData[] = [];
  filteredDistricts: DocumentData[] = [];
  filteredSchools: DocumentData[] = [];
  loading = false;
  error = "";
  model = {
    schoolId: "",
    schoolName: "",
    business_phone: "",
    business_phone_extension: "",
    primary_phone: "",
    primary_phone_extension: "",
    contact_email: "",
    contact_firstName: "",
    contact_direct_phone: "",
    contact_direct_phone_extension: "",
    contact_lastName: "",
    address: "",
    unit: "",
    city: "",
    county: "",
    state: "",
    zip: "",
    region: "",
    district: null,
    associatedSchoolId: "",
    timezone: "America/Los_Angeles",
    slipsCount: 0,
    residentialUnitsCount: 0,
    slipsSizeUnit: LengthUnit.FEET,
    slipsSizeRange: {
      from: 0,
      to: 0,
    },
    reportEmails: "",
    managed_by_outside_company: false,
    managementOrganization: {},
    website: "",
    map_marina: "",
    map_harbor: "",
    location: "",
  };

  managementOrganization = {
    name: "",
    phone: "",
    phone_extension: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    contact_firstName: "",
    contact_lastName: "",
    contact_email: "",
    contact_phone: "",
    contact_phone_extension: "",
  };

  timezones = USTimezones;
  submitted = false;
  selectedSchoolId: string;
  mode: "add" | "edit" = "add";

  constructor(
    public afAuth: AngularFireAuth,
    private schoolService: SchoolService,
    private router: Router,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private toastrService: ToastrService
  ) {
    this.getAllRegions();
    this.getAllDistricts();
    this.getSchoolInEditMode();
  }

  ngOnInit() {
    this.getAllStates();
  }

  getAllStates() {
    this.db
      .collection(CollectionNames.states)
      .valueChanges()
      .subscribe((states) => {
        if (states) this.usStateList = states;
      });
  }

  getSchoolInEditMode() {
    this.route.paramMap.subscribe((params) => {
      this.selectedSchoolId = params.get("id");
      if (this.selectedSchoolId) {
        const schoolRef = this.db.doc("schools/" + this.selectedSchoolId);
        schoolRef.valueChanges().subscribe((school: DocumentData) => {
          this.mode = "edit";
          this.model = { ...this.model, ...school, schoolName: school.name };
          if (school && school?.managementOrganization) {
            this.managementOrganization = { ...this.managementOrganization, ...school.managementOrganization };
          }
          if (school.region) {
            const regionRef: DocumentReference = school.region;
            this.model = { ...this.model, region: regionRef.id };
            this.onRegionFilterChange(regionRef.id);
          }
          if (school.district) {
            const districtRef: DocumentReference = school.district;
            this.model = { ...this.model, district: districtRef.id };
            this.onDistrictFilterChange(school.district.id);
          }
          if (school.associatedSchool && school?.associatedSchool?.id) {
            this.model = { ...this.model, associatedSchoolId: school?.associatedSchool.id };
          }
        });
      }
    });
  }

  getAllRegions() {
    this.db
      .collection(CollectionNames.regions, (ref) => ref.where("deleted", "==", false).orderBy("name", "asc"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((cb) => {
            const data: DocumentData = cb.payload.doc.data();
            const id = cb.payload.doc["id"];
            return { id, ...data };
          })
        )
      )
      .subscribe((regions) => {
        if (regions) this.allRegions = regions;
      });
  }

  getAllDistricts() {
    this.db
      .collection(CollectionNames.districts, (ref) => ref.where("deleted", "==", false).orderBy("name", "asc"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((cb) => {
            const data: DocumentData = cb.payload.doc.data();
            const id = cb.payload.doc["id"];
            return { id, ...data };
          })
        )
      )
      .subscribe((districts) => {
        if (districts) {
          this.allDistricts = districts;
        }
      });
  }

  onRegionFilterChange(selectedRegionIdId: string) {
    this.filteredDistricts = this.allDistricts.filter((item) => item.region.id === selectedRegionIdId);
  }

  onDistrictFilterChange(selectedDistrictId: string) {
    const districtRef = this.db.collection(CollectionNames.districts).doc(selectedDistrictId).ref;
    if (!districtRef?.id) {
      return;
    }
    this.filteredSchools = [];
    const schoolsRef = this.db.collection(CollectionNames.schools, (ref) => ref.where("district", "==", districtRef));
    this.subs.sink = schoolsRef.snapshotChanges().subscribe(
      (schools) => {
        if (schools.length === 0) {
          this.loading = false;
          this.filteredSchools = [];
        } else {
          let filteredSchools = [];
          schools.map((school) => {
            const schoolData: DocumentData = school.payload.doc.data();
            filteredSchools = orderBy(
              uniqBy(
                [...filteredSchools, { ...schoolData, snap: school.payload.doc, id: school.payload.doc.id }],
                "id"
              ),
              "name"
            );
          });
          this.loading = false;
          this.filteredSchools = filteredSchools;
        }
      },
      (error) => {
        console.log("error on querying", error);
      }
    );
  }

  onSubmit() {
    const schoolName = this.filteredSchools.find((item) => item.id === this.model.associatedSchoolId)?.name;
    const associatedSchool = {
      id: this.model.associatedSchoolId ? this.model.associatedSchoolId : "",
      name: schoolName ? schoolName : "",
    };
    if (this.selectedSchoolId) {
      this.model = { ...this.model, schoolId: this.selectedSchoolId };
    }
    this.loading = true;
    const postData = { ...this.model, isYachtClub: true, associatedSchool };
    this.schoolService.postSchool(postData).subscribe(
      (_) => {
        this.loading = false;
        this.toastrService.success(`Great, Yacht data has been updated successfully!`);
        this.router.navigateByUrl(`${RoutePaths.schools}/${this.selectedSchoolId}`);
      },
      (error) => {
        console.log("Error", error);
        this.loading = false;
        this.error = error;
        this.toastrService.error(`Oops, something went wrong while updating Yacht data. Please try again!`);
      }
    );
  }

  public get LengthUnit() {
    return LengthUnit;
  }
}

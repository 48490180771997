<div class="row">
  <div class="col-12 pb-5 pt-5 pl-5 pr-5">
    <div class="activeModal-header">
      <h3 class="activeModal-title">Visitors Log on {{ selectedDate | date }}</h3>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="activeModal-body">
      <input
        type="text"
        style="padding: 8px; margin: 15px auto; width: 30%"
        placeholder="Type to filter by visitor and visited person"
        (keyup)="updateFilter($event)"
      />

      <ngx-datatable
        [rows]="selectedVisitors"
        [columns]="columns"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="25"
        [columnMode]="'force'"
        class="material"
      >
        <ngx-datatable-column name="createdAt">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value.toDate() | date: "shortTime" }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="visitor" prop="fullName">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="purpose of visit" prop="purposeOfVisit">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="visited user" prop="visitedUserDetail">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value.firstName }} {{ value.lastName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="approved">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value === true ? "Yes" : "No" }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="acknowledgedAt">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value?.toDate() | date: "shortTime" }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <div class="activeModal-footer mt-5 float-right">
      <button type="button" class="btn btn-danger float-right" (click)="activeModal.close('Close click')">Close</button>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.css"],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() title: string = "";
  @Input() text: string = "";
  @Input() highlightedText: string = "";
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}

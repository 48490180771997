import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute } from "@angular/router";
import { DatatableComponent } from "@swimlane/ngx-datatable";

export interface Item {
  body: string;
  createdAt: number;
  createdBy: string;
  title: string;
  type: string;
}

@Component({
  selector: "app-school-explorer-alerts",
  templateUrl: "./explorer-alerts.component.html",
})
export class ExplorerAlertsComponent implements OnInit {
  selectedId = "";
  loading = false;
  items: Item[];
  allItems: Item[];
  columns = [{ prop: "body" }, { name: "createdAt" }, { name: "createdBY" }, { name: "title" }, { name: "type" }];

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore, private route: ActivatedRoute) {
    this.route.paramMap.subscribe((params) => {
      this.selectedId = params.get("id");
      this.loadData();
    });
  }

  ngOnInit() {}

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allItems.filter(function (d) {
      return (
        (d.body && d.body.toLowerCase().indexOf(val) !== -1) ||
        (d.createdBy && d.createdBy.toLowerCase().indexOf(val) !== -1) ||
        !val
      );
    });
    this.items = [];
    this.items = [...temp];
    this.table.offset = 0;
  }

  loadData() {
    const notifications = this.db.collection<Item>("schools/" + this.selectedId + "/notifications", (ref) =>
      ref.orderBy("createdAt", "desc")
    );
    notifications.valueChanges().subscribe((items) => {
      this.allItems = [];
      this.allItems = [...items];
      this.items = this.allItems;
    });
  }
}

<h3>Broadcasts</h3>

<input
  type="text"
  style="padding: 8px; margin: 15px auto; width: 30%"
  placeholder="Type to filter body and created by"
  (keyup)="updateFilter($event)"
/>

<ngx-datatable
  [rows]="items"
  [columns]="columns"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [limit]="5"
  [columnMode]="'force'"
  class="material"
>
  <ngx-datatable-column name="createdAt">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value | date: "yyyy-MM-dd" }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="createdBy">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="body">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="groups">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ getBroadcastGroups(value) }}
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

import { Component, OnInit } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute, Router } from "@angular/router";
import { Roles, strings, UserBulkUploadFileSampleTemplateFileName } from "environments/environment";
import { VillageType } from "common/typings";
import { isEqual, orderBy } from "lodash";
import * as XLSX from "xlsx";
import { ToastrService } from "ngx-toastr";
import { UserService } from "app/core/services/user.service";
import { BulkUserUpload } from "app/utils/user.util";
import { RoutePaths } from "app/shared/generic_variables";

// AOA : array of array
type AOA = any[][];
@Component({
  selector: "app-school-users-bulk",
  templateUrl: "./school-user-bulk.component.html",
  styleUrls: ["./school-user-bulk.component.scss"],
})
export class UserBulkComponent implements OnInit {
  selectedSchoolId = "";
  schoolData: DocumentData;
  errorOccurred: boolean;
  inValidOrderOfSheetTitles = false;
  currentOrderOfSheetTitles = [];
  uploading = false;
  requiredOrderOfSheetTitles = [];

  isMaxSelect = false;
  currentPage = 0;
  isEmptyDrop = true;
  isExcelDrop = true;
  strings = strings;

  origExcelData: AOA = [];
  refExcelData: Array<any>;
  excelDataEncodeToJson: any[];
  excelTransformNum = [];

  /** Default excel file-name */
  sheetJsExcelName = "marinas.xlsx";

  /* excel sheet.js */
  sheetCellRange: XLSX.Range;
  sheetMaxRow: number;
  localWSheet: XLSX.WorkSheet;
  localWorkBook: XLSX.WorkBook;
  sheetNameForTab: Array<string> = ["excel tab 1", "excel tab 2"];
  totalPage = this.sheetNameForTab.length;
  selectDefault: string;
  sheetBufferRender: File;

  submitted = false;
  roles = Roles;
  sampleTemplateFileName = UserBulkUploadFileSampleTemplateFileName;
  invalidRolesInSheet = [];
  villageType = strings.villageType;

  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router
  ) {
    this.afAuth.authState.subscribe((_) => {
      this.route.paramMap.subscribe((params) => {
        this.selectedSchoolId = params.get("id");
        const schoolRef = this.db.doc("schools/" + this.selectedSchoolId);
        schoolRef.valueChanges().subscribe((_school: DocumentData) => {
          this.schoolData = _school;
        });
      });
    });
    if (this.isMarina) {
      this.requiredOrderOfSheetTitles = [
        "#",
        "first_name",
        "last_name",
        "email",
        "role",
        "phone_cell",
        "phone_office",
        "phone_extension",
        "company_organization",
        "boat_name",
        "dock_number",
        "slip_number",
        "mooring",
        "Street",
        "city",
        "state",
        "zip",
      ];
    }
    if (this.isSchool) {
      this.requiredOrderOfSheetTitles = [
        "#",
        "first_name",
        "last_name",
        "email",
        "role",
        "phone_cell",
        "phone_office",
        "phone_extension",
        "company_organization",
        "family_first_name",
        "family_last_name",
        "family_email",
        "family_phone",
        "family_phone_extension",
      ];
    }
    if (this.isHoa) {
      this.requiredOrderOfSheetTitles = [
        "#",
        "first_name",
        "last_name",
        "email",
        "role",
        "phone_cell",
        "phone_office",
        "phone_extension",
        "company_organization",
      ];
    }
  }

  ngOnInit() {}

  inputExcelOnClick(evt: any) {
    this.inValidOrderOfSheetTitles = false;
    const target: HTMLInputElement = evt.target;
    if (target.files.length === 0) {
      throw new Error("Please upload at least a valid file!");
    }
    if (target.files.length > 1) {
      throw new Error("Cannot use multiple files");
    }
    this.sheetJsExcelName = evt.target.files.item(0).name;
    const reader: FileReader = new FileReader();
    this.readerExcel(reader);
    reader.readAsArrayBuffer(target.files[0]);
    this.sheetBufferRender = target.files[0];
    this.isEmptyDrop = false;
    this.isExcelDrop = true;
  }

  transform(value: number) {
    return (value >= 26 ? this.transform(((value / 26) >> 0) - 1) : "") + "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[value % 26 >> 0];
  }

  readerExcel(reader: FileReader, index = 0) {
    /* reset array */
    this.origExcelData = [];
    reader.onload = (e: any) => {
      const data: string = e.target.result;
      const wBook: XLSX.WorkBook = XLSX.read(data, { type: "array" });
      this.localWorkBook = wBook;
      const wsName: string = wBook.SheetNames[index];
      this.sheetNameForTab = wBook.SheetNames;
      this.totalPage = this.sheetNameForTab.length;
      this.selectDefault = this.sheetNameForTab[index];
      const wSheet: XLSX.WorkSheet = wBook.Sheets[wsName];
      this.localWSheet = wSheet;
      this.sheetCellRange = XLSX.utils.decode_range(wSheet["!ref"]);
      this.sheetMaxRow = this.sheetCellRange.e.r;
      this.origExcelData = <AOA>XLSX.utils.sheet_to_json(wSheet, {
        header: 1,
        range: wSheet["!ref"],
        raw: true,
      });
      this.refExcelData = this.origExcelData.map((value) => Object.assign([], value));
      this.excelTransformNum = [];
      for (let idx = 0; idx <= this.sheetCellRange.e.c; idx++) {
        this.excelTransformNum[idx] = this.transform(idx);
      }
      this.refExcelData.map((x) => x.unshift("#"));
      this.excelTransformNum.unshift("order");
      this.excelDataEncodeToJson = this.refExcelData.slice(0).map((item) =>
        item.reduce((obj: any, val: any, i: any) => {
          obj[this.excelTransformNum[i]] = val;
          return obj;
        }, {})
      );
    };
  }

  async confirmBulkUpload() {
    if (this.inValidOrderOfSheetTitles) {
      this.toastr.warning(`Invalid formatted sheet data.
      The either title name or order of the fields on uploaded sheet is wrong.
      Please try again with valid sheet data!`);
      return;
    }
    const valid = this.validateSheetTitleFieldsOrder();
    if (valid) {
      this.errorOccurred = false;
      this.invalidRolesInSheet = [];
      this.inValidOrderOfSheetTitles = false;
      this.uploading = true;
      let allUserList: DocumentData[] = [];
      this.excelDataEncodeToJson.slice(1, this.excelDataEncodeToJson.length - 1).forEach((sheetData) => {
        const userData = BulkUserUpload.constructData(strings.villageType, sheetData);
        if (userData.email) {
          allUserList = [...allUserList, userData];
        }
      });
      allUserList = orderBy(allUserList, ["schoolName"], ["asc"]);
      const { valid, invalidRoles } = this.validateRoles(allUserList);
      if (valid) {
        const chunkedUserList = this.splitArrayIntoChunksOfLen(allUserList, 50);
        await Promise.all(
          chunkedUserList.map(async (userList) => {
            await this.userService.postBulkUser(userList, this.selectedSchoolId);
          })
        )
          .then((_) => {
            this.uploading = false;
            this.toastr.success(`Success in uploading the users.`);
            this.router.navigate([`${RoutePaths.schools}/${this.selectedSchoolId}/users`]);
          })
          .catch((error) => {
            console.log("error", error);
            this.uploading = false;
            this.errorOccurred = true;
            this.toastr.error(`Something went wrong while uploading the users.
          Please refresh the page and try again!`);
          });
      } else {
        this.invalidRolesInSheet = invalidRoles;
        this.uploading = false;
        this.toastr.error(`Data sheet contains users with invalid role.
          Please edit the data sheet and re-upload.`);
      }
    } else {
      this.inValidOrderOfSheetTitles = true;
    }
  }

  validateRoles(allUserList: DocumentData[]) {
    let invalidRoles = [];
    const dataRoles = allUserList.map((item) => item.role);
    const valid = dataRoles.every((dR) => !!Roles.includes(dR));
    if (!valid) {
      invalidRoles = dataRoles.filter((dR) => !Roles.includes(dR));
    }
    return { valid, invalidRoles };
  }

  getInvalidRoles(allUserList: DocumentData[]) {
    const dataRoles = allUserList.map((item) => item.role);
    return dataRoles.filter((dR) => !!Object.keys(Roles).includes(dR));
  }

  validateSheetTitleFieldsOrder(): boolean {
    let valid = false;
    const sheetTitlesObj = this.excelDataEncodeToJson[0];
    let sheetTitles = [];
    if (sheetTitles && Object.keys(sheetTitlesObj).length) {
      Object.values(sheetTitlesObj).forEach((title) => {
        sheetTitles = [...sheetTitles, title];
      });
    }
    this.currentOrderOfSheetTitles = sheetTitles;
    if (isEqual(sheetTitles, this.requiredOrderOfSheetTitles)) {
      valid = true;
    }
    return valid;
  }

  splitArrayIntoChunksOfLen(arr: any[], len: number) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }

  get isMarina() {
    return strings.villageType === VillageType.MARINA;
  }

  get isSchool() {
    return strings.villageType === VillageType.SCHOOL;
  }

  get isHoa() {
    return strings.villageType === VillageType.HOA;
  }

  get Roles() {
    return Roles;
  }
}

<style type="text/css">
  .cal-month-view .cal-day-badge {
    display: none;
  }
</style>

<section class="content">
  <app-school-detail-page-header [schoolData]="schoolData"></app-school-detail-page-header>
  <div class="row">
    <div *ngFor="let item of items; let i = index" style="list-style: none" class="col-md-4">
      <div class="card" style="cursor: pointer">
        <div class="card-body">
          <!-- <img class="card-img-top" src="{{getImgUrl(item)}}" alt="Card image cap" *ngIf="item.image && item.image != '' "> -->
          <h4 class="card-title">{{ item.title }}</h4>
          <h6 class="card-subtitle"><strong>Reported By: </strong>{{ item.createdBy }}</h6>
          <h6 class="card-subtitle"><strong>Incident Date:</strong> {{ item.date | date: "yyyy-MM-dd HH:mm" }}</h6>
          <h6 class="card-subtitle"><strong>Details:</strong> {{ item.details }}</h6>
          <h6 class="card-subtitle"><strong>Location:</strong> {{ item.location }}</h6>
          <h6 class="card-subtitle"><strong>Witnesses:</strong> {{ item.witnesses }}</h6>
          <h6 class="card-subtitle"><strong>Incidents:</strong> {{ item.incidents }}</h6>
          <h6 class="card-subtitle"><strong>Postive Incidents:</strong> {{ item.positiveIncidents }}</h6>
        </div>
      </div>
    </div>
  </div>
</section>

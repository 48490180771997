import { Component, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { orderBy, uniqBy } from "lodash";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";

import { AllUserRoles, CollectionNames } from "app/shared/generic_variables";
import { Roles, strings } from "environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-school-users",
  templateUrl: "./school-users.component.html",
})
export class SchoolUsersComponent implements OnDestroy {
  items = [];
  selectedSchoolId = "";
  users: DocumentData[] = [];
  allUsers: DocumentData[] = [];
  school: Observable<DocumentData>;
  searchText = "";
  subs = new SubSink();
  allRoles: AllUserRoles[] = Roles;
  selectedRole: AllUserRoles | "all" = "all";
  strings = strings;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private _modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.afAuth.authState.subscribe((user) => {
      this.route.paramMap.subscribe((params) => {
        this.selectedSchoolId = params.get("id");
        const schoolRef = db.doc("schools/" + this.selectedSchoolId);
        this.school = schoolRef.valueChanges();
        this.getSchoolUsers();
        this.getAllSchoolAssociatedUsers();
      });
    });
  }

  getAllSchoolAssociatedUsers() {
    this.subs.sink = this.db
      .collection(CollectionNames.users, (ref) =>
        ref.where("deleted", "==", false).where("isAssociatedToAllSchools", "==", true)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: DocumentData = a.payload.doc.data();
            const id = a.payload.doc["id"];
            return { id, ...data };
          });
        })
      )
      .subscribe((users: DocumentData[]) => {
        const filterUsers = orderBy(
          users.filter((item) => !item.deleted),
          ["lastName", "firstName"],
          ["asc"]
        );
        this.allUsers = orderBy(uniqBy([...this.allUsers, ...filterUsers], "id"), ["lastName", "firstName"], ["asc"]);
        this.users = orderBy(uniqBy([...filterUsers, ...this.users], "id"), ["lastName", "firstName"], ["asc"]);
      });
  }

  getSchoolUsers() {
    this.subs.sink = this.db
      .collection(CollectionNames.users, (ref) =>
        ref.where("deleted", "==", false).where("associatedSchools." + this.selectedSchoolId + ".allowed", "==", true)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: DocumentData = a.payload.doc.data();
            const id = a.payload.doc["id"];
            return { id, ...data };
          });
        })
      )
      .subscribe((users: DocumentData[]) => {
        const filterUsers = orderBy(
          users.filter((item) => !item.deleted),
          ["lastName", "firstName"],
          ["asc"]
        );
        this.allUsers = orderBy(uniqBy([...this.allUsers, ...filterUsers], "id"), ["lastName", "firstName"], ["asc"]);
        this.users = orderBy(uniqBy([...filterUsers, ...this.users], "id"), ["lastName", "firstName"], ["asc"]);
      });
  }

  addUser() {
    this.router.navigateByUrl("app/schools/" + this.selectedSchoolId + "/create-user");
  }

  addUsersBulk() {
    this.router.navigateByUrl("app/schools/" + this.selectedSchoolId + "/create-user-bulk");
  }

  getGroups(user: DocumentData) {
    try {
      return Object.keys(user["associatedSchools"][this.selectedSchoolId]).indexOf("groups") < 0
        ? ""
        : Object.keys(user["associatedSchools"][this.selectedSchoolId].groups).join(",");
    } catch {
      return user?.defaultGroups ? Object.keys(user?.defaultGroups).join(",") : "";
    }
  }

  navigateToUserDetailPage(user: DocumentData) {
    this.router.navigateByUrl(`app/schools/${this.selectedSchoolId}/users/${user.id}`);
  }

  onRoleFilterChange(role: AllUserRoles | "all") {
    if (role === "all") {
      this.users = this.allUsers;
    } else {
      if (role === AllUserRoles.district) {
        this.users = this.allUsers.filter(
          (item) =>
            item?.associatedSchools?.[this.selectedSchoolId]?.["role"] === role ||
            item?.isAssociatedToAllSchools === true
        );
      } else {
        this.users = this.allUsers.filter(
          (item) => item["associatedSchools"]?.[this.selectedSchoolId]?.["role"] === role
        );
      }
    }
  }

  onUserSearch(event: any) {
    const keyword = event.target.value;
    if (keyword && keyword.length > 0) {
      this.users = this.allUsers.filter(
        (user) =>
          `${user.firstName} ${user.lastName}`.toLowerCase().trim().indexOf(this.searchText.toLowerCase().trim()) >=
            0 || user.email.toLowerCase().trim().indexOf(this.searchText.toLowerCase().trim()) >= 0
      );
    } else {
      this.users = this.allUsers;
    }
  }

  verifyYachtMemberShip(event: any, userData: DocumentData, schoolId: string, schoolName: string) {
    event.preventDefault();
    const modalRef = this._modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = `Verify Yacht membership`;
    modalRef.componentInstance.text = `Are you sure you want to verify Yacht membership of `;
    modalRef.componentInstance.highlightedText = `${schoolName} for user ${userData?.email}`;
    modalRef.result.then((confirmed) => {
      if (confirmed) {
        const changes = {};
        try {
          this.db.doc(`users/${userData.id}`).update({ ...changes, [`associatedSchools.${schoolId}.verified`]: true });
          this.toastr.success(`User has been successfully verified for yacht membership.`, "Success");
        } catch (error) {
          console.error("error", error);
          this.toastr.error(`Sorry, we had trouble verifying user for yacht membership .Please try again.`, "Error");
        }
      }
    });
  }

  getBackLink() {
    return `/app/schools/${this.selectedSchoolId}`;
  }

  getUserRole(userData: DocumentData) {
    if (userData?.associatedSchools[this.selectedSchoolId]?.role) {
      return userData?.associatedSchools[this.selectedSchoolId]?.role;
    } else if (userData?.isAssociatedToAllSchools && userData?.isDistrictAdmin) {
      return AllUserRoles.district;
    } else {
      ("N/A");
    }
  }

  ngOnDestroy() {
    if (this.subs.sink) this.subs.unsubscribe();
  }
}

import { Component, OnInit } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs/Observable";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute } from "@angular/router";
import firebase from "firebase/compat/app";

@Component({
  selector: "app-school-groups",
  templateUrl: "./school-groups.component.html",
})
export class SchoolGroupsComponent implements OnInit {
  items = [];
  selectedId = "";
  groups: any[];
  school: Observable<DocumentData>;
  schoolData: DocumentData;
  newGroup = "";
  loading = false;

  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore, private route: ActivatedRoute) {
    this.afAuth.authState.subscribe((_) => {
      this.route.paramMap.subscribe((params) => {
        this.selectedId = params.get("id");
        const schoolRef = db.doc("schools/" + this.selectedId);
        this.school = schoolRef.valueChanges();
        this.school.subscribe((scl) => {
          this.schoolData = scl;
          this.groups = this.getAsList(scl["groups"]);
        });
      });
    });
  }

  ngOnInit() {}

  saveUpdatedOrder(groupList: any) {
    const groups = {};
    for (let i = 1; i <= groupList.length; i++) {
      groups[groupList[i - 1].key] = {
        sortOrder: i,
      };
    }
    const newGroups = {
      groups: groups,
    };
    const deletes = {
      groups: firebase.firestore.FieldValue.delete(),
    };
    this.db
      .doc(`schools/${this.selectedId}`)
      .set(deletes, { merge: true })
      .then(() => {
        this.db
          .doc(`schools/${this.selectedId}`)
          .set(newGroups, { merge: true })
          .then(() => {});
      });
  }

  getObject() {
    return Object;
  }

  getAsList(groups) {
    let grpList = [];
    if (groups === undefined) {
      return [];
    }
    for (const grp of Object.keys(groups)) {
      grpList.push({
        key: grp,
        value: groups[grp],
      });
    }
    grpList = grpList.sort(this.compareOrder);
    return grpList;
  }

  compareOrder(a, b) {
    if (a.value.sortOrder < b.value.sortOrder) {
      return -1;
    } else if (a.value.sortOrder > b.value.sortOrder) {
      return 1;
    }
    return 0;
  }

  deleteGroup(index: number) {
    if (confirm("Are you sure to delete this group?")) {
      this.groups.splice(index, 1);
      this.saveUpdatedOrder(this.groups);
    }
  }

  createNewGroup() {
    const changes = {
      groups: {},
    };
    changes.groups[this.newGroup] = {
      sortOrder: this.groups.length,
    };
    this.db
      .doc(`schools/${this.selectedId}`)
      .set(changes, { merge: true })
      .then(() => {});
  }

  getBackLink() {
    return `/app/schools/${this.selectedId}`;
  }
}

<section class="content">
  <header class="content__title">
    <h1><a [routerLink]="['/app/home']">Back</a></h1>
    <br />
    <div class="row no-gutters">
      <div class="col-auto logo-image">
        <a (click)="openChangeLogoModal(updateLogoTemplate)">
          <img src="{{ logo }}" class="img-fluid" alt="" width="72px" height="72px" title="click to update logo" />
        </a>
      </div>
      <div class="col">
        <div class="card-block px-2">
          <h1 style="font-size: 18px; margin-top: 12px">
            {{ (school | async)?.name }}
          </h1>
          <small style="font-size: 11px">{{ (school | async)?.city }}</small>
        </div>
      </div>
      <div class="col">
        <div class="float-right">
          <button type="button" class="btn btn-outline-primary" (click)="editVillage(); $event.stopPropagation()">
            EDIT INITIAL SETUP DATA
          </button>
        </div>
      </div>
    </div>
  </header>

  <div class="row">
    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToUsers()">
        <div class="card-body">
          <h4 class="card-title">Users</h4>
          <h6 class="card-subtitle">Manage users for this {{ strings.villageType }}</h6>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToDocuments()">
        <div class="card-body">
          <h4 class="card-title">Documents</h4>
          <h6 class="card-subtitle">Manage the documents as it appears in the app</h6>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToSops()">
        <div class="card-body">
          <h4 class="card-title">SOPS</h4>
          <h6 class="card-subtitle">Manage the sops as it appears in the app</h6>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToAssets()">
        <div class="card-body">
          <h4 class="card-title">Assets</h4>
          <h6 class="card-subtitle">Manage files like images and PDFs served the app</h6>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToEvents()">
        <div class="card-body">
          <h4 class="card-title">Events Calendar</h4>
          <h6 class="card-subtitle">View/Edit Calendar with all events</h6>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToRecentActivities()">
        <div class="card-body">
          <h4 class="card-title">Recent Activities</h4>
          <h6 class="card-subtitle">Explore recent activity</h6>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToReports()">
        <div class="card-body">
          <h4 class="card-title">Reports</h4>
          <h6 class="card-subtitle">Download daily activity reports</h6>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToIncidents()">
        <div class="card-body">
          <h4 class="card-title">Incidents</h4>
          <h6 class="card-subtitle">Incidents reported in the {{ strings.villageType }}</h6>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToChannels()">
        <div class="card-body">
          <h4 class="card-title">Channels</h4>
          <h6 class="card-subtitle">All the talk channels in {{ strings.villageType }}</h6>
        </div>
      </div>
    </div>
    <div *ngIf="isMarina" class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToRegion()">
        <div class="card-body">
          <h4 class="card-title">Region</h4>
          <h6 class="card-subtitle">Region this {{ strings.villageType }} belongs to</h6>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToVisitorsLog()">
        <div class="card-body">
          <h4 class="card-title">Visitors Log</h4>
          <h6 class="card-subtitle">View Daily Visitor Logs</h6>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" style="cursor: pointer" (click)="goToSchoolSettings()">
        <div class="card-body">
          <h4 class="card-title">Settings</h4>
          <h6 class="card-subtitle">Update schools associated settings</h6>
        </div>
      </div>
    </div>
  </div>
  <div class="widget-lists card-columns"></div>
</section>

<ng-template #updateLogoTemplate>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Change {{ strings.villageType }} Logo</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="closeModal()">
      <span aria-hidden=" true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="imageFiles.length > 0; else noImageFilesTemplate">
    <p>
      <strong
        >Select an image asset from dropdown below {{ strings.villageType }}
        <span class="text-primary"></span>
      </strong>
    </p>
    <div class="row">
      <div class="col-md-12">
        <p *ngIf="schoolData?.logo">
          <strong>Current Logo:</strong>
          <span>
            {{ schoolData.logo }}
          </span>
        </p>
        <select class="custom-select custom-select-lg mb-3" (change)="onLogoSelectChange($event)">
          <option disabled selected>Select an image for Logo</option>
          <option *ngFor="let f of imageFiles" [value]="f.fullPath">{{ f.fullPath }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
    <button *ngIf="imageFiles.length > 0" type="button" ngbAutofocus class="btn btn-danger" (click)="uploadLogo()">
      Change
    </button>
  </div>
</ng-template>

<ng-template #noImageFilesTemplate>
  <div class="modal-body">
    <p>
      There are no assets of image types i.e files with extension '.png', '.jpg' and '.jpeg' uploaded to this
      {{ strings.villageType }}. Please, first upload those images to the {{ strings.villageType }} from
      <b>Assets</b> section on the page.
    </p>
  </div>
</ng-template>

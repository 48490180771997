import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DocumentData } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-single-date-visitors",
  templateUrl: "./single-date-visitors.component.html",
})
export class SingleDateVisitorsComponentModal implements OnInit {
  @Input() selectedDate: Date;
  @Input() selectedVisitors: DocumentData[];
  allSelectedVisitors: DocumentData[];
  columns = [
    { prop: "fullName", name: "visitor" },
    { name: "createdAt" },
    { prop: "visitedUserDetail", name: "visited user" },
    { prop: "securityUserDetail", name: "security user" },
    { name: "approved" },
    { prop: "purposeOfVisit", name: "purpose of visit" },
    { name: "acknowledgedAt" },
    { name: "departedAt" },
  ];
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(public afAuth: AngularFireAuth, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.allSelectedVisitors = [...this.selectedVisitors];
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allSelectedVisitors.filter(function (d) {
      return (
        (d.message && d.message.toLowerCase().indexOf(val) !== -1) ||
        (d.fullName && d.fullName.toLowerCase().indexOf(val) !== -1) ||
        (d.visitedUserDetail.firstName && d.visitedUserDetail.firstName.toLowerCase().indexOf(val) !== -1) ||
        (d.visitedUserDetail.lastName && d.visitedUserDetail.lastName.toLowerCase().indexOf(val) !== -1) ||
        !val
      );
    });
    this.selectedVisitors = [];
    this.selectedVisitors = [...temp];
    this.table.offset = 0;
  }
}
